/* eslint-disable prefer-destructuring */
import {
    apiManagerLogin, apiSendWebUserMessage, apiManagerSessionRehydrateFromServer,
    apiProcessPurchase, apiPurchaseDetails, apiSignup, apiLogin, apiSessionRehydrateFromServer,
    apiForgot, apiReset, apiCoursesAddClassStep, apiUpdateLocation, apiFetchBookedSlots,
    apiFetchCalendarTeamMembers, apiProcessPaymentLink, apiFetchCalendarServices, apiFetchCalendarBonos,
    apiSaveCustomerSale, apiValidatePromoCode, apiProcessSalePayment
} from "../api";
import * as Pages from "../utils/Pages";
import { setToken, removeToken } from "../utils/ManagerUtils";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import { getSaleItemBookings } from "../utils/SalesUtils";
import { getServiceAvailableDays, getAllConflictingBookings } from "../utils/CalendarUtils";

export const loginAttempt = ( provider ) => ( {
    type: "LOGIN_ATTEMPT",
    provider
} );

export const loginError = ( message, errorType ) => ( {
    type: "LOGIN_ERROR",
    errorMessage: message,
    errorType
} );

export const loginSuccess = ( ) => ( {
    type: "LOGIN_SUCCESS"
} );

export const initializeSession = ( ) => ( {
    type: "INITIALIZE_SESSION"
} );

export const initializeFacebookSession = ( fbData ) => ( {
    type: "INITIALIZE_FACEBOOK_SESSION",
    email: fbData.profile.email,
    name: fbData.profile.name,
    id: fbData.profile.id,
    accessToken: fbData.tokenDetail.accessToken
} );

export const changeSessionAttr = ( key, value ) => ( {
    type: "CHANGE_SESSION_ATTR",
    key,
    value
} );
export const changeSignupAttr = ( key, value ) => ( {
    type: "CHANGE_SIGNUP_ATTR",
    key,
    value
} );

export const changeShopAttr = ( key, value ) => ( {
    type: "CHANGE_SHOP_ATTR",
    key,
    value
} );

export const userSessionShowToastMessage = ( message ) => ( dispatch ) => {
    // message: { id: uuid, message:
    dispatch( { type: "USER_SESSION_SHOW_TOAST_MESSAGE", message } );
    setTimeout( () => dispatch( { type: "USER_SESSION_HIDE_TOAST_MESSAGE", message } ), 5000 );
};
export const userSessionHideToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "USER_SESSION_HIDE_TOAST_MESSAGE", message } );
};

export const shopAddItemToCart = ( item ) => ( { type: "SHOP_CART_ADD_ITEM", item } );
export const shopAddItemQuantity = ( item ) => ( { type: "SHOP_CART_ADD_ITEM_QUANTITY", item } );
export const shopRemoveItemQuantity = ( item ) => ( { type: "SHOP_CART_REMOVE_ITEM_QUANTITY", item } );

export const shopUpdateItemPriceAmount = ( item, amount ) => ( { type: "SHOP_CART_UPDATE_ITEM_PRICEAMOUNT", item, amount } );
export const shopUpdateCartItemAttr = ( item, key, value ) => ( {
    type: "SHOP_CART_UPDATE_ITEM_ATTR",
    item,
    key,
    value
} );

export const shopRemoveItem = ( item ) => ( { type: "SHOP_CART_REMOVE_ITEM", item } );
export const shopRemoveCart = () => ( { type: "SHOP_CART_REMOVE_CART" } );
export const shopChangeItemSelectedCourseClassId = ( item, courseClassIdSelected ) => ( { type: "SHOP_CART_ITEM_CHANGE_COURSE_CLASS_ID", item, courseClassIdSelected } );
export const shopUpdateBookings = ( cartBookings ) => ( { type: "SHOP_CART_UPDATE_BOOKINGS", cartBookings } );

export const validateCartCheckout = () => ( dispatch, getState ) => {
    let errors = [];
    // Can be used for extra validations
    const cartItems = getState().pwSession.shop.cartItems;
    const cartBookings = getState().pwSession.shop.cartBookings;
    if ( Pages.company.stopBookingUnavailable ) {
        // bookings without datetime selected not allowed
        cartItems.forEach( cartItem => {
            if ( cartItem.type === "service" ) {
                let fullService = cartItem.service;
                let bonoService = null;
                let cartItemBookings = getSaleItemBookings( cartItem.type, fullService, bonoService, cartItem.quantity, cartBookings || [], cartItem.priceAmount, cartItem.priceVatIncluded, cartItem.priceVatPercentage );
                cartItemBookings.forEach( cartItemBooking => {
                    if ( !cartItemBooking.bookingDatetime ) {
                        errors.push( "Para continuar es necesario que todos los servicios seleccionados tengan agendada sus citas" );
                    }
                } );
            }
        } );
    }
    if ( errors.length === 0 ) {
        return true;
    }
    dispatch( userSessionShowToastMessage( { id: DataUtils.uuid(), message: errors[ 0 ] } ) );
    return false;
};

export const shopCartCheckout = () => ( dispatch ) => {
    if ( dispatch( validateCartCheckout() ) ) {
        window.location.href = Pages.getPage( "shop-cart-checkout" ).relativeUrl;
    }
    return null;
};

export const shopSearch = () => ( dispatch, getState ) => {
    const encodedSearchText = DataUtils.base64Encode( getState().pwSession.searchText );
    window.location.href = `${ Pages.getPage( "shop" ).relativeUrl }?q=${ encodedSearchText }`;
};
export const locationsSearch = () => ( dispatch, getState ) => {
    const encodedSearchText = DataUtils.base64Encode( getState().pwSession.searchText );
    window.location.href = `${ Pages.getPage( "locations" ).relativeUrl }?q=${ encodedSearchText }`;
};
export const changeShopCartAttr = ( key, value ) => ( {
    type: "CHANGE_SHOP_CART_ATTR",
    key,
    value
} );
export const changeShopCartAttrs = ( changes ) => ( {
    type: "CHANGE_SHOP_CART_ATTRS",
    changes
} );
export const processPurchaseAttempt = () => ( {
    type: "SHOP_CART_PROCESS_PURCHASE_ATTEMPT"
} );

export const processPurchaseError = ( message, errorType ) => ( {
    type: "SHOP_CART_PROCESS_PURCHASE_ERROR",
    errorMessage: message,
    errorType
} );

export const processPurchaseDone = ( cartId ) => ( {
    type: "SHOP_CART_PROCESS_PURCHASE_DONE",
    cartId
} );

export const processPurchaseSuccess = ( paymentMethod ) => ( {
    type: "SHOP_CART_PROCESS_PURCHASE_SUCCESS",
    paymentMethod
} );

export const processPurchaseRequest = ( providers, options ) => ( dispatch, getState ) => {
    dispatch( processPurchaseAttempt() );
    const { pwSession } = getState();
    const forcePaymentMethod = options ? options.forcePaymentMethod : null;
    let mutations = { language: pwSession.language, userAcceptNewsletter: pwSession.webUserAcceptNewsletter, userAcceptTermsConditions: pwSession.acceptPrivacyPolicy };
    if ( options ) {
        mutations = Object.assign( {}, mutations, options );
    }
    if ( forcePaymentMethod ) {
        mutations.paymentMethod = forcePaymentMethod;
        if ( ShopUtils.isOutsourcedCart( pwSession.shop.cartItems ) ) {
            mutations.outsourced = true;
        }
    }
    const shopAttributes = Object.assign( {}, pwSession.shopAttributes, mutations );
    apiProcessPurchase( shopAttributes, pwSession.shop )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( processPurchaseDone( jsonRes.cartId ) );
                if ( shopAttributes && shopAttributes.paymentMethod === "stripe" && providers && providers.stripe ) {
                    providers.stripe.redirectToCheckout( { sessionId: jsonRes.data.id } );
                    return;
                }
                if ( jsonRes.fillAndSubmitForm && jsonRes.fillAndSubmitForm.fields ) {
                    jsonRes.fillAndSubmitForm.fields.forEach( field => {
                        document.getElementById( field.id ).value = field.value;
                    } );
                    document.getElementById( jsonRes.fillAndSubmitForm.formId ).action = jsonRes.fillAndSubmitForm.formUrl;
                    document.getElementById( jsonRes.fillAndSubmitForm.formId ).submit();
                } else if ( jsonRes.redirectUserTo ) {
                    window.location.href = jsonRes.redirectUserTo;
                } else {
                    dispatch( shopRemoveCart() );
                    dispatch( processPurchaseSuccess( shopAttributes.paymentMethod ) );
                }
            } else {
                dispatch( processPurchaseError( jsonRes.errorMessage ) );
            }
        } )
        .catch( () => {
            dispatch( processPurchaseError( "shop.validation.genericservererror" ) );
        } );
};
export const purchaseDetailsRequest = () => ( dispatch, getState ) => {
    dispatch( { type: "SHOP_CART_PURCHASE_DETAILS_ATTEMPT" } );
    const { pwSession } = getState();
    apiPurchaseDetails( pwSession.shopAttributes )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CART_PURCHASE_DETAILS_SUCCESS", cart: jsonRes.cart } );
            } else {
                dispatch( { type: "SHOP_CART_PURCHASE_DETAILS_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CART_PURCHASE_DETAILS_ERROR", errorMessage: "shop.validation.genericservererror" } );
        } );
};

export const validatePromoCode = () => ( dispatch, getState ) => {
    dispatch( { type: "SHOP_CART_PROMOCODE_VALIDATION_ATTEMPT" } );
    const { pwSession } = getState();
    apiValidatePromoCode( pwSession.shopAttributes, pwSession.shop )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CART_PROMOCODE_VALIDATION_SUCCESS", item: jsonRes.item } );
            } else {
                dispatch( { type: "SHOP_CART_PROMOCODE_VALIDATION_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CART_PROMOCODE_VALIDATION_ERROR", errorMessage: "shop.validation.genericservererror" } );
        } );
};

export const changeSessionManagerAttr = ( key, value ) => ( {
    type: "CHANGE_SESSION_MANAGER_ATTR",
    key,
    value
} );

export const managerLogout = ( history ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_LOGOUT" } );
    removeToken();
    history.push( Pages.company.managerURLRelative );
};

export const managerLoginAttempt = ( provider ) => ( {
    type: "MANAGER_LOGIN_ATTEMPT",
    provider
} );

export const managerLoginError = ( message, errorType ) => ( {
    type: "MANAGER_LOGIN_ERROR",
    errorMessage: message,
    errorType
} );

export const managerLoginSuccess = ( accessToken ) => ( {
    type: "MANAGER_LOGIN_SUCCESS",
    accessToken
} );

export const sessionRehydrateFromServer = () => ( dispatch ) => {
    dispatch( { type: "SESSION_REHYDRATE_FROM_SERVER_ATTEMPT" } );
    return apiSessionRehydrateFromServer()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SESSION_REHYDRATE_FROM_SERVER_SUCCESS", data: jsonRes.data } );
            } else if ( jsonRes && jsonRes.status && jsonRes.status === 401 ) {
                dispatch( { type: "MOD_SIGNUP_LOGOUT" } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SESSION_REHYDRATE_FROM_SERVER_ERROR" } );
        } );
};

export const sessionManagerRehydrateFromServer = () => ( dispatch ) => {
    dispatch( { type: "SESSION_MANAGER_REHYDRATE_FROM_SERVER_ATTEMPT" } );
    apiManagerSessionRehydrateFromServer()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SESSION_MANAGER_REHYDRATE_FROM_SERVER_SUCCESS", data: jsonRes } );
            } else if ( jsonRes && jsonRes.status && jsonRes.status === 401 ) {
                dispatch( { type: "MANAGER_LOGOUT" } );
                if ( window ) {
                    if ( window.location.href.indexOf( Pages.company.managerURLRelative ) > -1 ) {
                        window.location.href = Pages.company.managerURLRelative;
                    }
                }
            }
        } )
        .catch( () => {
            dispatch( { type: "SESSION_MANAGER_REHYDRATE_FROM_SERVER_ERROR" } );
        } );
};

export const signupRequest = ( history, body ) => ( dispatch ) => {
    dispatch( { type: "SIGNUP_ATTEMPT" } );
    apiSignup( body )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SIGNUP_SUCCESS" } );
                if ( jsonRes.accessToken ) {
                    dispatch( { type: "MOD_SIGNUP_LOGIN_SUCCESS" } );
                    DataUtils.setToken( jsonRes.accessToken );
                    dispatch( sessionRehydrateFromServer() );
                    if ( jsonRes.redirectTo ) {
                        window.location.href = jsonRes.redirectTo;
                    }
                }
            } else {
                dispatch( { type: "SIGNUP_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SIGNUP_ERROR", errorMessage: "generic.server.error" } );
        } );
};

export const loginRequest = ( history, body ) => ( dispatch ) => {
    dispatch( { type: "MOD_SIGNUP_LOGIN_ATTEMPT" } );
    apiLogin( body )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MOD_SIGNUP_LOGIN_SUCCESS" } );
                DataUtils.setToken( jsonRes.accessToken );
                dispatch( sessionRehydrateFromServer() );
                window.location.href = jsonRes.redirectTo;
            } else if ( jsonRes.passwordSignupRequired ) {
                dispatch( changeSignupAttr( "view", "signuppassword" ) );
            } else {
                dispatch( { type: "MOD_SIGNUP_LOGIN_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "MOD_SIGNUP_LOGIN_ERROR", errorMessage: "generic.server.error" } );
        } );
};
export const forgotRequest = ( history, body ) => ( dispatch ) => {
    dispatch( { type: "MOD_SIGNUP_FORGOT_ATTEMPT" } );
    apiForgot( body )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MOD_SIGNUP_FORGOT_SUCCESS" } );
            } else {
                dispatch( { type: "MOD_SIGNUP_FORGOT_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "MOD_SIGNUP_FORGOT_ERROR", errorMessage: "generic.server.error" } );
        } );
};
export const resetRequest = ( history, body ) => ( dispatch ) => {
    dispatch( { type: "MOD_SIGNUP_RESET_ATTEMPT" } );
    apiReset( body )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MOD_SIGNUP_RESET_SUCCESS" } );
                dispatch( changeSignupAttr( "view", "login" ) );
            } else {
                dispatch( { type: "MOD_SIGNUP_RESET_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "MOD_SIGNUP_RESET_ERROR", errorMessage: "generic.server.error" } );
        } );
};
export const managerLoginRequest = ( history ) => ( dispatch, getState ) => {
    dispatch( managerLoginAttempt() );
    const { pwSession } = getState();
    if ( !pwSession.manager.acceptPrivacyAndTerms ) {
        dispatch( managerLoginError( "Debes aceptar la Política de Privacidad para continuar" ) );
        return false;
    }
    apiManagerLogin(
        pwSession.manager.userEmail,
        pwSession.manager.userPass,
        pwSession.manager.acceptPrivacyAndTerms,
    )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( managerLoginSuccess( jsonRes.accessToken ) );
                setToken( jsonRes.accessToken );
                dispatch( sessionManagerRehydrateFromServer() );
                history.push( jsonRes.redirectTo );
            } else {
                dispatch( managerLoginError() );
            }
        } )
        .catch( () => {
            dispatch( managerLoginError() );
        } );
    return true;
};

export const sendWebUserMessageAttempt = () => ( {
    type: "SEND_WEB_USER_MESSAGE_ATTEMPT"
} );

export const sendWebUserMessageError = ( message, errorType ) => ( {
    type: "SEND_WEB_USER_MESSAGE_ERROR",
    errorMessage: message,
    errorType
} );

export const sendWebUserMessageSuccess = () => ( {
    type: "SEND_WEB_USER_MESSAGE_SUCCESS"
} );

export const sendWebUserMessageRequest = () => ( dispatch, getState ) => {
    dispatch( sendWebUserMessageAttempt() );
    const { pwSession } = getState();
    apiSendWebUserMessage( pwSession.message )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( sendWebUserMessageSuccess() );
            } else {
                dispatch( sendWebUserMessageError( jsonRes.errorMessage ) );
            }
        } )
        .catch( () => {
            dispatch( sendWebUserMessageError( "generic.server.error" ) );
        } );
};

export const courseClassStepDone = ( classStep ) => ( dispatch ) => {
    dispatch( { type: "WEBUSER_COURSES_CLASS_ADD_STEP_ATTEMPT" } );
    apiCoursesAddClassStep( classStep )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( sessionRehydrateFromServer() );
            } else {
                dispatch( { type: "WEBUSER_COURSES_CLASS_ADD_STEP_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "WEBUSER_COURSES_CLASS_ADD_STEP_ERROR", errorMessage: "generic.server.error" } );
        } );
};
export const locationUpdateRequest = ( locationImageFile, item ) => () => apiUpdateLocation( locationImageFile, item ).then( ( res ) => res.json() );
// booked slots for frontend availability
export const getCalendarBookedSlots = () => ( dispatch ) => {
    dispatch( { type: "SHOP_CART_GET_BOOKED_SLOTS_ATTEMPT" } );
    return apiFetchBookedSlots()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CART_GET_BOOKED_SLOTS_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "SHOP_CART_GET_BOOKED_SLOTS_ERROR" } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CART_GET_BOOKED_SLOTS_ERROR" } );
        } );
};
// team members for frontend availability
export const getCalendarTeamMembers = () => ( dispatch ) => {
    dispatch( { type: "SHOP_CART_TEAM_MEMBERS_ATTEMPT" } );
    return apiFetchCalendarTeamMembers()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CART_TEAM_MEMBERS_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "SHOP_CART_TEAM_MEMBERS_ERROR" } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CART_TEAM_MEMBERS_ERROR" } );
        } );
};
// services for frontend availability
export const getCalendarServices = ( optionalActiveFilter ) => ( dispatch ) => {
    dispatch( { type: "SHOP_CART_SERVICES_ATTEMPT" } );
    return apiFetchCalendarServices( optionalActiveFilter )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CART_SERVICES_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "SHOP_CART_SERVICES_ERROR" } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CART_SERVICES_ERROR" } );
        } );
};
// bonos for frontend availability
export const getCalendarBonos = ( optionalActiveFilter ) => ( dispatch ) => {
    dispatch( { type: "SHOP_CART_BONOS_ATTEMPT" } );
    return apiFetchCalendarBonos( optionalActiveFilter )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "SHOP_CART_BONOS_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "SHOP_CART_BONOS_ERROR" } );
            }
        } )
        .catch( () => {
            dispatch( { type: "SHOP_CART_BONOS_ERROR" } );
        } );
};
// payment links
export const processPaymentLinkAttempt = () => ( {
    type: "PROCESS_PAYMENT_LINK_ATTEMPT"
} );
export const processPaymentLinkError = ( message, errorType ) => ( {
    type: "PROCESS_PAYMENT_LINK_ERROR",
    errorMessage: message,
    errorType
} );
export const processPaymentLinkDone = ( cartId ) => ( {
    type: "PROCESS_PAYMENT_LINK_DONE",
    cartId
} );
export const processPaymentLinkSuccess = () => ( {
    type: "PROCESS_PAYMENT_LINK_SUCCESS"
} );
export const processPaymentLinkRequest = ( providers, options ) => ( dispatch, getState ) => {
    dispatch( processPaymentLinkAttempt() );
    const { content } = getState();
    const paymentLink = Object.assign( {}, content.paymentLinks.current, options || {} );
    apiProcessPaymentLink( paymentLink )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( processPaymentLinkDone( jsonRes.paymentLinkId ) );
                if ( paymentLink.paymentLinkPaymentMethod === "stripe" && providers && providers.stripe ) {
                    providers.stripe.redirectToCheckout( { sessionId: jsonRes.data.id } );
                    return;
                }
                if ( jsonRes.fillAndSubmitForm && jsonRes.fillAndSubmitForm.fields ) {
                    jsonRes.fillAndSubmitForm.fields.forEach( field => {
                        document.getElementById( field.id ).value = field.value;
                    } );
                    document.getElementById( jsonRes.fillAndSubmitForm.formId ).action = jsonRes.fillAndSubmitForm.formUrl;
                    document.getElementById( jsonRes.fillAndSubmitForm.formId ).submit();
                } else if ( jsonRes.redirectUserTo ) {
                    window.location.href = jsonRes.redirectUserTo;
                } else {
                    dispatch( processPaymentLinkSuccess() );
                }
            } else {
                dispatch( processPaymentLinkError( jsonRes.errorMessage ) );
            }
        } )
        .catch( () => {
            dispatch( processPaymentLinkError( "shop.validation.genericservererror" ) );
        } );
};
// sale payments
export const processSalePaymentAttempt = () => ( {
    type: "PROCESS_SALE_PAYMENT_ATTEMPT"
} );
export const processSalePaymentError = ( message, errorType ) => ( {
    type: "PROCESS_SALE_PAYMENT_ERROR",
    errorMessage: message,
    errorType
} );
export const processSalePaymentDone = ( cartId ) => ( {
    type: "PROCESS_SALE_PAYMENT_DONE",
    cartId
} );
export const processSalePaymentSuccess = () => ( {
    type: "PROCESS_SALE_PAYMENT_SUCCESS"
} );
export const processSalePaymentRequest = ( providers, options ) => ( dispatch, getState ) => {
    dispatch( processSalePaymentAttempt() );
    const { content } = getState();
    const sale = Object.assign( {}, content.sales.current, options || {} );
    apiProcessSalePayment( sale )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( processSalePaymentDone( jsonRes.paymentLinkId ) );
                if ( sale.processPaymentMethod === "stripe" && providers && providers.stripe ) {
                    providers.stripe.redirectToCheckout( { sessionId: jsonRes.data.id } );
                    return;
                }
                if ( jsonRes.fillAndSubmitForm && jsonRes.fillAndSubmitForm.fields ) {
                    jsonRes.fillAndSubmitForm.fields.forEach( field => {
                        document.getElementById( field.id ).value = field.value;
                    } );
                    document.getElementById( jsonRes.fillAndSubmitForm.formId ).action = jsonRes.fillAndSubmitForm.formUrl;
                    document.getElementById( jsonRes.fillAndSubmitForm.formId ).submit();
                } else if ( jsonRes.redirectUserTo ) {
                    window.location.href = jsonRes.redirectUserTo;
                } else {
                    dispatch( processSalePaymentSuccess() );
                }
            } else {
                dispatch( processSalePaymentError( jsonRes.errorMessage ) );
            }
        } )
        .catch( () => {
            dispatch( processSalePaymentError( "shop.validation.genericservererror" ) );
        } );
};

export const managerSessionShowToastMessage = ( message ) => ( dispatch ) => {
    // message: { id: uuid, message:
    dispatch( { type: "MANAGER_SESSION_SHOW_TOAST_MESSAGE", message } );
    setTimeout( () => dispatch( { type: "MANAGER_SESSION_HIDE_TOAST_MESSAGE", message } ), 5000 );
};
export const managerSessionHideToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_SESSION_HIDE_TOAST_MESSAGE", message } );
};

export const saveCustomerSaleRequest = () => ( dispatch, getState ) => {
    dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavingSale", value: true } );
    dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavedSale", value: false } );
    dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "errorMessage", value: null } );
    const { content } = getState();
    return apiSaveCustomerSale( content.sales.current, content.sales.saleBookings )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavingSale", value: false } );
                dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavedSale", value: true } );
                dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "current", value: jsonRes.item } );
                dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "saleBookings", value: [] } );
            } else {
                // handled error
                dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavingSale", value: false } );
                dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavedSale", value: false } );
                dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "errorMessage", value: jsonRes.errorMessage } );
            }
        } )
        .catch( () => {
            dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavingSale", value: false } );
            dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "isSavedSale", value: false } );
            dispatch( { type: "CHANGE_CONTENT_SALES_ATTR", key: "errorMessage", value: "shop.validation.genericservererror" } );
        } );
};
export const managerShowNoPrivilegesMessage = () => ( dispatch ) => {
    dispatch( managerSessionShowToastMessage( { id: DataUtils.uuid(), message: "Permisos insuficientes para la acción que intentas realizar" } ) );
};
export const openShopBookingOffcanvas = ( step, editingShopBooking ) => ( dispatch ) => {
    dispatch( changeShopCartAttrs( {
        isEditingShopBookingStep: step || "booking",
        isEditingShopBooking: true,
        editingShopBooking: editingShopBooking || {},
        isEditingShopBookingServiceSearchText: "",
        isEditingShopBookingEditingId: DataUtils.uuid()
    } ) );
};
export const closeShopBookingOffcanvas = () => ( dispatch ) => {
    dispatch( changeShopCartAttrs( {
        isEditingShopBooking: false,
        isEditingShopBookingStep: "booking",
        isEditingShopBookingServiceSearchText: ""
    } ) );
};
export const shopAddItemServiceToCart = ( item ) => ( dispatch, getState ) => {
    dispatch( changeShopCartAttr( "isAddingShopItemId", item.id ) );
    return dispatch( getCalendarBookedSlots() )
        .then( () => dispatch( getCalendarTeamMembers() ) )
        .then( () => {
            const allConflictingBookings = getAllConflictingBookings( null, getState().pwSession.shop.cartBookings, getState().pwSession.shopAttributes.bookedSlots );
            // available days?
            let availableDays = [];
            if ( Pages.company.stopBookingUnavailable ) {
                availableDays = getServiceAvailableDays( item.service, getState().pwSession.shopAttributes.teamMembers, null, allConflictingBookings );
            }
            if ( Pages.company.stopBookingUnavailable && availableDays.length === 0 ) {
                dispatch( openShopBookingOffcanvas( "shopItemNotAvailable", { shopItem: item } ) );
            } else {
                dispatch( shopAddItemToCart( item ) );
                dispatch( openShopBookingOffcanvas( "booking", { shopItem: item } ) );
            }
            dispatch( changeShopCartAttr( "isAddingShopItemId", null ) );
        } )
        .catch( () => dispatch( changeShopCartAttr( "isAddingShopItemId", null ) ) );
};
