/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isLoading: false,
    isError: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    editing: {},
    items: [],
    toastMessages: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_PAGES_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_PAGES_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_PAGES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_PAGE_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_PAGE_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_PAGE_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_PAGE_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_PAGE_NEW_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_PAGE_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_PAGE_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_PAGE_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_PAGE_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_PAGE_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_PAGE_CHANGE_UPDATE_ITEM": {
            const itemIndex = state.editing.dynamicContent.findIndex( tmp => tmp.dynamicContentId === action.item.dynamicContentId );
            let items = state.editing.dynamicContent;
            if ( itemIndex > -1 ) {
                const mutation = {};
                mutation[ itemIndex ] = action.item;
                items = Object.assign( [], items, mutation );
            }
            return update( state, { editing: update( state.editing, { dynamicContent: items } ) } );
        }
        case "MANAGER_PAGE_CHANGE_ADD_ITEM": {
            const items = state.editing.dynamicContent ? state.editing.dynamicContent.map( item => item ) : [];
            items.push( action.item );
            return update( state, { editing: update( state.editing, { dynamicContent: items } ) } );
        }
        case "MANAGER_PAGE_CHANGE_REMOVE_ITEM": {
            const items = state.editing.dynamicContent.filter( item => item.dynamicContentId !== action.item.dynamicContentId );
            return update( state, { editing: update( state.editing, { dynamicContent: items } ) } );
        }
        case "MANAGER_PAGE_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessages: newToastMessages } );
        }
        case "MANAGER_PAGE_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessages: newToastMessages } );
        }
        default: return state;
    }
};
