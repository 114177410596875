/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isEditingChild: false,
    isLoading: false,
    isLoadingChild: false,
    isError: false,
    isErrorChild: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    errorMessageChild: "",
    editing: {},
    editingChild: {},
    items: [],
    toastMessages: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_LOCATIONS_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_LOCATIONS_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_LOCATIONS_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_LOCATION_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.customer
            } );
        }
        case "MANAGER_LOCATION_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_LOCATION_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_LOCATION_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_LOCATION_NEW_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_LOCATION_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_LOCATION_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_LOCATION_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_LOCATIONS_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_LOCATION_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_LOCATION_CHANGE_EDIT_CHILD_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_LOCATIONS_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessages: newToastMessages } );
        }
        case "MANAGER_LOCATIONS_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessages: newToastMessages } );
        }
        default: return state;
    }
};
