/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable complexity */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Ratio, CardGroup, Card, ButtonGroup, Button, Modal, Alert, Badge, Row, Col, Image, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IoIosArrowDown, IoMdDownload, IoMdPin, IoLogoInstagram, IoLogoFacebook, IoIosGlobe, IoIosArrowUp, IoMdCalendar, IoIosAddCircle, IoIosRemoveCircle, IoIosClose, IoMdCart, IoIosArrowForward, IoLogoTwitter, IoLogoWhatsapp, IoMdCall, IoIosCall } from "react-icons/io";
import Fade from "react-reveal/Fade";
import ReactPlayer from "react-player";
import ExpandCollapse from "../components/widgets/ExpandCollapse";
import GenericGallery from "../components/GenericGallery";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/SessionActions";
import * as Pages from "../utils/Pages";
import WebContext from "../utils/WebContext";
import * as DataUtils from "../utils/DataUtils";
import * as ShopUtils from "../utils/ShopUtils";
import * as CoursesUtils from "../utils/CoursesUtils";
import DynamicSlider from "./dynamiccontent/DynamicSlider";
import Icon from "./widgets/Icon";

class GenericDynamicContent extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            modalCourseShow: false, modalCourse: {}, viewMoreContentShow: false, viewMoreContentCard: {}
        };
        this.renderDynamicContent = this.renderDynamicContent.bind( this );
        this.shopCartRelativeUrl = Pages.getPage( "shop-cart" ) ? Pages.getPage( "shop-cart" ).relativeUrl : "/";
        this.shopRelativeUrl = Pages.getPage( "shop" ) ? Pages.getPage( "shop" ).relativeUrl : "/";
        this.locationsRelativeUrl = Pages.getPage( "locations" ) ? Pages.getPage( "locations" ).relativeUrl : "/";
        this.skipDynamicContentContainer = false;
        let type = null;
        this.shopCartUrl = Pages.getPage( "shop-cart" ).relativeUrl;
        if ( props.items ) {
            for ( let index = 0; index < props.items.length; index += 1 ) {
                const item = props.items[ index ];
                type = item.type || item.dynamicContentType;
                if ( [ "shop_featured_items", "shop_card" ].includes( type ) ) {
                    this.skipDynamicContentContainer = true;
                    break;
                }
            }
        }
    }
    componentDidMount() {
        this.props.loadUIContext( { noScrollEvents: true } );
    }
    renderDynamicContent( items, argParentIndex ) {
        if ( !items ) {
            return null;
        }
        const lang = this.props.pwSession.language;
        return items.map( ( argContent, i ) => {
            const parentIndex = typeof argParentIndex === "undefined" ? i : argParentIndex;
            const contentType = argContent.type || argContent.dynamicContentType;
            let content = argContent;
            if ( content.dynamicContentJsonProps ) {
                content = Object.assign( {}, content, content.dynamicContentJsonProps );
            }
            if ( !content.value && content.dynamicContentValue ) {
                content.value = content.dynamicContentValue;
            }
            let containerHtmlId = null;
            if ( content.dynamicContentId ) {
                containerHtmlId = content.dynamicContentId;
            }
            if ( content.htmlId ) {
                containerHtmlId = content.htmlId;
            }
            if ( content.dynamicContentValue && content.dynamicContentValue.htmlId ) {
                containerHtmlId = content.dynamicContentValue.htmlId.trim();
            }
            // deactivated items
            if ( content.dynamicContentId && !content.dynamicContentActive ) {
                return null;
            }
            if ( !containerHtmlId ) {
                containerHtmlId = DataUtils.uuid();
            }
            switch ( contentType ) {
                case "img": {
                    return (
                        <div className="dyncontent-img-container" key={ `dyncontent${ i }-${ parentIndex }` }>
                            <img src={ content.value } alt={ Pages.company.seoDefaultAlt } style={ content.style ? content.style : null } width={ content.width ? content.width : "100%" } />
                            { content.captionDescription ? <div className="dyncontent-img-caption">{ content.captionDescription }</div> : null }
                        </div>
                    );
                }
                case "h1": {
                    return ( <h1 key={ `dyncontent${ i }-${ parentIndex }` }>{ content.value }</h1> );
                }
                case "h2": {
                    return ( <h2 key={ `dyncontent${ i }-${ parentIndex }` }>{ content.value }</h2> );
                }
                case "h3": {
                    return ( <h3 key={ `dyncontent${ i }-${ parentIndex }` }>{ content.value }</h3> );
                }
                case "h4": {
                    return ( <h4 key={ `dyncontent${ i }-${ parentIndex }` }>{ content.value }</h4> );
                }
                case "b": {
                    return ( <b key={ `dyncontent${ i }-${ parentIndex }` }>{ content.value }</b> );
                }
                case "gallery": {
                    let id = null;
                    let gallery = null;
                    if ( typeof content.value === "string" ) {
                        id = content.value;
                    } else if ( content.value ) {
                        gallery = {
                            id: content.dynamicContentId,
                            title: content.dynamicContentTitle,
                            subTitle: content.dynamicContentSubTitle,
                            showTitle: content.dynamicContentShowTitle,
                            viewMoreUrl: content.dynamicContentViewMoreUrl,
                            viewMoreText: content.dynamicContentViewMoreText,
                            targetRowHeight: 400,
                            custom: true,
                            photos: content.value.map( tmpPhoto => {
                                const tmpResult = {
                                    width: tmpPhoto.width,
                                    height: tmpPhoto.height,
                                    src: DataUtils.getPublicImageUrl( tmpPhoto.imageUrl ),
                                    alt: Pages.company.seoDefaultAlt
                                };
                                if ( tmpPhoto.overlayTitle || tmpPhoto.viewMoreContentHtml ) {
                                    tmpResult.overlay = {
                                        title: tmpPhoto.overlayTitle,
                                        shortText: tmpPhoto.overlayShortText,
                                        html: tmpPhoto.viewMoreContentHtml
                                    };
                                }
                                return tmpResult;
                            } )
                        };
                        if ( content.dynamicContentJsonProps ) {
                            gallery = Object.assign( {}, gallery, content.dynamicContentJsonProps );
                        }
                    }
                    return ( <GenericGallery id={ id } gallery={ gallery } /> );
                }
                case "span": {
                    return ( <span key={ `dyncontent${ i }-${ parentIndex }` }>{ content.value }</span> );
                }
                case "p": {
                    if ( content.isHTML ) {
                        return ( <p key={ `dyncontent${ i }-${ parentIndex }` } dangerouslySetInnerHTML={ { __html: content.value } } /> );
                    }
                    return ( <p key={ `dyncontent${ i }-${ parentIndex }` }>{ content.values ? this.renderDynamicContent( content.values, i ) : content.value }</p> );
                }
                case "div": {
                    if ( content.isHTML ) {
                        return ( <div key={ `dyncontent${ i }-${ parentIndex }` } dangerouslySetInnerHTML={ { __html: content.value } } /> );
                    }
                    return ( <div key={ `dyncontent${ i }-${ parentIndex }` }>{ content.values ? this.renderDynamicContent( content.values, i ) : content.value }</div> );
                }
                case "br": {
                    return ( <br key={ `dyncontent${ i }-${ parentIndex }` } /> );
                }
                case "hr": {
                    return ( <hr key={ `dyncontent${ i }-${ parentIndex }` } /> );
                }
                case "pwithbold": {
                    return ( <hr key={ `dyncontent${ i }-${ parentIndex }` } /> );
                }
                case "youtube": {
                    return (
                        <Ratio key={ `dyncontent${ i }-${ parentIndex }` } style={ content.style ? content.style : { width: "100%" } } aspectRatio={ content.aspectRatio ? `${ content.aspectRatio.width }x${ content.aspectRatio.height }` : "16x9" }>
                            <iframe title={ `youtube-${ i }` } width="100%" height="100%" src={ content.value } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </Ratio>
                    );
                }
                case "2columns": {
                    return (
                        <div className="dyncontent-2columns-container" key={ `dyncontent${ i }-${ parentIndex }` }>
                            <div className="dyncontent-2columns-column">
                                { this.renderDynamicContent( content.value[ "1" ], i ) }
                            </div>
                            <div className="dyncontent-2columns-column">
                                { this.renderDynamicContent( content.value[ "2" ], i ) }
                            </div>
                        </div>
                    );
                }
                case "expandcollapse": {
                    return (
                        <ExpandCollapse
                            key={ `dyncontent${ i }-${ parentIndex }` }
                            title={ content.title }
                            expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                            collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                        >
                            { content.value && !content.isHTML ? <div>{ content.value }</div> : null }
                            { content.value && content.isHTML ? <div dangerouslySetInnerHTML={ { __html: content.value } } /> : null }
                        </ExpandCollapse>
                    );
                }
                case "carddeck": {
                    return (
                        <CardGroup key={ DataUtils.uuid() } className={ content.className ? content.className : null }>
                            { content.value.map( card => {
                                let subTitleIcon = null;
                                let showSocialLinks = false;
                                if ( card.socialLinksInstagramUrl || card.socialLinksFacebookUrl || card.socialLinksWebUrl ) {
                                    showSocialLinks = true;
                                }
                                switch ( card.subTitleIcon ) {
                                    case "calendar":
                                        subTitleIcon = <IoMdCalendar />;
                                        break;
                                    case "location":
                                        subTitleIcon = <IoMdPin />;
                                        break;
                                    default:
                                        break;
                                }
                                if ( card.type === "overlay" ) {
                                    let isInCart = false;
                                    let cartItem = null;
                                    let shopItemPriceCurrency = null;
                                    let shopItemPriceAmount = null;
                                    if ( card.shopItem ) {
                                        cartItem = this.props.pwSession.shop.cartItems.find( addedItem => addedItem.id === card.shopItem.id );
                                        if ( cartItem && cartItem.quantity > 0 ) {
                                            isInCart = true;
                                        }
                                        shopItemPriceAmount = card.shopItem.priceAmount;
                                        shopItemPriceCurrency = card.shopItem.priceCurrency;
                                    }
                                    const topOfferTextStyle = {};
                                    if ( card.topOfferTextBgColor ) {
                                        topOfferTextStyle.background = card.topOfferTextBgColor;
                                    }
                                    if ( card.topOfferTextColor ) {
                                        topOfferTextStyle.color = card.topOfferTextColor;
                                        topOfferTextStyle.borderColor = card.topOfferTextBgColor;
                                    }
                                    return (
                                        <Card key={ DataUtils.uuid() } style={ { background: `url(${ card.imgSrc }) no-repeat center center`, backgroundSize: "cover" } }>
                                            <Card.ImgOverlay>
                                                { card.topOfferText ? <div className="pw_offer_shorttext" style={ topOfferTextStyle }>{ Pages.dynamicText( this.context.language, card.topOfferText ) }</div> : null }
                                                <Card.Title>{ Pages.dynamicText( this.context.language, card.title ) }</Card.Title>
                                                { card.subTitle ? <Card.Subtitle>{ subTitleIcon }{ Pages.dynamicText( this.context.language, card.subTitle ) }</Card.Subtitle> : null }
                                                { card.thirdTitle ? <h3>{ card.thirdTitle }</h3> : null }
                                                { card.embed16x9 ? <Ratio aspectRatio="16x9"><div dangerouslySetInnerHTML={ { __html: card.embed16x9 } } /></Ratio> : null }
                                                <Card.Text>
                                                    <div dangerouslySetInnerHTML={ { __html: Pages.dynamicText( this.context.language, card.shortText ) } } />
                                                </Card.Text>
                                                { card.shopItem ? <div className="shop_item_cart_panel">
                                                    <h5>{ DataUtils.formatIntegerPrice( shopItemPriceCurrency, shopItemPriceAmount )}</h5>
                                                    <ButtonGroup>
                                                        <Button variant="pw-primary" onClick={ () => this.props.shopAddItemToCart( card.shopItem ) }>{ isInCart ? <Badge bg="light" text="dark">{ cartItem.quantity }</Badge> : <IoMdCart />} { isInCart ? Pages.text( this.context.language, "shop.item.mycart.already" ) : Pages.text( this.context.language, "shop.item.mycart.add" ) }</Button>
                                                        { isInCart ? <Button variant="info" href={ this.shopCartUrl }><IoIosArrowForward />{ Pages.text( this.context.language, "shop.item.mycart.view" ) }</Button> : null }
                                                    </ButtonGroup>
                                                </div> : null }
                                                { card.viewMoreContentHtml ? <ButtonGroup>
                                                    <Button
                                                        variant="pw-primary"
                                                        onClick={ () => this.setState( { viewMoreContentShow: true, viewMoreContentCard: card } ) }
                                                    >
                                                        Más info
                                                    </Button>
                                                </ButtonGroup> : null }
                                                { card.detailButton ? <ButtonGroup>
                                                    <Button
                                                        variant="pw-primary"
                                                        onClick={ card.detailButton.onClick }
                                                    >
                                                        { card.detailButton.text }
                                                    </Button>
                                                </ButtonGroup> : null }
                                                { card.viewMoreUrl ? <ButtonGroup>
                                                    <Button
                                                        variant="pw-primary"
                                                        href={ card.viewMoreUrl }
                                                    >
                                                        { Pages.dynamicText( this.context.language, card.viewMoreText ) || "Más info" }
                                                    </Button>
                                                </ButtonGroup> : null }
                                                { card.pdfURL ? <ButtonGroup>
                                                    <Button
                                                        variant="pw-primary"
                                                        href={ card.pdfURL }
                                                    >
                                                        <IoMdDownload /> { card.pdfURLText }
                                                    </Button>
                                                </ButtonGroup> : null }
                                            </Card.ImgOverlay>
                                        </Card>
                                    );
                                } else if ( card.type === "card" ) {
                                    const showBody = card.title || card.subTitle || card.thirdTitle || card.shortText || card.embed16x9 || showSocialLinks;
                                    const topOfferTextStyle = {};
                                    if ( card.topOfferTextBgColor ) {
                                        topOfferTextStyle.background = card.topOfferTextBgColor;
                                    }
                                    if ( card.topOfferTextColor ) {
                                        topOfferTextStyle.color = card.topOfferTextColor;
                                    }
                                    return (
                                        <Card key={ DataUtils.uuid() }>
                                            { card.imgSrc ? <Card.Img variant="top" src={ card.imgSrc } /> : null }
                                            { showBody ? <Card.Body>
                                                { card.topOfferText ? <div className="pw_offer_shorttext" style={ topOfferTextStyle }>{ Pages.dynamicText( this.context.language, card.topOfferText ) }</div> : null }
                                                <Card.Title>{ card.title }</Card.Title>
                                                { card.subTitle ? <Card.Subtitle>{ subTitleIcon }{ Pages.dynamicText( this.context.language, card.subTitle ) }</Card.Subtitle> : null }
                                                { card.thirdTitle ? <h3>{ card.thirdTitle }</h3> : null }
                                                { card.shortText ? <Card.Text><div dangerouslySetInnerHTML={ { __html: Pages.dynamicText( this.context.language, card.shortText ) } } /></Card.Text> : null }
                                                { card.embed16x9 ? <Ratio aspectRatio="16x9"><div dangerouslySetInnerHTML={ { __html: card.embed16x9 } } /></Ratio> : null }
                                                { showSocialLinks ?
                                                    <div className="d-flex justify-content-center" style={ { fontSize: "2rem" } }>
                                                        { card.socialLinksInstagramUrl ? <Card.Link href={ card.socialLinksInstagramUrl }><IoLogoInstagram /></Card.Link> : null }
                                                        { card.socialLinksFacebookUrl ? <Card.Link href={ card.socialLinksFacebookUrl }><IoLogoFacebook /></Card.Link> : null }
                                                        { card.socialLinksWebUrl ? <Card.Link href={ card.socialLinksWebUrl }><IoIosGlobe /></Card.Link> : null }
                                                        { card.socialLinksPhone ? <Card.Link href={ card.socialLinksPhone }><IoIosCall /></Card.Link> : null }
                                                    </div> : null }
                                                { card.viewMoreUrl ? <ButtonGroup>
                                                    <Button
                                                        variant="pw-primary"
                                                        href={ card.viewMoreUrl }
                                                    >
                                                        { Pages.dynamicText( this.context.language, card.viewMoreText ) || "Más info" }
                                                    </Button>
                                                </ButtonGroup> : null }
                                                { card.viewMoreContentHtml ? <ButtonGroup>
                                                    <Button
                                                        variant="pw-primary"
                                                        onClick={ () => this.setState( { viewMoreContentShow: true, viewMoreContentCard: card } ) }
                                                    >
                                                        Más info
                                                    </Button>
                                                </ButtonGroup> : null }
                                            </Card.Body> : null }
                                        </Card>
                                    );
                                } else if ( card.type === "review" ) {
                                    return (
                                        <Card key={ DataUtils.uuid() } className="pw-card-review pw-shadow">
                                            <Card.Body>
                                                <Row>
                                                    { card.imgSrc ?
                                                        <Col xs="3" className="pw-card-review-avatar">
                                                            <Image roundedCircle thumbnail src={ card.imgSrc } />
                                                        </Col> : null }
                                                    <Col className="pw-card-review-info" xs={ card.imgSrc ? "9" : "12" }>
                                                        <h6>{ card.title }</h6>
                                                        <h7>{ card.subTitle }</h7>
                                                        <div className="pw-review-stars"><Icon name="star" /><Icon name="star" /><Icon name="star" /><Icon name="star" /><Icon name="star" /></div>
                                                    </Col>
                                                </Row>
                                                <div className="pw-card-review-comment">‘‘<div dangerouslySetInnerHTML={ { __html: card.shortText } } />”</div>
                                            </Card.Body>
                                        </Card>
                                    );
                                } else if ( card.type === "expandcollapse" ) {
                                    return (
                                        <Card key={ DataUtils.uuid() }>
                                            { card.imgSrc ? <Card.Img variant="top" src={ card.imgSrc } /> : null }
                                            <Card.Body>
                                                <ExpandCollapse
                                                    title={ card.title }
                                                    expandIcon={ <IoIosAddCircle className="pw_widget_expand_collapse_icon_expand" /> }
                                                    collapseIcon={ <IoIosRemoveCircle className="pw_widget_expand_collapse_icon_collapse" /> }
                                                >
                                                    { card.shortText ? <Card.Text><Fade in={ this.state.openText }><div dangerouslySetInnerHTML={ { __html: card.shortText } } /></Fade></Card.Text> : null }
                                                </ExpandCollapse>
                                            </Card.Body>
                                        </Card>
                                    );
                                } else if ( card.type === "emptycard" ) {
                                    return ( <Card key={ DataUtils.uuid() } className="empty_card" /> );
                                } else if ( card ) {
                                    return card;
                                }
                                return null;
                            } ) }
                        </CardGroup>
                    );
                }
                case "colorsection": {
                    if ( content.dynamicContentValue ) {
                        if ( !content.dynamicContentValue.title && !content.dynamicContentValue.subTitle ) {
                            return null;
                        }
                        const style = { margin: 0 };
                        const titleStyle = { padding: 0 };
                        const subTitleStyle = { padding: 0 };
                        if ( content.dynamicContentValue.backgroundColor ) {
                            style.background = content.dynamicContentValue.backgroundColor;
                        }
                        if ( content.dynamicContentValue.textColor ) {
                            titleStyle.color = content.dynamicContentValue.textColor;
                            subTitleStyle.color = content.dynamicContentValue.textColor;
                        }
                        if ( content.dynamicContentValue.titleColor ) {
                            titleStyle.color = content.dynamicContentValue.titleColor;
                        }
                        return ( <div key={ `dyncontent${ i }-${ parentIndex }` } className="dyncontent-colorsection" style={ style }>
                            <h2 className="pw_section_title" style={ titleStyle }>{ Pages.dynamicText( this.context.language, content.dynamicContentValue.title ) }</h2>
                            <h3 className="pw_section_subtitle" style={ subTitleStyle } dangerouslySetInnerHTML={ { __html: Pages.dynamicText( this.context.language, content.dynamicContentValue.subTitle ) } } />
                            { content.dynamicContentValue.viewMoreUrl ?
                                <div className="dyncontent-colorsection-buttons">
                                    <ButtonGroup>
                                        <Button variant="pw-primary" href={ content.dynamicContentValue.viewMoreUrl }>{ content.dynamicContentValue.viewMoreText ? content.dynamicContentValue.viewMoreText : "Ver más" }</Button>
                                    </ButtonGroup>
                                </div>
                                : null }
                        </div> );
                    }
                    if ( content.isHTML ) {
                        return ( <div key={ `dyncontent${ i }-${ parentIndex }` } className="dyncontent-colorsection" dangerouslySetInnerHTML={ { __html: Pages.dynamicText( this.context.language, content.value ) } } /> );
                    }
                    return ( <div key={ `dyncontent${ i }-${ parentIndex }` } className="dyncontent-colorsection">{ content.values ? this.renderDynamicContent( content.values, i ) : null }</div> );
                }
                case "featuredsection": {
                    if ( content.isHTML ) {
                        return ( <div key={ `dyncontent${ i }-${ parentIndex }` } className="dyncontent-featuredsection" dangerouslySetInnerHTML={ { __html: content.value } } /> );
                    }
                    return ( <div key={ `dyncontent${ i }-${ parentIndex }` } className="dyncontent-featuredsection">{ content.values ? this.renderDynamicContent( content.values, i ) : content.value }</div> );
                }
                case "courses_cardecks_overlay_categories": {
                    if ( !this.props.content.courses || !this.props.content.courses.items ) {
                        return null;
                    }
                    const coursesCategories = [];
                    this.props.content.courses.items.forEach( tmpCourse => {
                        if ( tmpCourse.courseCategories ) {
                            tmpCourse.courseCategories.forEach( tmpCourseCategory => {
                                const already = coursesCategories.findIndex( tmpI => tmpI.courseAllCategoryId === tmpCourseCategory.courseCategoryCategoryId );
                                if ( already === -1 ) {
                                    const category = Object.assign( {}, tmpCourseCategory.courseCategory, { categoryCourses: [ tmpCourse ] } );
                                    coursesCategories.push( category );
                                } else {
                                    coursesCategories[ already ].categoryCourses.push( tmpCourse );
                                }
                            } );
                        }
                    } );
                    DataUtils.sortArray( coursesCategories, { sortingKeys: [ { alias: "courseAllCategoryOrderNumber", direction: "asc" } ] } );
                    const elements = [];
                    coursesCategories.forEach( courseAllCategory => {
                        elements.push( { type: "h2", value: courseAllCategory.courseAllCategoryName } );
                        elements.push( { type: "courses_cardecks_overlay", categoryItems: courseAllCategory.categoryCourses, deckSize: content.deckSize } );
                    } );
                    return ( <div key={ DataUtils.uuid() } className="courses_cardecks_overlay_categories_container">
                        { this.renderDynamicContent( elements ) }
                    </div> );
                }
                case "courses_cardecks_overlay": {
                    if ( !this.props.content.courses || !this.props.content.courses.items || !content.categoryItems ) {
                        return null;
                    }
                    let useItems = this.props.content.courses.items;
                    if ( content.categoryItems ) {
                        useItems = content.categoryItems;
                    }
                    const carddecks = [];
                    let carddeckCardData = [];
                    for ( let index = 0; index < useItems.length; index += 1 ) {
                        const course = useItems[ index ];
                        const cardData = {
                            type: "overlay",
                            imgSrc: `/content/public/${ course.courseImageCard }`,
                            title: course.courseTitle,
                            thirdTitle: course.courseTotalAmount ? DataUtils.formatIntegerPrice( "EUR", course.courseTotalAmount ) : null,
                            shortText: course.courseShortText,
                            embed16x9: course.courseEmbedCard,
                            detailButton: course.courseHtmlDescription || this.props.content.capabilities.dossiers ? { text: "Ver más", onClick: () => this.setState( { modalCourseShow: true, modalCourse: course } ) } : null
                        };
                        const nextClass = CoursesUtils.courseNextClass( course );
                        cardData.subTitle = course.courseDuration;
                        if ( nextClass ) {
                            cardData.subTitle += ` / Próxima clase: ${ nextClass }`;
                            cardData.subTitleIcon = "calendar";
                        }
                        carddeckCardData.push( cardData );
                        if ( carddeckCardData.length === content.deckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_courses_carddeck" } );
                            carddeckCardData = [];
                        }
                    }
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_courses_carddeck" } );
                    }
                    const nextClasses = CoursesUtils.courseNextClasses( this.state.modalCourse, 3 );
                    const dossierPdf = this.state.modalCourse.coursePdfDossier ? this.state.modalCourse.coursePdfDossier : `dossier-curso-${ this.state.modalCourse.courseId }.pdf`;
                    return (
                        <div key={ DataUtils.uuid() } className="courses_cardecks_overlay_container">
                            { this.renderDynamicContent( carddecks ) }
                            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={ this.state.modalCourseShow } onHide={ () => this.setState( { modalCourseShow: false } ) }>
                                <Modal.Body>
                                    <Card>
                                        { this.state.modalCourse.courseEmbedCard ? <Ratio aspectRatio="16x9"><div dangerouslySetInnerHTML={ { __html: this.state.modalCourse.courseEmbedCard } } /></Ratio> : null }
                                        { !this.state.modalCourse.courseEmbedCard ? <Card.Img variant="top" src={ `/content/public/${ this.state.modalCourse.courseImageCard }` } /> : null }
                                        <Card.Body>
                                            <Card.Title>{ this.state.modalCourse.courseTitle }</Card.Title>
                                            { this.state.modalCourse.courseDuration ? <Card.Subtitle>Duración: { this.state.modalCourse.courseDuration }</Card.Subtitle> : null }
                                            <Card.Subtitle style={ { marginTop: "1rem" } }>Próximas fechas:</Card.Subtitle>
                                            <ul>
                                                { nextClasses.length > 0 ? nextClasses.map( nextClass => ( <li><b>{ nextClass.text }</b></li> ) ) : <li><b>Consultar</b></li> }
                                            </ul>
                                            <div dangerouslySetInnerHTML={ { __html: this.state.modalCourse.courseHtmlDescription } } />
                                            { this.state.modalCourse.courseCommentsForUsers ?
                                                <Alert variant="warning">
                                                    { this.state.modalCourse.courseCommentsForUsers }
                                                </Alert> : null }
                                        </Card.Body>
                                    </Card>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={ () => this.setState( { modalCourseShow: false } ) }><IoIosClose /> Cerrar</Button>
                                    { this.props.content.capabilities.dossiers ? <Button
                                        variant="pw-primary"
                                        href={ `/content/public/${ dossierPdf }` }
                                    ><IoMdDownload /> Dossier del curso</Button> : null }
                                </Modal.Footer>
                            </Modal>
                        </div>
                    );
                }
                case "services_and_categories_expandcollapse": {
                    if ( !this.props.content.services ) {
                        return null;
                    }
                    const carddecks = [];
                    let carddeck = [];
                    for ( let index = 0; index < this.props.content.services.categories.length; index += 1 ) {
                        const category = this.props.content.services.categories[ index ];
                        carddeck.push( <Card key={ DataUtils.uuid() }>
                            <Fade>
                                <Card.Img variant="top" src={ category.cardImage } alt={ category.name } />
                            </Fade>
                            <Card.Body>
                                <ExpandCollapse
                                    title={ category.name }
                                    expandIcon={ <IoIosAddCircle className="pw_widget_expand_collapse_icon_expand" /> }
                                    collapseIcon={ <IoIosRemoveCircle className="pw_widget_expand_collapse_icon_collapse" /> }
                                >
                                    { category.items.map( ( item, itemIndex ) => (
                                        <ExpandCollapse
                                            key={ `s${ itemIndex }` }
                                            title={ item.name }
                                            expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                            collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                            addClass="items"
                                        >
                                            { item.shortText ? <div>{ item.shortText }</div> : null }
                                        </ExpandCollapse>
                                    ) )}
                                    { category.categories && category.categories.map( ( subc, subcIndex ) => (
                                        <ExpandCollapse
                                            key={ `s${ subcIndex }` }
                                            title={ subc.name }
                                            expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                            collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                        >
                                            { subc.items.map( ( subcItem, subcItemIndex ) => (
                                                <ExpandCollapse
                                                    key={ `s${ subcItemIndex }` }
                                                    title={ subcItem.name }
                                                    expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                                                    collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                                                    addClass="items"
                                                >
                                                    { subcItem.shortText ? <div>{ subcItem.shortText }</div> : null }
                                                </ExpandCollapse>
                                            ) )}
                                        </ExpandCollapse>
                                    ) )}
                                </ExpandCollapse>
                            </Card.Body>
                        </Card> );
                        if ( carddeck.length === content.deckSize ) {
                            carddecks.push( carddeck );
                            carddeck = [];
                        }
                    }
                    if ( carddeck.length > 0 ) {
                        const cardeckLength = carddeck.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeck.push( <Card key={ DataUtils.uuid() } style={ { border: 0 } } /> );
                        }
                        carddecks.push( carddeck );
                    }
                    return carddecks.map( cards => <CardGroup key={ DataUtils.uuid() }>{ cards }</CardGroup> );
                }
                case "alert":
                    return ( <Alert key={ DataUtils.uuid() } variant={ content.variant ? content.variant : "warning" }>{ content.value }</Alert> );
                case "badgeh5":
                    return ( <h5 key={ DataUtils.uuid() }>{ content.titleValue ? content.titleValue : null }<Badge bg={ content.variant ? content.variant : "secondary" }>{ content.value }</Badge></h5> );
                case "team_cards": {
                    if ( !this.props.content.team || !this.props.content.team.items ) {
                        return null;
                    }
                    const carddecks = [];
                    let carddeckCardData = [];
                    const filteredTeam = this.props.content.team.items.filter( teamMember => teamMember.teamMemberWebActive );
                    for ( let index = 0; index < filteredTeam.length; index += 1 ) {
                        const teamMember = filteredTeam[ index ];
                        const cardData = {
                            type: "card",
                            imgSrc: `/content/public/${ teamMember.teamMemberImage }`,
                            title: teamMember.teamMemberFirstName,
                            subTitle: teamMember.teamMemberRole
                        };
                        carddeckCardData.push( cardData );
                        if ( carddeckCardData.length === content.deckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_team_cards" } );
                            carddeckCardData = [];
                        }
                    }
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_team_carddeck" } );
                    }
                    return (
                        <div key="pw-team-cards-container-id" className="pw_team_cards_container">
                            { this.renderDynamicContent( carddecks ) }
                        </div>
                    );
                }
                case "location_cards": {
                    const carddecks = [];
                    let carddeckCardData = [];
                    content.value.forEach( card => {
                        carddeckCardData.push( card );
                        if ( carddeckCardData.length === content.deckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_locations_carddeck" } );
                            carddeckCardData = [];
                        }
                    } );
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_locations_carddeck" } );
                    }
                    return (
                        <div key="pw-location-cards-container-id" className="pw_location_cards_container">
                            { this.renderDynamicContent( carddecks ) }
                        </div>
                    );
                }
                case "shop_cards": {
                    const carddecks = [];
                    let carddeckCardData = [];
                    const shopCardOptions = content.shopCardOptions ? content.shopCardOptions : {};
                    content.value.forEach( shopCardContentValue => {
                        carddeckCardData.push( this.renderDynamicContent( [ Object.assign( { type: "shop_card", value: shopCardContentValue }, shopCardOptions ) ] ) );
                        if ( carddeckCardData.length === content.deckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_shop_cards_carddeck" } );
                            carddeckCardData = [];
                        }
                    } );
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_shop_cards_carddeck" } );
                    }
                    return (
                        <div key="pw-shop-cards-container-id" className="pw_shop_cards_container">
                            { this.renderDynamicContent( carddecks ) }
                        </div>
                    );
                }
                case "shop_card": {
                    let item = Pages.applyShopOverides( content.value );
                    item = ShopUtils.applyShopItemOverrides( item, this.props.pwSession.userIsReseller );
                    let isResellerPrice = false;
                    if ( this.props.pwSession.userIsReseller && item.resellerPriceAmount ) {
                        isResellerPrice = true;
                    }
                    let category = this.props.content.shop.categories.find( cat => cat.id === item.categoryId );
                    const brand = this.props.content.shop.brands.find( bra => bra.shopBrandId === item.brandId );
                    let categoryName = "";
                    if ( category ) {
                        category = Pages.applyShopOverides( category );
                        categoryName = category.name;
                    }
                    const cartItem = this.props.pwSession.shop.cartItems.find( addedItem => addedItem.id === item.id );
                    let isInCart = false;
                    if ( cartItem && cartItem.quantity > 0 ) {
                        isInCart = true;
                    }
                    const topOfferTextStyle = {};
                    if ( item.topOfferTextBgColor ) {
                        topOfferTextStyle.background = item.topOfferTextBgColor;
                    }
                    if ( item.topOfferTextColor ) {
                        topOfferTextStyle.color = item.topOfferTextColor;
                    }
                    const detailedView = content.detailedView;
                    let hidePrice = content.hidePrice;
                    let hideAddToCart = content.hideAddToCart;
                    const hideViewCart = content.hideViewCart;
                    const shopItemPriceAmount = item.priceAmount;
                    const shopItemPriceCurrency = item.priceCurrency;
                    let disableShortTextLimit = false;
                    let showDescriptionVideo = false;
                    let showSuggestedBonos = false;
                    let showHtmlDetails = false;
                    if ( detailedView ) {
                        showDescriptionVideo = true;
                        disableShortTextLimit = true;
                        showSuggestedBonos = true;
                        showHtmlDetails = true;
                    }
                    if ( item.shopItemSoldOut ) {
                        hidePrice = true;
                        hideAddToCart = true;
                    }
                    const previousPriceAmount = ShopUtils.getShopItemPreviousPrice( item );
                    return (
                        <Card key={ `item-${ item.id }` }>
                            { !item.vimeoTop ?
                                <Card.Img
                                    onClick={ () => {
                                        window.location.href = item.relativeUrl;
                                    } }
                                    variant="top"
                                    style={ { cursor: "pointer" } }
                                    src={ item.videoTopPreview ? item.videoTopPreview : DataUtils.getPublicImageUrl( item.imageTop ) }
                                    alt={ item.title }
                                /> : null }
                            <Card.Body>
                                { item.vimeoTop ?
                                    <Ratio style={ { width: "100%" } } aspectRatio={ item.aspectRatio ? `${ item.aspectRatio.width }x${ item.aspectRatio.height }` : "16x9" }>
                                        <iframe title={ `vimeo-${ item.id }` } src={ `https://player.vimeo.com/video/${ item.vimeoTop }` } width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                    </Ratio>
                                    : null
                                }
                                { category && !category.hideItemTitles ?
                                    <Card.Title>
                                        <a href={ `${ item.relativeUrl }` }>
                                            { item.title }
                                        </a>
                                    </Card.Title> : null
                                }
                                { category && !category.hideItemCategories && categoryName ?
                                    <Card.Subtitle className="mb-2 text-muted pw_shop_card_category"><a href={ `${ this.shopRelativeUrl }/${ item.categoryId }` }>{ categoryName }</a></Card.Subtitle>
                                    : null
                                }
                                { brand ?
                                    <Card.Subtitle className="mb-2 text-muted pw_shop_card_brand"><a href={ `${ this.shopRelativeUrl }/brand/${ item.brandId }` }>{ brand.shopBrandName }</a></Card.Subtitle>
                                    : null
                                }
                                { item.subTitle ?
                                    <Card.Subtitle className="pw_shop_card_subtitle mb-2 text-muted">{ item.subTitle }</Card.Subtitle>
                                    : null
                                }
                                { showSuggestedBonos && item.bonos ? item.bonos.map( tmpBono => ( <div className="pw_suggested_bono">
                                    <h4>{ tmpBono.bonoTitle } <Badge bg="secondary">{ DataUtils.formatIntegerPrice( "EUR", tmpBono.bonoPriceAmount, true ) }</Badge></h4>
                                </div> ) ) : null }
                                { item.shortText ?
                                    <Card.Text>
                                        { this.props.content.shop.shortTextWordsLimit && !disableShortTextLimit ? item.shortText.split( " " ).splice( 0, this.props.content.shop.shortTextWordsLimit ).join( " " ) : item.shortText }
                                        { category && category.showItemViewMore ?
                                            <a className="pw_shop_view_more" href={ `${ item.relativeUrl }` }>
                                                { Pages.text( lang, "shop.shorttext.viewmore" ) }
                                            </a> : null
                                        }
                                    </Card.Text> : []
                                }
                                { showDescriptionVideo && item.descriptionVideoUrl ?
                                    <div className="pw_shop_item_description_video">
                                        <Ratio style={ { width: "100%" } } aspectRatio="16x9">
                                            <ReactPlayer
                                                url={ item.descriptionVideoUrl }
                                                width="100%"
                                                height="100%"
                                                allowFullScreen
                                            />
                                        </Ratio>
                                    </div>
                                    : null
                                }
                                { showHtmlDetails && item.htmlDetails ? <div dangerouslySetInnerHTML={ { __html: item.htmlDetails } } /> : null }
                                <div className="shop_item_cart_panel">
                                    { item.topOfferText ? <div className="pw_offer_shorttext" style={ topOfferTextStyle }>{ item.topOfferText }</div> : null }
                                    { item.shopItemSoldOut ? <div className="pw_special_price_text">AGOTADO / No disponible</div> : null }
                                    { item.shopItemIsSpecialPrice ? <div className="pw_special_price_text">{ Pages.text( this.context.language, "shop.general.specialprice" ) }</div> : null }
                                    { !item.hidePrice && !hidePrice ? <h5>{ previousPriceAmount ? <span className="pw_shop_card_previous_price">{ DataUtils.formatIntegerPrice( shopItemPriceCurrency, previousPriceAmount ) }</span> : null }{ isResellerPrice ? <span className="pw_reseller_price">{ Pages.text( this.context.language, "shop.item.resellerprice" ) }: </span> : null }{ DataUtils.formatIntegerPrice( shopItemPriceCurrency, shopItemPriceAmount )}</h5> : null }
                                    { item.type === "service" && isInCart ?
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-sm pw_shop_bookings_btn"
                                            disabled={ this.state.isLoadingItemIdBookings === item.id }
                                            onClick={ () => {
                                                this.setState( { isLoadingItemIdBookings: item.id } );
                                                this.props.getCalendarBookedSlots()
                                                    .then( () => this.props.getCalendarTeamMembers() )
                                                    .then( () => {
                                                        this.props.openShopBookingOffcanvas( "booking", { shopItem: item } );
                                                        this.setState( { isLoadingItemIdBookings: null } );
                                                    } )
                                                    .catch( () => this.setState( { isLoadingItemIdBookings: null } ) );
                                            } }
                                        >{ this.state.isLoadingItemIdBookings === item.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null } Ver citas agendadas</button> : null }
                                    { !item.hideAddToCart && !Pages.company.hideAddToCart && !hideAddToCart ?
                                        <ButtonGroup>
                                            <Button
                                                variant="pw-primary"
                                                disabled={ this.props.pwSession.shopAttributes.isAddingShopItemId === item.id }
                                                onClick={ () => {
                                                    if ( item.type === "service" ) {
                                                        this.props.shopAddItemServiceToCart( item );
                                                    } else {
                                                        this.props.shopAddItemToCart( item );
                                                    }
                                                } }
                                            >
                                                { this.props.pwSession.shopAttributes.isAddingShopItemId === item.id ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }
                                                { isInCart ? <Badge bg="light" text="dark">{ cartItem.quantity }</Badge> : <IoMdCart />} { isInCart ? Pages.text( lang, "shop.item.mycart.already" ) : Pages.text( lang, "shop.item.mycart.add" ) }</Button>
                                            { isInCart && !hideViewCart ? <Button variant="info" href={ this.shopCartRelativeUrl }><IoIosArrowForward />{ Pages.text( lang, "shop.item.mycart.view" ) }</Button> : null }
                                        </ButtonGroup> : null }
                                </div>
                            </Card.Body>
                        </Card>
                    );
                }
                case "shop_featured_items": {
                    if ( !this.props.content.shop.featuredItems ) {
                        return null;
                    }
                    const carddecks = [];
                    let carddeckCardData = [];
                    for ( let index = 0; index < this.props.content.shop.featuredItems.length; index += 1 ) {
                        if ( this.props.content.shop.maxFeaturedItemsDisplayed && this.props.content.shop.maxFeaturedItemsDisplayed <= index ) {
                            break;
                        }
                        const item = this.props.content.shop.featuredItems[ index ];
                        const cardData = this.renderDynamicContent( [ { type: "shop_card", value: item } ] );
                        carddeckCardData.push( cardData );
                        if ( carddeckCardData.length === content.deckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_shop_featured_cards" } );
                            carddeckCardData = [];
                        }
                    }
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_shop_featured_cards" } );
                    }
                    return (
                        <div key="shop-featured-container-id" className="pw_shop_featured_container">
                            { this.renderDynamicContent( carddecks ) }
                        </div>
                    );
                }
                case "location_card": {
                    const item = Pages.applyShopOverides( content.value );
                    let category = this.props.content.locations.categories.find( cat => cat.locationCategoryCategoryId === item.locationCategoryId );
                    let categoryName = "";
                    if ( category ) {
                        category = Pages.applyShopOverides( category );
                        categoryName = category.locationCategoryName;
                    }
                    let showSocialLinks = false;
                    if ( item.locationWebsite || item.locationInstagramUrl || item.locationFacebookUrl || item.locationTwitterUrl || item.locationWhatsAppUrl ) {
                        showSocialLinks = true;
                    }
                    const itemUrl = `${ this.locationsRelativeUrl }/${ item.locationId }`;
                    let hasLink = false;
                    if ( item.locationEmbedMap || item.locationContent.length > 0 ) {
                        hasLink = true;
                    }
                    return (
                        <Card key={ `item-${ item.locationId }` }>
                            <Card.Img
                                onClick={ () => {
                                    if ( hasLink ) {
                                        window.location.href = itemUrl;
                                    }
                                } }
                                variant="top"
                                style={ { cursor: "pointer" } }
                                src={ item.locationImage ? DataUtils.getPublicImageUrl( item.locationImage ) : "/static/og_image_default.png" }
                                alt={ item.locationTitle }
                            />
                            <Card.Body>
                                <Card.Title>
                                    <a href={ hasLink ? itemUrl : null }>
                                        { item.locationTitle }
                                    </a>
                                </Card.Title>
                                { categoryName ?
                                    <Card.Subtitle className="mb-2 text-muted"><a href={ `${ this.locationsRelativeUrl }/${ item.locationCategoryId }` }>{ categoryName }</a></Card.Subtitle>
                                    : null
                                }
                                { item.locationShortText ?
                                    <Card.Text>
                                        { item.locationShortText }
                                    </Card.Text> : []
                                }
                                { item.locationAddress ?
                                    <Card.Text>
                                        <span className="pw_featured_text"><IoMdPin /> { item.locationAddress }</span>
                                    </Card.Text> : []
                                }
                                { item.locationPhone ?
                                    <Card.Text>
                                        <a className="pw_featured_text" href={ `tel://${ item.locationPhone }` }><IoMdCall /> { item.locationPhone }</a>
                                    </Card.Text> : []
                                }
                                { showSocialLinks ?
                                    <div className="d-flex justify-content-center" style={ { fontSize: "2rem" } }>
                                        { item.locationInstagramUrl ? <Card.Link href={ item.locationInstagramUrl }><IoLogoInstagram /></Card.Link> : null }
                                        { item.locationFacebookUrl ? <Card.Link href={ item.locationFacebookUrl }><IoLogoFacebook /></Card.Link> : null }
                                        { item.locationWebsite ? <Card.Link href={ item.locationWebsite }><IoIosGlobe /></Card.Link> : null }
                                        { item.locationTwitterUrl ? <Card.Link href={ item.locationTwitterUrl }><IoLogoTwitter /></Card.Link> : null }
                                        { item.locationWhatsAppUrl ? <Card.Link href={ item.locationWhatsAppUrl }><IoLogoWhatsapp /></Card.Link> : null }
                                    </div> : null }
                            </Card.Body>
                        </Card>
                    );
                }
                case "locations_featured_items": {
                    if ( !this.props.content.locations.featuredItems ) {
                        return null;
                    }
                    const carddecks = [];
                    let carddeckCardData = [];
                    for ( let index = 0; index < this.props.content.locations.featuredItems.length; index += 1 ) {
                        const item = this.props.content.locations.featuredItems[ index ];
                        const cardData = this.renderDynamicContent( [ { type: "location_card", value: item } ] );
                        carddeckCardData.push( cardData );
                        if ( carddeckCardData.length === content.deckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_locations_featured_cards" } );
                            carddeckCardData = [];
                        }
                    }
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_locations_featured_cards" } );
                    }
                    return (
                        <div key="locations-featured-container-id" className="pw_locations_featured_container">
                            { this.renderDynamicContent( carddecks ) }
                        </div>
                    );
                }
                case "cards": {
                    const carddecks = [];
                    let carddeckCardData = [];
                    let deckType = "default";
                    const cardsTitle = content.dynamicContentTitle && content.dynamicContentShowTitle ? content.dynamicContentTitle : null;
                    if ( content.dynamicContentDeckType ) {
                        deckType = content.dynamicContentDeckType;
                    }
                    if ( !content.dynamicContentDeckSize ) {
                        content.dynamicContentDeckSize = 4;
                    }
                    content.dynamicContentValue.forEach( card => {
                        if ( !card.imgSrc && card.imageUrl ) {
                            card.imgSrc = DataUtils.getPublicImageUrl( card.imageUrl );
                        }
                        carddeckCardData.push( card );
                        if ( carddeckCardData.length === content.dynamicContentDeckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_dyncontent_cards_carddeck" } );
                            carddeckCardData = [];
                        }
                    } );
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.dynamicContentDeckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_dyncontent_cards_carddeck" } );
                    }
                    return (
                        <div
                            key={ DataUtils.uuid() }
                            id={ containerHtmlId }
                            className={ `pw_dyncontent_cards_${ deckType }_container` }
                        >
                            { cardsTitle ? <h2 className="pw_section_title">{ cardsTitle }</h2> : null }
                            { this.renderDynamicContent( carddecks ) }
                            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={ this.state.viewMoreContentShow } onHide={ () => this.setState( { viewMoreContentShow: false } ) }>
                                <Modal.Body>
                                    <Card>
                                        { !this.state.viewMoreContentCard.viewMoreEmbed16x9 && this.state.viewMoreContentCard.imgSrc ? <Card.Img variant="top" src={ this.state.viewMoreContentCard.imgSrc } /> : null }
                                        <Card.Body>
                                            { this.state.viewMoreContentCard.viewMoreEmbed16x9 ? <Ratio aspectRatio="16x9"><div dangerouslySetInnerHTML={ { __html: this.state.viewMoreContentCard.viewMoreEmbed16x9 } } /></Ratio> : null }
                                            <div className="pw_card_viewmore_content" dangerouslySetInnerHTML={ { __html: this.state.viewMoreContentCard.viewMoreContentHtml } } />
                                        </Card.Body>
                                    </Card>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={ () => this.setState( { viewMoreContentShow: false } ) }><IoIosClose /> Cerrar</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    );
                }
                case "slider": {
                    return <DynamicSlider content={ content } containerHtmlId={ containerHtmlId } />;
                }
                case "videoheader": {
                    return (
                        <div
                            key={ DataUtils.uuid() }
                            id={ containerHtmlId }
                            className="pw_dyncontent_videoheader"
                            style={ {
                                position: "relative", paddingTop: "56.25%"
                            } }
                        >
                            { content.dynamicContentValue.overlay ? <div className="carousel_overlay pw_dyncontent_videoheader_overlay" /> : null }
                            <ReactPlayer
                                style={ {
                                    position: "absolute",
                                    zIndex: 1,
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    height: "100%"
                                } }
                                url={ Pages.dynamicText( this.context.language, content.dynamicContentValue.videoUrl ) }
                                width="100%"
                                height="100%"
                                playing
                                loop
                                muted={ content.dynamicContentValue.muted }
                            />
                            { content.dynamicContentValue.displayLogo || content.dynamicContentValue.overlaySmallTitle ?
                                <div style={ {
                                    position: "absolute",
                                    zIndex: 2,
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    height: "100%"
                                } }
                                >
                                    { content.dynamicContentValue.displayLogo ?
                                        <div className="carousel_centered_content pw_dyncontent_videoheader_centered_content">
                                            <img src="/static/logo-white-transparent.png" width="100%" alt="Video Header" />
                                        </div> : null }
                                    { content.dynamicContentValue.overlaySmallTitle ?
                                        <div className="pw_dyncontent_videoheader_smalloverlay">
                                            <h1 className="pw_dyncontent_videoheader_smalltitle">
                                                { Pages.dynamicText( this.context.language, content.dynamicContentValue.overlaySmallTitle ) }
                                            </h1>
                                            { content.dynamicContentValue.overlaySmallSubTitle ?
                                                <h2 className="pw_dyncontent_videoheader_smallsubtitle">
                                                    { Pages.dynamicText( this.context.language, content.dynamicContentValue.overlaySmallSubTitle ) }
                                                </h2> : null }
                                        </div> : null }
                                </div> : null }
                        </div>
                    );
                }
                case "leftimage":
                    return (
                        <div key={ DataUtils.uuid() } id={ containerHtmlId } className="pw_section_container pw_dyncontent_leftimage">
                            { content.dynamicContentValue.title ? <h1 className="pw_section_title">{ content.dynamicContentValue.title }</h1> : null }
                            { content.dynamicContentValue.subTitle ? <h2 className="pw_section_subtitle">{ content.dynamicContentValue.subTitle }</h2> : null }
                            <div className="pw_dyncontent_leftimage_content">
                                { content.dynamicContentValue.imageUrl ? <img src={ DataUtils.getPublicImageUrl( content.dynamicContentValue.imageUrl ) } className="pw_section_left_image" alt={ content.dynamicContentValue.title } /> : null }
                                <div className="pw_section_right_content">
                                    <div dangerouslySetInnerHTML={ { __html: content.dynamicContentValue.htmlDescription } } />
                                    { content.dynamicContentValue.viewMoreUrl ? <ButtonGroup>
                                        <Button
                                            variant="pw-primary"
                                            href={ content.dynamicContentValue.viewMoreUrl }
                                        >
                                            { content.dynamicContentValue.viewMoreText || "Más info" }
                                        </Button>
                                    </ButtonGroup> : null }
                                </div>
                            </div>
                        </div>
                    );
                case "videosection":
                    return (
                        <div key={ DataUtils.uuid() } id={ containerHtmlId } className="pw_section_container pw_dyncontent_videosection">
                            { content.dynamicContentValue.title ? <h1 className="pw_section_title">{ Pages.dynamicText( this.context.language, content.dynamicContentValue.title ) }</h1> : null }
                            { content.dynamicContentValue.subTitle ? <h2 className="pw_section_subtitle">{ Pages.dynamicText( this.context.language, content.dynamicContentValue.subTitle ) }</h2> : null }
                            <div className="dyncontent-colorsection">
                                <Fade bottom>
                                    <Ratio aspectRatio="16x9" className="pw_video_responsive">
                                        <ReactPlayer
                                            url={ content.dynamicContentValue.videoUrl }
                                            width="100%"
                                            height="100%"
                                            loop
                                            controls
                                        />
                                    </Ratio>
                                </Fade>
                            </div>
                        </div>
                    );
                case "services_cardecks_overlay_categories": {
                    if ( !this.props.content.services || !this.props.content.services.items ) {
                        return null;
                    }
                    const servicesCategories = [];
                    this.props.content.services.items.forEach( tmpService => {
                        if ( tmpService.serviceCategory && tmpService.serviceCategory.serviceCategoryActive && tmpService.serviceCategory.serviceCategoryWebActive && tmpService.serviceActive && tmpService.serviceWebActive ) {
                            const already = servicesCategories.findIndex( tmpI => tmpI.serviceCategoryId === tmpService.serviceCategory.serviceCategoryId );
                            if ( already === -1 ) {
                                const category = Object.assign( {}, tmpService.serviceCategory, { categoryServices: [ tmpService ] } );
                                servicesCategories.push( category );
                            } else {
                                servicesCategories[ already ].categoryServices.push( tmpService );
                            }
                        }
                    } );
                    DataUtils.sortArray( servicesCategories, { sortingKeys: [ { alias: "serviceCategoryOrderNumber", direction: "asc" } ] } );
                    const elements = [];
                    servicesCategories.forEach( serviceCategory => {
                        elements.push( { type: "h2", value: serviceCategory.serviceCategoryName } );
                        elements.push( { type: "services_cardecks_overlay", categoryItems: serviceCategory.categoryServices, deckSize: content.deckSize } );
                    } );
                    return ( <div key={ DataUtils.uuid() } className="services_cardecks_overlay_categories_container">
                        { this.renderDynamicContent( elements ) }
                    </div> );
                }
                case "services_cardecks_overlay": {
                    // step 001
                    if ( !this.props.content.services || !this.props.content.services.items ) {
                        return null;
                    }
                    let useItems = this.props.content.services.items;
                    if ( content.categoryItems ) {
                        useItems = content.categoryItems;
                    }
                    const carddecks = [];
                    let carddeckCardData = [];
                    for ( let index = 0; index < useItems.length; index += 1 ) {
                        const service = useItems[ index ];
                        let shopItem = null;
                        if ( this.props.shop && this.props.shop.items ) {
                            shopItem = this.props.shop.items.find( tmpShopItem => tmpShopItem.type === "service" && tmpShopItem.serviceId === service.serviceId && tmpShopItem.active && tmpShopItem.saleActive );
                        }
                        const cardData = {
                            type: "overlay",
                            imgSrc: `/content/public/${ service.serviceImageWeb }`,
                            title: service.serviceTitle,
                            subTitle: `${ service.serviceSubtitle ? `${ service.serviceSubtitle } / ${ service.serviceDuration } minutos` : `${ service.serviceDuration } minutos` }`,
                            thirdTitle: service.servicePriceAmount ? DataUtils.formatIntegerPrice( "EUR", service.servicePriceAmount ) : null,
                            shortText: service.serviceShortText,
                            topOfferText: service.serviceCardOfferText,
                            topOfferTextColor: service.serviceCardOfferColor,
                            topOfferTextBgColor: service.serviceCardOfferBgColor,
                            embed16x9: service.serviceVideoEmbed,
                            shopItem
                        };
                        carddeckCardData.push( cardData );
                        if ( carddeckCardData.length === content.deckSize ) {
                            carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_services_carddeck" } );
                            carddeckCardData = [];
                        }
                    }
                    if ( carddeckCardData.length > 0 ) {
                        const cardeckLength = carddeckCardData.length;
                        for ( let index = 0; index < ( content.deckSize - cardeckLength ); index += 1 ) {
                            carddeckCardData.push( { type: "emptycard" } );
                        }
                        carddecks.push( { type: "carddeck", value: carddeckCardData, className: "pw_services_carddeck" } );
                    }
                    return (
                        <div key={ DataUtils.uuid() } className="services_cardecks_overlay_container">
                            { this.renderDynamicContent( carddecks ) }
                        </div>
                    );
                }
                default:
                    break;
            }
            return null;
        } ).filter( tmp => tmp );
    }
    render() {
        if ( !this.skipDynamicContentContainer ) {
            return ( <div className="pw_generic_dynamic_content">
                { this.renderDynamicContent( this.props.items ) }
            </div> );
        }
        return this.renderDynamicContent( this.props.items );
    }
}

GenericDynamicContent.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    shop: state.shop,
    content: state.content
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( GenericDynamicContent ) );
