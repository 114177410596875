/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable complexity */
import React from "react";
import { Navbar, Nav, Container, Badge, Form, FormControl, Button, Stack, Offcanvas, NavDropdown } from "react-bootstrap";
import { FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { IoIosMenu, IoIosCall, IoMdCart, IoIosMail, IoIosCalendar, IoMdPerson, IoMdPower } from "react-icons/io";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NavBarShop from "./NavBarShop";
import Dropdown from "../widgets/Dropdown";
import DomRefs from "../../domrefs";
import Styles from "../styles/Global";
import * as Pages from "../../utils/Pages";
import { loadUIContext } from "../../actions/UIContextActions";
import * as SessionActions from "../../actions/SessionActions";
import WebContext from "../../utils/WebContext";
import * as DataUtils from "../../utils/DataUtils";
import NavBarLocations from "./NavBarLocations";
import Icon from "../widgets/Icon";

class NavBar extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { navExpanded: false };
        this.state.brandImg = "/static/logo-rectangle.png";
        this.toggleNav = this.toggleNav.bind( this );
        this.closeNav = this.closeNav.bind( this );
        this.renderLogin = this.renderLogin.bind( this );
        this.renderLoginOffcanvas = this.renderLoginOffcanvas.bind( this );
        this.renderNavBarClassic = this.renderNavBarClassic.bind( this );
        this.renderNavBarOffcanvas = this.renderNavBarOffcanvas.bind( this );
        this.shopRelativeUrl = Pages.getPage( "shop" ).relativeUrl;
        this.page = null;
    }

    componentDidMount() {
        this.props.loadUIContext();
        this.page = Pages.getPageByPath( window.location.pathname );
    }

    toggleNav( expanded ) {
        this.setState( { navExpanded: expanded } );
    }

    closeNav() {
        this.setState( { navExpanded: false } );
    }

    static renderMenuItem( menuItem ) {
        if ( menuItem.menuItems ) {
            return (
                <Dropdown
                    className="pw_navbar_menu_dropdown"
                    key={ DataUtils.uuid() }
                    text={ menuItem.menuText }
                    items={ menuItem.menuItems.filter( subItem => subItem.active || typeof subItem.active === "undefined" ).map( subItem => {
                        const item = {
                            key: DataUtils.uuid(),
                            href: subItem.relativeUrl,
                            text: subItem.menuText
                        };
                        return item;
                    } ) }
                />
            );
        }
        return ( <Nav.Link key={ `menu-${ menuItem.id }` } style={ Styles.NavBar.MenuItem } className="pw_menu_item" href={ menuItem.relativeUrl }>{menuItem.menuText}</Nav.Link> );
    }
    renderNavBarClassic( config ) {
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const containerStyle = !isLandscape && Styles.NavBar.NavbarContainerPortrait ? Object.assign( {}, Styles.NavBar.NavbarContainerPortrait ) : Object.assign( {}, Styles.NavBar.NavbarContainer );
        if ( config.currentPage.navBarCSSPosition ) {
            containerStyle.position = config.currentPage.navBarCSSPosition;
        }
        return (
            <Container fluid style={ containerStyle } className="pw_navbar_container">
                { Pages.NavBar.topNavBar.enabled ?
                    <div id="pw-top-navbar" style={ Styles.NavBar.TopNavBar }>
                        <div id="pw-top-navbar-links" style={ Styles.NavBar.TopNavBarLinks }>
                            { Pages.NavBar.topNavBar.cartLink && Pages.company.shopCart ? <a href={ Pages.NavBar.topNavBar.cartLink } className="pw_topnavbar_link" style={ Styles.NavBar.TopNavBarLink }><IoMdCart style={ Styles.NavBar.TopNavBarCallIcon } />{ isLandscape ? ` ${ Pages.text( this.context.language, "shop.navbar.top.mycart" ) }` : null } <Badge bg="pw-primary">{ config.cartQuantity }</Badge></a> : null }
                            { Pages.NavBar.topNavBar.citaLink ? <a href={ Pages.NavBar.topNavBar.citaLink } className="pw_topnavbar_link" style={ Styles.NavBar.TopNavBarLink }><IoIosCalendar style={ Styles.NavBar.TopNavBarCallIcon } /><span className="topnavbar_cita_text"> { Pages.NavBar.topNavBar.citaText }</span></a> : null }
                            { Pages.NavBar.topNavBar.phoneLink ? <a href={ Pages.NavBar.topNavBar.phoneLink } className="pw_topnavbar_link" style={ Styles.NavBar.TopNavBarLink }><IoIosCall style={ Styles.NavBar.TopNavBarCallIcon } /><span className="topnavbar_phone_text"> { Pages.NavBar.topNavBar.phoneText }</span></a> : null }
                            { Pages.NavBar.topNavBar.facebookLink ? <a href={ Pages.NavBar.topNavBar.facebookLink } className="pw_topnavbar_link" style={ Styles.NavBar.TopNavBarLink }><FaFacebookF /></a> : null }
                            { Pages.NavBar.topNavBar.instagramLink ? <a href={ Pages.NavBar.topNavBar.instagramLink } className="pw_topnavbar_link" style={ Styles.NavBar.TopNavBarLink }><FaInstagram /></a> : null }
                            { Pages.NavBar.topNavBar.whatsappLink ? <a href={ Pages.NavBar.topNavBar.whatsappLink } className="pw_topnavbar_link" style={ Styles.NavBar.TopNavBarLink }><FaWhatsapp /><span className="topnavbar_whatsapp_text">{ Pages.NavBar.topNavBar.whatsappText ? ` ${ Pages.NavBar.topNavBar.whatsappText }` : null }</span></a> : null }
                            { Pages.NavBar.topNavBar.sendMessageText ?
                                <a
                                    role="link"
                                    tabIndex={ 0 }
                                    onClick={ () => {
                                        window.scrollTo( 0, DomRefs.contactRef.current.offsetTop - 100 );
                                    } }
                                    className="pw_topnavbar_link"
                                    style={ Styles.NavBar.TopNavBarLink }
                                >
                                    <IoIosMail /><span className="topnavbar_message_text">{ config.sendMessageText ? ` ${ config.sendMessageText }` : null }</span>
                                </a> : null }
                        </div>
                    </div>
                    : null
                }
                <Navbar style={ Styles.NavBar.Navbar } className="pw_navbar" expand="lg" onToggle={ this.toggleNav } expanded={ this.state.navExpanded }>
                    <Navbar.Brand href="/" style={ Styles.NavBar.NavbarBrand }>
                        <img
                            alt=""
                            src={ this.state.brandImg }
                            height={ Pages.NavBar.logoHeight ? Pages.NavBar.logoHeight : 40 }
                        />
                        {isLandscape ? <span style={ Styles.NavBar.NavbarBrandTitle }>{ Pages.text( this.context.language, "navbar.title" ) }</span> : []}
                    </Navbar.Brand>
                    { config.menuPages.length > 0 || Pages.NavBar.showShopCategories || Pages.NavBar.showLocationsCategories || Pages.availableLanguages.length > 1 ?
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            style={ Styles.NavBar.ResponsiveMenuToggle }
                        >
                            <IoIosMenu />
                        </Navbar.Toggle>
                        : []
                    }
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-right">
                        { config.menuPages.length > 0 || Pages.NavBar.showShopCategories || Pages.NavBar.showLocationsCategories ?
                            <Nav className="mr-auto" style={ Styles.NavBar.MenuItemsContainer }>
                                { config.menuPages.map( ( page ) => NavBar.renderMenuItem( page ) ) }
                                { config.showShopCategories && this.props.UIContext.loaded && this.props.UIContext.windowWidth < 991 && Pages.NavBar.showShopCategories && this.props.content && this.props.content.shop && this.props.content.shop.categories.length > 0 ? <NavBarShop /> : null }
                                { Pages.NavBar.showLocationsCategories && this.props.UIContext.loaded && this.props.UIContext.windowWidth < 991 && this.props.content && this.props.content.locations && this.props.content.locations.categories.length > 0 ? <NavBarLocations /> : null }
                            </Nav>
                            : []
                        }
                        { config.showShopSearch ?
                            <Form
                                onSubmit={ ( e ) => {
                                    e.preventDefault();
                                    this.props.shopSearch();
                                } }
                                inline
                                className="pw_shop_search_form"
                            >
                                <Stack direction="horizontal">
                                    <FormControl type="text" placeholder={ Pages.text( this.context.language, "navbar.search" ) } onChange={ ( e ) => this.props.changeSessionAttr( "searchText", e.target.value ) } />
                                    <Button variant="pw-primary" size="sm" onClick={ this.props.shopSearch }>{ Pages.NavBar.searchButtonIcon ? <Icon name="search" /> : Pages.text( this.context.language, "navbar.search" ) }</Button>
                                </Stack>
                            </Form> : null }
                        { config.showLocationsSearch ?
                            <Form
                                onSubmit={ ( e ) => {
                                    e.preventDefault();
                                    this.props.locationsSearch();
                                } }
                                inline
                                className="pw_locations_search_form"
                            >
                                <Stack direction="horizontal">
                                    <FormControl type="text" placeholder={ Pages.text( this.context.language, "navbar.search" ) } onChange={ ( e ) => this.props.changeSessionAttr( "searchText", e.target.value ) } />
                                    <Button variant="pw-primary" size="sm" onClick={ this.props.locationsSearch }>{ Pages.text( this.context.language, "navbar.search" ) }</Button>
                                </Stack>
                            </Form> : null }
                        { Pages.company.modSignupEnabled ?
                            this.renderLogin()
                            : [] }
                        { Pages.availableLanguages.length > 1 ?
                            <Dropdown
                                className="pw_navbar_language"
                                text={ Pages.getLanguageName( this.context.language ) }
                                key={ DataUtils.uuid() }
                                items={ Pages.availableLanguages.map( tmplang => {
                                    const item = {
                                        key: DataUtils.uuid(),
                                        text: Pages.getLanguageName( tmplang ),
                                        onClick: () => {
                                            this.context.setLanguage( tmplang );
                                            this.closeNav();
                                        }
                                    };
                                    return item;
                                } ) }
                            /> : []
                        }
                    </Navbar.Collapse>
                </Navbar>
                { config.showShopCategories && this.props.UIContext.loaded && this.props.UIContext.windowWidth >= 991 && Pages.NavBar.showShopCategories && this.props.content && this.props.content.shop && this.props.content.shop.categories.length > 0 ? <NavBarShop /> : null }
                { Pages.NavBar.showLocationsCategories && this.props.UIContext.loaded && this.props.UIContext.windowWidth >= 991 && this.props.content && this.props.content.locations && this.props.content.locations.categories.length > 0 ? <NavBarLocations /> : null }
            </Container>
        );
    }
    renderNavBarOffcanvas( config ) {
        if ( !config ) {
            return null;
        }
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        let showTopButtons = false;
        let showBookNowButton = Pages.NavBar.showBookNowButton;
        let showMyCartButton = Pages.NavBar.topNavBar.cartLink && Pages.company.shopCart;
        if ( showMyCartButton || showBookNowButton || Pages.availableLanguages.length > 1 ) {
            showTopButtons = true;
        }
        if ( !this.page || ![ "home", "shop", "shop-cart" ].includes( this.page.id ) ) {
            showBookNowButton = false;
        }
        let showLanguageInNavBar = true;
        if ( Pages.company.hideLanguageInNavBar ) {
            showLanguageInNavBar = false;
        }

        return (
            <Navbar fixed="top" key="navbar-offcanvas" collapseOnSelect onToggle={ () => this.setState( { expanded: !this.state.expanded } ) } scrolling="true" expand={ false } expanded={ this.state.expanded } bg="pw-transparent" className="pw_navbar_offcanvas">
                <Container fluid>
                    <Navbar.Toggle
                        onMouseEnter={ () => {
                            this.setState( { expanded: true } );
                        } }
                        onMouseLeave={ () => {
                            this.setState( { expanded: true } );
                        } }
                    />
                    <Navbar.Brand href="/">
                        <img
                            id="pw-logo-navbar"
                            alt=""
                            src="/static/logo-navbar.png"
                        />
                    </Navbar.Brand>
                    { showTopButtons ?
                        <div className="pw_nav_offcanvas_top_buttons">
                            { showBookNowButton ? <Button variant="outline-dark" className="pw_nav_offcanvas_top_btn" onClick={ () => this.props.openShopBookingOffcanvas( "services", null ) }><Icon name="calendar" /> Pedir cita</Button> : null }
                            { showMyCartButton ? <Button variant="outline-dark" className="pw_nav_offcanvas_top_btn" href={ Pages.NavBar.topNavBar.cartLink }><Icon name="cart" /> { Pages.text( this.context.language, "shop.navbar.top.mycart" ) } <Badge bg="pw-primary">{ config.cartQuantity }</Badge></Button> : null }
                            { showLanguageInNavBar && Pages.availableLanguages.length > 1 ?
                                <Dropdown
                                    className="pw_navbar_language"
                                    text={ Pages.getLanguageName( this.context.language ) }
                                    key={ DataUtils.uuid() }
                                    items={ Pages.availableLanguages.map( tmplang => {
                                        const item = {
                                            key: DataUtils.uuid(),
                                            text: Pages.getLanguageName( tmplang ),
                                            onClick: () => {
                                                this.context.setLanguage( tmplang );
                                                this.closeNav();
                                            }
                                        };
                                        return item;
                                    } ) }
                                /> : []
                            }
                        </div> : null }
                    <Navbar.Offcanvas
                        placement="start"
                        backdrop={ false }
                        onMouseLeave={ () => this.setState( { expanded: !this.state.expanded } ) }
                        onHide={ () => this.setState( { expanded: !this.state.expanded } ) }
                        className="pw_navbar_offcanvas_content"
                    >
                        <Offcanvas.Header closeButton={ !isLandscape }>
                            <Offcanvas.Title>
                                <img
                                    alt=""
                                    src="/static/logo-navbar.png"
                                />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="pw_navbar_user_container">
                                { Pages.availableLanguages.length > 1 ?
                                    <Dropdown
                                        className="pw_navbar_language"
                                        text={ Pages.getLanguageName( this.context.language ) }
                                        key={ DataUtils.uuid() }
                                        items={ Pages.availableLanguages.map( tmplang => {
                                            const item = {
                                                key: DataUtils.uuid(),
                                                text: Pages.getLanguageName( tmplang ),
                                                onClick: () => {
                                                    this.context.setLanguage( tmplang );
                                                    this.closeNav();
                                                }
                                            };
                                            return item;
                                        } ) }
                                    /> : []
                                }
                                { showBookNowButton ? <Button variant="outline-dark" onClick={ () => this.props.openShopBookingOffcanvas( "services", null ) }><Icon name="calendar" /> Pedir cita</Button> : null }
                                { Pages.NavBar.topNavBar.cartLink && Pages.company.shopCart ? <Button href={ Pages.NavBar.topNavBar.cartLink } variant="outline-dark"><Icon name="cart" /> { Pages.text( this.context.language, "shop.navbar.top.mycart" ) } <Badge bg="pw-primary">{ config.cartQuantity }</Badge></Button> : null }
                                { Pages.company.modSignupEnabled ? this.renderLoginOffcanvas() : null }
                            </div>
                            { config.showShopSearch ?
                                <Form
                                    onSubmit={ ( e ) => {
                                        e.preventDefault();
                                        this.props.shopSearch();
                                    } }
                                    inline
                                    className="pw_shop_search_form"
                                >
                                    <Stack direction="horizontal">
                                        <FormControl type="text" placeholder={ Pages.text( this.context.language, "navbar.search" ) } onChange={ ( e ) => this.props.changeSessionAttr( "searchText", e.target.value ) } />
                                        <Button variant="pw-primary" size="sm" onClick={ this.props.shopSearch }>{ Pages.NavBar.searchButtonIcon ? <Icon name="search" /> : Pages.text( this.context.language, "navbar.search" ) }</Button>
                                    </Stack>
                                </Form> : null }
                            { config.menuPages.length > 0 || Pages.NavBar.showShopCategories || Pages.NavBar.showLocationsCategories ?
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    { config.menuPages.filter( tmp => !tmp.afterCategories ).map( ( menuItem ) => {
                                        if ( menuItem.menuItems ) {
                                            return (
                                                <NavDropdown key={ DataUtils.uuid() } title={ menuItem.menuText }>
                                                    { menuItem.menuItems.filter( subItem => subItem.active || typeof subItem.active === "undefined" ).map( subItem => (
                                                        <Nav.Link key={ DataUtils.uuid() } href={ subItem.relativeUrl }>{ subItem.menuText }</Nav.Link>
                                                    ) ) }
                                                </NavDropdown>
                                            );
                                        }
                                        return ( <Nav.Link key={ `menu-${ menuItem.id }` } href={ menuItem.relativeUrl }>{ menuItem.menuText }</Nav.Link> );
                                    } ) }
                                    { config.showShopCategories && Pages.NavBar.showShopCategories && this.props.content && this.props.content.shop && this.props.content.shop.categories.length > 0 ? <NavBarShop type="offcanvas" /> : null }
                                    { Pages.NavBar.showLocationsCategories && this.props.content && this.props.content.locations && this.props.content.locations.categories.length > 0 ? <NavBarLocations /> : null }
                                    { config.menuPages.filter( tmp => tmp.afterCategories ).map( ( menuItem ) => {
                                        if ( menuItem.menuItems ) {
                                            return (
                                                <NavDropdown key={ DataUtils.uuid() } title={ menuItem.menuText }>
                                                    { menuItem.menuItems.filter( subItem => subItem.active || typeof subItem.active === "undefined" ).map( subItem => (
                                                        <Nav.Link key={ DataUtils.uuid() } href={ subItem.relativeUrl }>{ subItem.menuText }</Nav.Link>
                                                    ) ) }
                                                </NavDropdown>
                                            );
                                        }
                                        return ( <Nav.Link key={ `menu-${ menuItem.id }` } href={ menuItem.relativeUrl }>{ menuItem.menuText }</Nav.Link> );
                                    } ) }
                                </Nav>
                                : []
                            }
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        );
    }
    renderLogin() {
        const result = [];
        if ( DataUtils.getToken() ) {
            result.push( <Nav.Link key="navbar-dashboard" href={ `${ Pages.getPage( "dashboard" ).relativeUrl }` }><IoMdPerson />{ Pages.text( this.context.language, "signup.navbar.dashboard" ) }</Nav.Link> );
            result.push( (
                <Nav.Link
                    key={ DataUtils.uuid() }
                    onClick={ () => {
                        DataUtils.removeToken();
                        this.props.history.push( `${ Pages.getPage( "login" ).relativeUrl }?view=login` );
                    } }
                >   <IoMdPower />{ Pages.text( this.context.language, "signup.navbar.logout" ) }
                </Nav.Link>
            ) );
        } else {
            result.push( <Nav.Link key="navbar-signup" href={ `${ Pages.getPage( "login" ).relativeUrl }` }>{ Pages.text( this.context.language, "signup.navbar.signup" ) }</Nav.Link> );
            result.push( <Nav.Link key="navbar-login" href={ `${ Pages.getPage( "login" ).relativeUrl }?view=login` }><IoMdPerson />{ Pages.text( this.context.language, "signup.navbar.login" ) }</Nav.Link> );
        }
        return result;
    }
    renderLoginOffcanvas() {
        const result = [];
        if ( DataUtils.getToken() ) {
            result.push( <Button key={ DataUtils.uuid() } variant="outline-dark" href={ `${ Pages.getPage( "dashboard" ).relativeUrl }` }><Icon name="user" /> { Pages.text( this.context.language, "signup.navbar.dashboard" ) }</Button> );
            result.push( (
                <Button
                    variant="outline-dark"
                    key={ DataUtils.uuid() }
                    onClick={ () => {
                        DataUtils.removeToken();
                        this.props.history.push( `${ Pages.getPage( "login" ).relativeUrl }?view=login` );
                    } }
                > <Icon name="system-shut" /> { Pages.text( this.context.language, "signup.navbar.logout" ) }
                </Button>
            ) );
        } else {
            result.push( <Button variant="outline-dark" key={ DataUtils.uuid() } href={ `${ Pages.getPage( "login" ).relativeUrl }` }>{ Pages.text( this.context.language, "signup.navbar.signup" ) }</Button> );
            result.push( <Button variant="outline-dark" key="navbar-login" href={ `${ Pages.getPage( "login" ).relativeUrl }?view=login` }><Icon name="user" /> { Pages.text( this.context.language, "signup.navbar.login" ) }</Button> );
        }
        return result;
    }
    render() {
        const config = {
            cartQuantity: 0,
            currentPage: Pages.getPageByPath( this.props.location.pathname ),
            menuPages: Pages.NavBar.customMenuItems ? Pages.NavBar.customMenuItems.filter( item => item.active || typeof item.active === "undefined" ) : Pages.all.filter( ( page ) => !page.menuHidden ).map( ( argpage ) => Pages.getPage( argpage.id ) ),
            showShopSearch: false,
            showLocationsSearch: Pages.NavBar.showLocationsSearch,
            showShopCategories: true,
            sendMessageText: Pages.NavBar.topNavBar.sendMessageText
        };
        this.props.pwSession.shop.cartItems.forEach( cartItem => {
            config.cartQuantity += cartItem.quantity;
        } );

        config.showShopSearch = Pages.NavBar.showShopSearch;
        if ( Pages.NavBar.hideShopSearchInPages && config.currentPage && Pages.NavBar.hideShopSearchInPages.includes( config.currentPage.id ) ) {
            config.showShopSearch = false;
        }
        if ( Pages.NavBar.hideLocationsSearchInPages && config.currentPage && Pages.NavBar.hideLocationsSearchInPages.includes( config.currentPage.id ) ) {
            config.showLocationsSearch = false;
        }
        if ( Pages.NavBar.hideShopCategoriesInPages && config.currentPage && Pages.NavBar.hideShopCategoriesInPages.includes( config.currentPage.id ) ) {
            config.showShopCategories = false;
        }
        if ( this.context.language === "en" && Pages.NavBar.topNavBar.sendMessageTextEn ) {
            config.sendMessageText = Pages.NavBar.topNavBar.sendMessageTextEn;
        }
        if ( Pages.NavBar.type === "offcanvas" ) {
            return this.renderNavBarOffcanvas( config );
        }
        return this.renderNavBarClassic( config );
    }
}

NavBar.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    content: state.content,
    shop: state.shop
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );
export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NavBar ) );
