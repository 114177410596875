/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-mount-set-state */
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";
import ReactGA from "react-ga";
import Cookies from "universal-cookie";
import NavBar from "./layout/NavBar";
import Footer from "./layout/Footer";
import ManageLayout from "./manage/Layout";
import CookiesAcceptance from "./layout/CookiesAcceptance";
import Splash from "./layout/Splash";
import AdultsOnlyAcceptance from "./layout/AdultsOnlyAcceptance";
import PromoModal from "./layout/PromoModal";
import * as Pages from "../utils/Pages";
import * as DataUtils from "../utils/DataUtils";
import * as UIContext from "../utils/UIContext";
import { loadUIContext } from "../actions/UIContextActions";
import * as SessionActions from "../actions/UIContextActions";
import routes from "../routes";
import WebContext from "../utils/WebContext";
import store from "../store";

ReactGA.initialize( Pages.company.googleAnalyticsTrackingID );

const mapStateToPropsForToasts = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToPropsForToasts = Object.assign( { loadUIContext }, SessionActions );

const SessionToastsComponent = ( props ) => {
    if ( !props.pwSession.toastMessages || props.pwSession.toastMessages.length === 0 ) {
        return null;
    }
    return (
        <div style={ {
            position: "fixed", top: 0, right: 0, zIndex: 9000
        } }
        >
            { props.pwSession.toastMessages.map( ( toastMessage ) => (
                <Toast
                    show
                    className="pw_session_toast"
                    style={ { backgroundColor: toastMessage.backgroundColor || "#fff3cd" } }
                    onClose={ () => props.userSessionHideToastMessage( toastMessage ) }
                >
                    <Toast.Header style={ { backgroundColor: toastMessage.backgroundColor || "#fff3cd" } }>
                        <strong className="mr-auto">{ toastMessage.title || "Atención" }</strong>
                    </Toast.Header>
                    <Toast.Body>{ toastMessage.isLangKey ? Pages.text( "es", toastMessage.message ) : <div dangerouslySetInnerHTML={ { __html: toastMessage.message } } /> }</Toast.Body>
                </Toast>
            ) ) }
        </div>
    );
};
const SessionToasts = connect( mapStateToPropsForToasts, mapDispatchToPropsForToasts )( SessionToastsComponent );

class Layout extends React.Component {
    constructor( props ) {
        super( props );
        this.cookies = new Cookies();
        this.state = {
            title: "",
            manage: false,
            language: props.language
        };
        if ( typeof window !== "undefined" ) {
            ReactGA.pageview( window.location.pathname + window.location.search );
        }
        if ( Pages.company.managerURLRelative && props.location.pathname.startsWith( Pages.company.managerURLRelative ) ) {
            this.state.manage = true;
        }
        this.setLanguage = ( lang ) => {
            this.cookies.set( "language", lang, { path: "/", expires: DataUtils.nowAddDays( 365 ) } );
            store.dispatch( { type: "CHANGE_SESSION_ATTR", key: "language", value: lang } );
            this.setState( {
                language: lang
            } );
        };
    }
    // eslint-disable-next-line class-methods-use-this
    componentDidMount() {
        let full16x9 = UIContext.getFullWidth16x9();
        if ( full16x9 ) {
            if ( window.scrollY > full16x9.height ) {
                document.documentElement.dataset.scrollIsOn16x9 = "0";
            } else {
                document.documentElement.dataset.scrollIsOn16x9 = "1";
            }
        }
        document.addEventListener( "scroll", UIContext.customDebounce( () => {
            if ( full16x9 ) {
                if ( window.scrollY > full16x9.height ) {
                    document.documentElement.dataset.scrollIsOn16x9 = "0";
                } else {
                    document.documentElement.dataset.scrollIsOn16x9 = "1";
                }
            }
        }, 10 ) );
    }
    static renderFacebookProvider( children ) {
        return children;
    }
    render() {
        if ( this.state.manage ) {
            return (
                <ManageLayout />
            );
        }
        return Layout.renderFacebookProvider( <WebContext.Provider value={ { language: this.state.language, setLanguage: this.setLanguage } }>
            { Pages.company.splash ? <Splash /> : null }
            <NavBar title={ this.state.title } />
            <Switch>
                { routes.map( route => <Route key={ route.path } { ...route } /> ) }
            </Switch>
            <Footer />
            <CookiesAcceptance />
            <AdultsOnlyAcceptance />
            <PromoModal />
            <SessionToasts />
        </WebContext.Provider> );
    }
}

export default withRouter( Layout );
