export const getWindowWidth = () => {
    if ( typeof window !== "undefined" ) {
        return window.innerWidth;
    }
    return 0;
};
export const getFullWidth16x9 = () => {
    if ( typeof window !== "undefined" ) {
        return {
            width: window.innerWidth,
            height: window.innerWidth / ( 16 / 9 )
        };
    }
    return null;
};
export const getWindowHeight = () => {
    if ( typeof window !== "undefined" ) {
        return window.innerHeight;
    }
    return 0;
};

export const getScrollY = () => {
    if ( typeof window !== "undefined" ) {
        return window.pageYOffset;
    }
    return 0;
};

export const customDebounce = ( func, wait, immediate ) => {
    let timeout;
    return () => {
        const context = this;
        const args = arguments;
        const later = () => {
            timeout = null;
            if ( !immediate ) {
                func.apply( context, args );
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout( timeout );
        timeout = setTimeout( later, wait );
        if ( callNow ) {
            func.apply( context, args );
        }
    };
};
