/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { uuid } from "../../utils/DataUtils";

class Dropdown extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {};
        this.hideMenu = this.hideMenu.bind( this );
        this.clickMenuItem = this.clickMenuItem.bind( this );
        this.clickDropdownMenuItem = this.clickDropdownMenuItem.bind( this );
        this.domId = `pw_dropdown${ uuid() }`;
    }
    hideMenu() {
        const selected = document.querySelector( `.pw_dropdown_content[data-dropdown-id="${ this.domId }"]` );
        selected.classList.remove( "pw_dropdown_expanded" );
    }
    // eslint-disable-next-line class-methods-use-this
    clickMenuItem() {
        const active = document.querySelector( ".pw_dropdown_expanded" );
        const newactive = document.querySelector( `.pw_dropdown_content[data-dropdown-id="${ this.domId }"]` );
        if ( active && active.dataset.dropdownId !== this.domId ) {
            active.classList.remove( "pw_dropdown_expanded" );
        }
        if ( newactive && newactive.classList.contains( "pw_dropdown_expanded" ) ) {
            newactive.classList.remove( "pw_dropdown_expanded" );
        } else if ( newactive ) {
            newactive.classList.add( "pw_dropdown_expanded" );
        }
    }
    clickDropdownMenuItem( onClick ) {
        if ( onClick ) {
            onClick();
        }
        this.hideMenu();
    }
    render() {
        return (
            <div className={ `pw_dropdown${ this.props.className ? ` ${ this.props.className }` : "" }` } >
                <button
                    className="pw_dropdown_button"
                    data-dropdown-id={ this.domId }
                    onClick={ () => this.clickMenuItem() }
                >
                    { this.props.text}
                    { this.props.items.length > 0 ? <IoIosArrowDown /> : null }
                </button>
                <div className={ `pw_dropdown_content${ this.props.expanded ? " pw_dropdown_expanded" : "" }${ this.props.absolutePosition ? " pw_dropdown_absolute" : "" }` } onMouseLeave={ () => this.hideMenu() } data-dropdown-id={ this.domId }>
                    { this.props.items.map( item => <a className="pw_dropdown_item" href={ item.href } onClick={ () => this.clickDropdownMenuItem( item.onClick ) } key={ item.key }>{ item.text }</a> ) }
                </div>
            </div>
        );
    }
}

export default Dropdown;
