/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
import * as LocalStorage from "../utils/LocalStorage";
import * as DataUtils from "../utils/DataUtils";
import * as ManagerUtils from "../utils/ManagerUtils";

const update = ( ...objects ) => Object.assign( {}, ...objects );
const validateCartItem = ( item ) => {
    if ( item.quantity > item.maxQuantityPerPurchase ) {
        item.quantity = item.maxQuantityPerPurchase;
    }
    return item;
};

export default ( state = {
    userEmail: "",
    userName: "",
    userMobile: "",
    userAddress: "",
    reCaptchaTokenNewClient: "",
    userPlanPremiumPeriod: "",
    userIsReseller: false,
    loggedIn: false,
    loginLoading: false,
    loginError: "",
    loginErrorType: "",
    authProvider: "palmawebs",
    userEmailVerified: false,
    fbSession: { loading: false, error: "", accessToken: "" },
    transactionErrorCode: null,
    language: "es",
    accessToken: "",
    isRehydrating: false,
    isSendingWebUserMessage: false,
    webUserMessageSent: false,
    webUserMessageError: "",
    forgotLoading: false,
    forgotSent: false,
    resetLoading: false,
    resetDone: false,
    acceptPrivacyPolicy: false,
    webUserAcceptNewsletter: false,
    courses: [],
    searchText: "",
    carts: [],
    message: {
        type: "question",
        text: "",
        productId: ""
    },
    signup: {
        errorMessage: "",
        view: "signup",
        isLoading: false,
        isLoadingLogin: false
    },
    checkout: {
        errorMessage: "",
        isLoading: false,
        stripePlanId: null,
        productId: null,
        planId: null
    },
    shopAttributes: {
        userName: "",
        userEmail: "",
        userMobile: "",
        userAddress: "",
        userComments: "",
        reCaptchaTokenNewClient: "",
        paymentMethod: "stripe",
        errorMessage: "",
        success: false,
        purchaseCode: "",
        purchaseEmail: "",
        purchaseCart: null,
        purchaseIsLoading: false,
        purchaseErrorMessage: "",
        redirectProvider: "",
        purchaseIsProcessing: false,
        isLoadingBookedSlots: false,
        bookedSlots: [],
        teamMembers: [],
        services: [],
        bonos: [],
        isEditingShopBooking: false,
        isEditingShopBookingStep: "booking",
        isEditingShopBookingServiceSearchText: "",
        editingShopBooking: null,
        isLoadingPromoCode: false,
        promoCodeError: "",
        promoCodeText: "",
        promoCode: null,
        isAddingShopItemId: null
    },
    shop: {
        version: 4,
        inStorePickUp: false,
        cartItems: [],
        cartBookings: [],
        cartStatus: "empty"
    },
    locations: {
        items: []
    },
    sales: {
        items: []
    },
    toastMessages: [],
    manager: {
        loading: false,
        loginLoading: true,
        loginError: "",
        accessToken: "",
        userEmail: "",
        userName: "",
        userPass: "",
        reCaptchaTokenNewClient: "",
        acceptPrivacyAndTerms: false,
        isRehydrating: false,
        capabilities: {
            managerRoles: [],
            courses: {},
            shop: {}
        },
        invoices: {
            vatNotIncludedAsDefault: false,
            globalNumbering: false,
            configFrom: []
        },
        courses: {
            configSignupTypes: [],
            configStatus: [],
            capabilities: {}
        },
        shop: {},
        locations: {
            categories: []
        },
        toastMessages: []
    }
}, action ) => {
    switch ( action.type ) {
        case "LOGIN_ATTEMPT": {
            return update( state, {
                loginLoading: true,
                loginError: "",
                loginErrorType: "",
                loggedIn: false
            } );
        }
        case "LOGIN_ERROR": {
            return update( state, {
                loginLoading: false,
                loginError: action.errorMessage,
                loginErrorType: action.errorType,
                loggedIn: false
            } );
        }
        case "INITIALIZE_FACEBOOK_SESSION": {
            return update( state, {
                fbSession: update( state.fbSession, {
                    email: action.email,
                    name: action.name,
                    id: action.id,
                    accessToken: action.accessToken
                } ),
                loggedIn: true,
                userEmail: action.email,
                userName: action.name,
                authProvider: "facebook"
            } );
        }
        case "LOGIN_SUCCESS": {
            return update( state, { loginLoading: true, loginError: "", loginErrorType: "" } );
        }
        case "CHANGE_SESSION_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "CHANGE_SESSION_MANAGER_ATTR": {
            return update( state, { manager: update( state.manager, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_LOGIN_SUCCESS": {
            return update( state, { manager: update( state.manager, { accessToken: action.accessToken, loginError: "", loginLoading: true } ) } );
        }
        case "MANAGER_LOGIN_ERROR": {
            return update( state, { manager: update( state.manager, { accessToken: "", loginError: action.errorMessage ? action.errorMessage : "Email y clave incorrectos", loginLoading: false } ) } );
        }
        case "MANAGER_LOGIN_ATTEMPT": {
            return update( state, { manager: update( state.manager, { accessToken: "", loginError: "", loginLoading: true } ) } );
        }
        case "MANAGER_LOGOUT": {
            ManagerUtils.removeToken();
            return update( state, { manager: update( state.manager, { accessToken: "" } ) } );
        }
        case "SHOP_CART_ADD_ITEM": {
            const existingItemIndex = state.shop.cartItems.findIndex( ( cartItem ) => cartItem.id === action.item.id );
            let mutations = {};
            if ( !action.item.uuid ) {
                mutations.uuid = DataUtils.uuid();
            }
            const itemToAdd = update( {}, action.item, mutations );
            const quantityToAdd = itemToAdd.quantity ? itemToAdd.quantity : 1;
            itemToAdd.quantity = quantityToAdd;
            if ( itemToAdd.courseId && itemToAdd.courseClasses ) {
                itemToAdd.courseClassIdSelected = itemToAdd.courseClasses[ 0 ].courseClassId;
            }
            let newCartItems = state.shop.cartItems.map( item => item );
            if ( existingItemIndex > -1 ) {
                newCartItems[ existingItemIndex ].quantity += quantityToAdd;
                newCartItems[ existingItemIndex ] = validateCartItem( newCartItems[ existingItemIndex ] );
            } else {
                newCartItems = newCartItems.concat( itemToAdd );
            }
            const result = update( state, { shop: update( state.shop, { cartItems: newCartItems } ) } );
            if ( state.shopAttributes.success ) {
                result.shopAttributes.success = false;
            }
            LocalStorage.setObject( "pwShopData", result.shop );
            return result;
        }
        case "SHOP_CART_UPDATE_ITEM_ATTR": {
            const existingItemIndex = state.shop.cartItems.findIndex( ( cartItem ) => cartItem.uuid === action.item.uuid );
            if ( existingItemIndex > -1 ) {
                const newCartItems = state.shop.cartItems.map( item => item );
                newCartItems[ existingItemIndex ][ action.key ] = action.value;
                newCartItems[ existingItemIndex ] = validateCartItem( newCartItems[ existingItemIndex ] );
                const result = update( state, { shop: update( state.shop, { cartItems: newCartItems } ) } );
                LocalStorage.setObject( "pwShopData", result.shop );
                return result;
            }
            return state;
        }
        case "CHANGE_SHOP_ATTR": {
            let mutations = {};
            mutations[ action.key ] = action.value;
            const result = update( state, { shop: update( state.shop, mutations ) } );
            LocalStorage.setObject( "pwShopData", result.shop );
            return result;
        }
        case "SHOP_CART_UPDATE_ITEM_PRICEAMOUNT": {
            const existingItemIndex = state.shop.cartItems.findIndex( ( cartItem ) => cartItem.id === action.item.id );
            if ( existingItemIndex > -1 ) {
                const newCartItems = state.shop.cartItems.map( item => item );
                newCartItems[ existingItemIndex ].priceAmount += action.amount;
                newCartItems[ existingItemIndex ] = validateCartItem( newCartItems[ existingItemIndex ] );
                const result = update( state, { shop: update( state.shop, { cartItems: newCartItems } ) } );
                LocalStorage.setObject( "pwShopData", result.shop );
                return result;
            }
            return state;
        }
        case "SHOP_CART_ADD_ITEM_QUANTITY": {
            const existingItemIndex = state.shop.cartItems.findIndex( ( cartItem ) => cartItem.id === action.item.id );
            if ( existingItemIndex > -1 ) {
                const newCartItems = state.shop.cartItems.map( item => item );
                newCartItems[ existingItemIndex ].quantity += 1;
                newCartItems[ existingItemIndex ] = validateCartItem( newCartItems[ existingItemIndex ] );
                const result = update( state, { shop: update( state.shop, { cartItems: newCartItems } ) } );
                LocalStorage.setObject( "pwShopData", result.shop );
                return result;
            }
            return state;
        }
        case "SHOP_CART_REMOVE_ITEM_QUANTITY": {
            const existingItemIndex = state.shop.cartItems.findIndex( ( cartItem ) => cartItem.id === action.item.id );
            if ( existingItemIndex > -1 && state.shop.cartItems[ existingItemIndex ].quantity > 1 ) {
                const newCartItems = state.shop.cartItems.map( item => item );
                newCartItems[ existingItemIndex ].quantity -= 1;
                newCartItems[ existingItemIndex ] = validateCartItem( newCartItems[ existingItemIndex ] );
                const result = update( state, { shop: update( state.shop, { cartItems: newCartItems } ) } );
                LocalStorage.setObject( "pwShopData", result.shop );
                return result;
            }
            return state;
        }
        case "SHOP_CART_ITEM_CHANGE_COURSE_CLASS_ID": {
            const existingItemIndex = state.shop.cartItems.findIndex( ( cartItem ) => cartItem.id === action.item.id );
            if ( existingItemIndex > -1 ) {
                const newCartItems = state.shop.cartItems.map( item => item );
                newCartItems[ existingItemIndex ].courseClassIdSelected = action.courseClassIdSelected;
                const result = update( state, { shop: update( state.shop, { cartItems: newCartItems } ) } );
                LocalStorage.setObject( "pwShopData", result.shop );
                return result;
            }
            return state;
        }
        case "SHOP_CART_REMOVE_ITEM": {
            const existingItemIndex = state.shop.cartItems.findIndex( ( cartItem ) => cartItem.id === action.item.id );
            if ( existingItemIndex > -1 ) {
                const newCartItems = state.shop.cartItems.filter( ( item ) => item.id !== action.item.id );
                const result = update( state, { shop: update( state.shop, { cartItems: newCartItems } ) } );
                LocalStorage.setObject( "pwShopData", result.shop );
                return result;
            }
            return state;
        }
        case "SHOP_CART_UPDATE_BOOKINGS": {
            const { cartBookings } = action;
            const result = update( state, { shop: update( state.shop, { cartBookings } ) } );
            LocalStorage.setObject( "pwShopData", result.shop );
            return result;
        }
        case "SHOP_CART_REMOVE_CART": {
            const result = update( state, { shop: update( state.shop, { cartItems: [], cartId: null, cartBookings: [] } ) } );
            LocalStorage.setObject( "pwShopData", result.shop );
            return result;
        }
        case "CHANGE_SHOP_CART_ATTR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { [ action.key ]: action.value } ) } );
        }
        case "CHANGE_SHOP_CART_ATTRS": {
            return update( state, { shopAttributes: update( state.shopAttributes, action.changes ) } );
        }
        case "SHOP_CART_PROCESS_PURCHASE_ERROR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { errorMessage: action.errorMessage, purchaseIsProcessing: false } ) } );
        }
        case "SHOP_CART_PROCESS_PURCHASE_DONE": {
            const result = update( state, { shop: update( state.shop, { cartId: action.cartId } ), shopAttributes: update( state.shopAttributes, { purchaseIsProcessing: false } ) } );
            LocalStorage.setObject( "pwShopData", result.shop );
            return result;
        }
        case "SHOP_CART_PROCESS_PURCHASE_SUCCESS": {
            return update( state, { shopAttributes: update( state.shopAttributes, { success: true, purchaseIsProcessing: false, successPaymentMethod: action.paymentMethod } ) } );
        }
        case "SHOP_CART_PURCHASE_DETAILS_ATTEMPT": {
            return update( state, { shopAttributes: update( state.shopAttributes, { purchaseIsLoading: true, purchaseErrorMessage: "" } ) } );
        }
        case "SHOP_CART_PURCHASE_DETAILS_ERROR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { purchaseIsLoading: false, purchaseErrorMessage: action.errorMessage } ) } );
        }
        case "SHOP_CART_PURCHASE_DETAILS_SUCCESS": {
            return update( state, { shopAttributes: update( state.shopAttributes, { purchaseIsLoading: false, purchaseErrorMessage: "", purchaseCart: action.cart } ) } );
        }
        case "CHANGE_SIGNUP_ATTR": {
            return update( state, { signup: update( state.signup, { [ action.key ]: action.value } ) } );
        }
        case "SIGNUP_ATTEMPT": {
            return update( state, { signup: update( state.signup, { isLoading: true } ) } );
        }
        case "SIGNUP_SUCCESS": {
            return update( state, { signup: update( state.signup, { isLoading: false, view: "signupsuccess" } ) } );
        }
        case "SIGNUP_ERROR": {
            return update( state, { signup: update( state.signup, { isLoading: false, errorMessage: action.errorMessage } ) } );
        }
        case "MOD_SIGNUP_LOGIN_ATTEMPT": {
            return update( state, { signup: update( state.signup, { isLoadingLogin: true } ) } );
        }
        case "MOD_SIGNUP_LOGIN_SUCCESS": {
            return update( state, { signup: update( state.signup, { isLoadingLogin: false } ) } );
        }
        case "MOD_SIGNUP_LOGIN_ERROR": {
            return update( state, { signup: update( state.signup, { isLoadingLogin: false, errorMessage: action.errorMessage } ) } );
        }
        case "MOD_SIGNUP_LOGOUT": {
            DataUtils.removeToken();
            return update( state, { loggedIn: false, accessToken: "", signup: update( state.signup, { isLoadingLogin: false } ) } );
        }
        case "SESSION_REHYDRATE_FROM_SERVER_ERROR": {
            return update( state, { isRehydrating: false } );
        }
        case "SESSION_REHYDRATE_FROM_SERVER_ATTEMPT": {
            return update( state, { isRehydrating: true } );
        }
        case "SESSION_REHYDRATE_FROM_SERVER_SUCCESS": {
            return update( state, {
                userEmail: action.data.userEmail,
                userName: action.data.userName,
                userMobile: action.data.userMobile,
                userAddress: action.data.userAddress,
                userEmailVerified: action.data.userEmailVerified,
                userIsReseller: action.data.userIsReseller || false,
                acceptPrivacyPolicy: action.data.acceptPrivacyPolicy,
                webUserAcceptNewsletter: action.data.webUserAcceptNewsletter,
                loggedIn: true,
                isRehydrating: false,
                carts: action.data.carts,
                courses: action.data.courses,
                checkout: update( state.checkout, { products: action.data.checkout.products } ),
                locations: update( state.locations, { items: action.data.locations.items } ),
                sales: update( state.sales, { items: action.data.sales.items } ),
                shopAttributes: update( state.shopAttributes, {
                    userName: action.data.userName,
                    userEmail: action.data.userEmail,
                    userMobile: action.data.userMobile,
                    userAddress: action.data.userAddress,
                    userPostalCode: action.data.userPostalCode,
                    userCity: action.data.userCity,
                    userProvince: action.data.userProvince
                } )
            } );
        }
        case "SESSION_MANAGER_REHYDRATE_FROM_SERVER_ATTEMPT": {
            return update( state, { manager: update( state.manager, { isRehydrating: true } ) } );
        }
        case "SESSION_MANAGER_REHYDRATE_FROM_SERVER_ERROR": {
            return update( state, { manager: update( state.manager, { isRehydrating: false } ) } );
        }
        case "SESSION_MANAGER_REHYDRATE_FROM_SERVER_SUCCESS": {
            return update( state, {
                manager: update( state.manager, {
                    isRehydrating: false,
                    userName: action.data.userName,
                    userEmail: action.data.userEmail,
                    userTeamMemberId: action.data.userTeamMemberId,
                    userPermissionRole: action.data.userPermissionRole,
                    capabilities: action.data.capabilities,
                    invoices: action.data.invoices,
                    courses: action.data.courses,
                    shop: action.data.shop,
                    locations: action.data.locations,
                    sales: action.data.sales
                } )
            } );
        }
        case "SEND_WEB_USER_MESSAGE_ATTEMPT": {
            return update( state, { isSendingWebUserMessage: true, webUserMessageSent: false, webUserMessageError: "" } );
        }
        case "SEND_WEB_USER_MESSAGE_ERROR": {
            return update( state, { isSendingWebUserMessage: false, webUserMessageSent: false, webUserMessageError: action.errorMessage } );
        }
        case "SEND_WEB_USER_MESSAGE_SUCCESS": {
            return update( state, { isSendingWebUserMessage: false, webUserMessageSent: true, webUserMessageError: action.errorMessage } );
        }
        case "MOD_SIGNUP_FORGOT_ATTEMPT": {
            return update( state, { forgotLoading: true, forgotSent: false, loginErrorType: "" } );
        }
        case "MOD_SIGNUP_FORGOT_ERROR": {
            return update( state, { forgotLoading: false, forgotSent: false, loginErrorType: "forgotPassword" } );
        }
        case "MOD_SIGNUP_FORGOT_SUCCESS": {
            return update( state, { forgotLoading: false, forgotSent: true, loginErrorType: "" } );
        }
        case "MOD_SIGNUP_RESET_ATTEMPT": {
            return update( state, { resetLoading: true, resetDone: false } );
        }
        case "MOD_SIGNUP_RESET_ERROR": {
            return update( state, { resetLoading: false, resetDone: false } );
        }
        case "MOD_SIGNUP_RESET_SUCCESS": {
            return update( state, { resetLoading: false, resetDone: true } );
        }
        case "SHOP_CART_PROCESS_PURCHASE_ATTEMPT": {
            return update( state, { shopAttributes: update( state.shopAttributes, { purchaseIsProcessing: true } ) } );
        }
        case "SHOP_CART_GET_BOOKED_SLOTS_ATTEMPT": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingBookedSlots: true } ) } );
        }
        case "SHOP_CART_GET_BOOKED_SLOTS_ERROR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingBookedSlots: false } ) } );
        }
        case "SHOP_CART_GET_BOOKED_SLOTS_SUCCESS": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingBookedSlots: false, bookedSlots: action.items } ) } );
        }
        case "SHOP_CART_TEAM_MEMBERS_ATTEMPT": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingTeamMembers: true } ) } );
        }
        case "SHOP_CART_TEAM_MEMBERS_ERROR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingTeamMembers: false } ) } );
        }
        case "SHOP_CART_TEAM_MEMBERS_SUCCESS": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingTeamMembers: false, teamMembers: action.items } ) } );
        }
        case "SHOP_CART_SERVICES_ATTEMPT": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingServices: true } ) } );
        }
        case "SHOP_CART_SERVICES_ERROR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingServices: false } ) } );
        }
        case "SHOP_CART_SERVICES_SUCCESS": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingServices: false, services: action.items } ) } );
        }
        case "SHOP_CART_BONOS_ATTEMPT": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingBonos: true } ) } );
        }
        case "SHOP_CART_BONOS_ERROR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingBonos: false } ) } );
        }
        case "SHOP_CART_BONOS_SUCCESS": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingBonos: false, bonos: action.items } ) } );
        }
        case "SHOP_CART_PROMOCODE_VALIDATION_ATTEMPT": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingPromoCode: true, promoCodeError: "" } ) } );
        }
        case "SHOP_CART_PROMOCODE_VALIDATION_ERROR": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingPromoCode: false, promoCodeError: action.errorMessage } ) } );
        }
        case "SHOP_CART_PROMOCODE_VALIDATION_SUCCESS": {
            return update( state, { shopAttributes: update( state.shopAttributes, { isLoadingPromoCode: false, promoCodeError: "", promoCode: action.item } ) } );
        }
        case "MANAGER_SESSION_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.manager.toastMessages ? state.manager.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { manager: update( state.manager, { toastMessages: newToastMessages } ) } );
        }
        case "MANAGER_SESSION_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.manager.toastMessages ? state.manager.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { manager: update( state.manager, { toastMessages: newToastMessages } ) } );
        }
        case "USER_SESSION_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessages: newToastMessages } );
        }
        case "USER_SESSION_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessages: newToastMessages } );
        }
        default: return state;
    }
};
