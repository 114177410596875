export default ( state = {
    loaded: false,
    windowWidth: 0,
    windowHeight: 0,
    acceptCookies: false,
    confirmationDialogOpen: false,
    confirmationDialogTitle: "Confirmar",
    confirmationDialogMessage: "Confirma para continuar ..."
}, action ) => {
    switch ( action.type ) {
        case "UICONTEXT_LOADED": {
            return Object.assign( {}, state, {
                loaded: true,
                windowWidth: action.windowWidth,
                windowHeight: action.windowHeight
            } );
        }
        case "UICONTEXT_RESIZE": {
            return Object.assign( {}, state, {
                windowWidth: action.windowWidth,
                windowHeight: action.windowHeight
            } );
        }
        case "UICONTEXT_SCROLL": {
            return Object.assign( {}, state, {
                scrollY: action.scrollY
            } );
        }
        case "UICONTEXT_ACCEPT_COOKIES": {
            return Object.assign( {}, state, {
                acceptCookies: true
            } );
        }
        case "UICONTEXT_SHOW_CONFIRMATION": {
            return Object.assign( {}, state, {
                acceptCookies: true
            } );
        }
        default: return state;
    }
};
