/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";

class ExpandCollapse extends React.Component {
    constructor( props ) {
        super( props );
        this.defaultExpanded = props.expanded;
        this.state = {
            expanded: props.expanded,
            disableExpandCollapse: props.disableExpandCollapse
        };
        this.renderIcon = this.renderIcon.bind( this );
    }
    renderIcon() {
        if ( this.state.expanded && this.props.expandIcon ) {
            return ( this.props.collapseIcon );
        } else if ( !this.state.expanded && this.props.collapseIcon ) {
            return ( this.props.expandIcon );
        }
        return null;
    }
    render() {
        if ( this.defaultExpanded !== this.props.expanded ) {
            this.defaultExpanded = this.props.expanded;
            this.setState( { expanded: this.props.expanded } );
        }
        return (
            <div className={ `pw_widget_expand_collapse${ this.props.addClass ? ` ${ this.props.addClass }` : "" }` }>
                <div className="pw_widget_expand_collapse_title_container">
                    { this.props.children && !this.state.disableExpandCollapse ?
                        <h3 onClick={ () => this.setState( { expanded: !this.state.expanded } ) } className={ `pw_widget_expand_collapse_title${ this.state.expanded ? " expanded" : "" }` }><span className="pw_widget_expand_collapse_title_text">{ this.props.title }</span>{ this.renderIcon() }</h3>
                        :
                        <h3 className="pw_widget_expand_collapse_title nolink"><span className="pw_widget_expand_collapse_title_text">{ this.props.title }</span></h3>
                    }
                </div>
                { this.props.children ?
                    <div className="pw_widget_expand_collapse_content">
                        <div className={ this.state.expanded ? "pw_widget_expand_collapse_open" : "pw_widget_expand_collapse_close" }>
                            { this.props.children }
                        </div>
                    </div>
                    : null }
            </div>
        );
    }
}

export default ExpandCollapse;
