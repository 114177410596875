/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable complexity */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Pages from "../../utils/Pages";
import { loadUIContext } from "../../actions/UIContextActions";
import * as SessionActions from "../../actions/SessionActions";
import WebContext from "../../utils/WebContext";

class NavBarLocations extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.locationsRelativeUrl = Pages.getPage( "locations" ).relativeUrl;
    }

    componentDidMount() {
        this.props.loadUIContext();
    }
    render() {
        const mainCategories = this.props.content.locations.categories;
        return (
            <div className="pw-multilevel-navbar">
                <div className="pw-multilevel-navbar-content">
                    { mainCategories.filter( tmp => tmp.locationCategoryWebActive ).map( argCat => {
                        const cat = Pages.applyShopOverides( argCat );
                        return ( <a key={ cat.locationCategoryCategoryId } className="pw-multilevel-main-item" href={ `${ this.locationsRelativeUrl }/${ cat.locationCategoryCategoryId }` }>{ cat.locationCategoryName }</a> );
                    } ) }
                </div>
            </div>
        );
    }
}

NavBarLocations.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    content: state.content
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );
export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NavBarLocations ) );
