import loadable from "@loadable/component";
import * as Pages from "./utils/Pages";

const ManagerLogin = loadable( () => import( "./components/manage/Login" ) );
const ManagerCalendar = loadable( () => import( "./components/manage/Calendar" ) );
const ManagerCustomers = loadable( () => import( "./components/manage/Customers" ) );

const TeamFeeSchemas = loadable( () => import( "./components/manage/TeamFeeSchemas" ) );
const TeamList = loadable( () => import( "./components/manage/TeamList" ) );
const Services = loadable( () => import( "./components/manage/Services" ) );
const ServicesCategories = loadable( () => import( "./components/manage/ServicesCategories" ) );
const Bonos = loadable( () => import( "./components/manage/Bonos" ) );
const ShopItems = loadable( () => import( "./components/manage/ShopItems" ) );
const ShopItemsCategories = loadable( () => import( "./components/manage/ShopItemsCategories" ) );
const ShopSuppliers = loadable( () => import( "./components/manage/ShopSuppliers" ) );
const ShopBrands = loadable( () => import( "./components/manage/ShopBrands" ) );
const Resources = loadable( () => import( "./components/manage/Resources" ) );
const Consents = loadable( () => import( "./components/manage/Consents" ) );
const ReportDailyCash = loadable( () => import( "./components/manage/ReportDailyCash" ) );
const ReportDailyTeamFee = loadable( () => import( "./components/manage/ReportDailyTeamFee" ) );

const ManagerCarts = loadable( () => import( "./components/manage/Carts" ) );
const ManagerInvoices = loadable( () => import( "./components/manage/Invoices" ) );
const ManagerCourses = loadable( () => import( "./components/manage/Courses" ) );
const ManagerCoursesSteps = loadable( () => import( "./components/manage/CoursesSteps" ) );
const ManagerCoursesClasses = loadable( () => import( "./components/manage/CoursesClasses" ) );
const ManagerCoursesClassesUsers = loadable( () => import( "./components/manage/CoursesClassesUsers" ) );
const ManagerWebUsers = loadable( () => import( "./components/manage/WebUsers" ) );
const ManagerSales = loadable( () => import( "./components/manage/Sales" ) );
const ManagerPages = loadable( () => import( "./components/manage/Pages" ) );
const ManagerReports = loadable( () => import( "./components/manage/Reports" ) );
const ManagerLocations = loadable( () => import( "./components/manage/Locations" ) );
const Outgoings = loadable( () => import( "./components/manage/Outgoings" ) );
const Incomes = loadable( () => import( "./components/manage/Incomes" ) );
const MessagesTemplates = loadable( () => import( "./components/manage/MessagesTemplates" ) );
const Subscriptions = loadable( () => import( "./components/manage/Subscriptions" ) );
const PromoCodes = loadable( () => import( "./components/manage/PromoCodes" ) );

const routes = Pages.all.slice( 0 );
if ( Pages.company.managerURLRelative ) {
    routes.push( {
        path: Pages.company.managerURLRelative,
        relativeUrl: Pages.company.managerURLRelative,
        component: ManagerLogin,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/calendar/:calendarDate`,
        relativeUrl: `${ Pages.company.managerURLRelative }/calendar/:calendarDate`,
        component: ManagerCalendar,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/calendar`,
        relativeUrl: `${ Pages.company.managerURLRelative }/calendar`,
        component: ManagerCalendar,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/customers`,
        relativeUrl: `${ Pages.company.managerURLRelative }/customers`,
        component: ManagerCustomers,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/carts`,
        relativeUrl: `${ Pages.company.managerURLRelative }/carts`,
        component: ManagerCarts,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/invoices`,
        relativeUrl: `${ Pages.company.managerURLRelative }/invoices`,
        component: ManagerInvoices,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/courses`,
        relativeUrl: `${ Pages.company.managerURLRelative }/courses`,
        component: ManagerCourses,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/courses/steps/:courseId`,
        relativeUrl: `${ Pages.company.managerURLRelative }/courses/steps/:courseId`,
        component: ManagerCoursesSteps,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/courses/classes/users/:courseId/:courseClassId`,
        relativeUrl: `${ Pages.company.managerURLRelative }/courses/classes/users/:courseId/:courseClassId`,
        component: ManagerCoursesClassesUsers,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/courses/classes/:courseId`,
        relativeUrl: `${ Pages.company.managerURLRelative }/courses/classes/:courseId`,
        component: ManagerCoursesClasses,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/webusers`,
        relativeUrl: `${ Pages.company.managerURLRelative }/webusers`,
        component: ManagerWebUsers,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/sales`,
        relativeUrl: `${ Pages.company.managerURLRelative }/sales`,
        component: ManagerSales,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/sales/:id`,
        relativeUrl: `${ Pages.company.managerURLRelative }/sales/:id`,
        component: ManagerSales,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/sales/:id/calendar/:calendarDate`,
        relativeUrl: `${ Pages.company.managerURLRelative }/sales/:id/calendar/:calendarDate`,
        component: ManagerSales,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/pages`,
        relativeUrl: `${ Pages.company.managerURLRelative }/pages`,
        component: ManagerPages,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/reports`,
        relativeUrl: `${ Pages.company.managerURLRelative }/reports`,
        component: ManagerReports,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/locations`,
        relativeUrl: `${ Pages.company.managerURLRelative }/locations`,
        component: ManagerLocations,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/teamfeeschemas`,
        relativeUrl: `${ Pages.company.managerURLRelative }/teamfeeschemas`,
        component: TeamFeeSchemas,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/team`,
        relativeUrl: `${ Pages.company.managerURLRelative }/team`,
        component: TeamList,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/services`,
        relativeUrl: `${ Pages.company.managerURLRelative }/services`,
        component: Services,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/servicescategories`,
        relativeUrl: `${ Pages.company.managerURLRelative }/servicescategories`,
        component: ServicesCategories,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/bonos`,
        relativeUrl: `${ Pages.company.managerURLRelative }/bonos`,
        component: Bonos,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/shopitems`,
        relativeUrl: `${ Pages.company.managerURLRelative }/shopitems`,
        component: ShopItems,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/shopitemscategories`,
        relativeUrl: `${ Pages.company.managerURLRelative }/shopitemscategories`,
        component: ShopItemsCategories,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/shopsuppliers`,
        relativeUrl: `${ Pages.company.managerURLRelative }/shopsuppliers`,
        component: ShopSuppliers,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/shopbrands`,
        relativeUrl: `${ Pages.company.managerURLRelative }/shopbrands`,
        component: ShopBrands,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/resources`,
        relativeUrl: `${ Pages.company.managerURLRelative }/resources`,
        component: Resources,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/consents`,
        relativeUrl: `${ Pages.company.managerURLRelative }/consents`,
        component: Consents,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/dailycashreport`,
        relativeUrl: `${ Pages.company.managerURLRelative }/dailycashreport`,
        component: ReportDailyCash,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/dailyteamfeereport`,
        relativeUrl: `${ Pages.company.managerURLRelative }/dailyteamfeereport`,
        component: ReportDailyTeamFee,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/outgoings`,
        relativeUrl: `${ Pages.company.managerURLRelative }/outgoings`,
        component: Outgoings,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/incomes`,
        relativeUrl: `${ Pages.company.managerURLRelative }/incomes`,
        component: Incomes,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/messagestemplates`,
        relativeUrl: `${ Pages.company.managerURLRelative }/messagestemplates`,
        component: MessagesTemplates,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/subscriptions`,
        relativeUrl: `${ Pages.company.managerURLRelative }/subscriptions`,
        component: Subscriptions,
        exact: true
    } );
    routes.push( {
        path: `${ Pages.company.managerURLRelative }/promocodes`,
        relativeUrl: `${ Pages.company.managerURLRelative }/promocodes`,
        component: PromoCodes,
        exact: true
    } );
}
export default routes;
