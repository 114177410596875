import React from "react";

class Icon extends React.Component {
    constructor( props ) {
        super( props );
        this.icon = props.name;
    }
    render() {
        if ( !this.icon ) {
            return null;
        }
        let theme = this.props.theme || "default";
        let icon = this.props.name;
        let extraClassName = this.props.color ? ` pw_generic_icon_${ this.props.color }` : "";
        if ( this.props.className ) {
            extraClassName += ` ${ this.props.className }`;
        }
        return (
            <img src={ `/static/icons/${ theme }/${ icon }.svg` } alt="Generic PW Icon" style={ this.props.style || null } className={ `pw_generic_icon${ extraClassName }` } />
        );
    }
}

export default Icon;
