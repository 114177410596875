import { connect } from "react-redux";
import { loadUIContext } from "../actions/UIContextActions";
import Home from "../domain/ayunabeautymallorca.com/components/Home";

const mapStateToProps = ( state ) => ( {
    circuits: state.data,
    UIContext: state.UIContext
} );

const mapDispatchToProps = {
    loadUIContext
};

export default connect( mapStateToProps, mapDispatchToProps )( Home );
