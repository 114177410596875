/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import * as ManagerApi from "../apimanager";
import { now, uuid } from "../utils/DataUtils";
import { requestManagerBookings } from "./ManagerCalendarActions";

export const changeManagerCustomerEditAttr = ( key, value ) => ( {
    type: "MANAGER_CUSTOMER_CHANGE_EDIT_ATTR",
    key,
    value
} );

export const changeManagerCustomerAttr = ( key, value ) => ( {
    type: "MANAGER_CUSTOMER_CHANGE_ATTR",
    key,
    value
} );
export const changeManagerCustomerChildAttr = ( key, value ) => ( {
    type: "MANAGER_CUSTOMER_CHANGE_EDIT_CHILD_ATTR",
    key,
    value
} );
export const changeManagerCustomerSubscriptionAttr = ( key, value ) => ( {
    type: "MANAGER_CUSTOMER_CHANGE_EDIT_SUBSCRIPTION_ATTR",
    key,
    value
} );
export const managerNewCustomer = ( from ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_NEW_EDIT", from } );
};

export const managerEditCustomer = ( customer ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_EDIT_EDIT", customer } );
};

export const managerCustomerModalShowToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_MODAL_SHOW_TOAST_MESSAGE", message } );
    setTimeout( () => dispatch( { type: "MANAGER_CUSTOMER_MODAL_HIDE_TOAST_MESSAGE", message } ), 5000 );
};

export const managerCustomerModalHideToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_MODAL_HIDE_TOAST_MESSAGE", message } );
};

export const requestManagerCustomers = ( successDispatchAction, customerId ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_ATTEMPT" } );
    return ManagerApi.fetchCustomers( customerId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_SUCCESS", items: jsonRes.items, customerId } );
                if ( successDispatchAction ) {
                    dispatch( successDispatchAction );
                }
            } else {
                dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_ERROR", errorMessage: err.message } );
        } );
};
export const reloadEditingCustomer = () => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    if ( ManagerCustomers.editing && ManagerCustomers.editing.customerId ) {
        return ManagerApi.fetchCustomers( ManagerCustomers.editing.customerId )
            .then( ( jsonRes ) => {
                if ( jsonRes.success && jsonRes.items && jsonRes.items.length === 1 ) {
                    dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_SUCCESS", items: jsonRes.items, customerId: ManagerCustomers.editing.customerId } );
                    dispatch( changeManagerCustomerAttr( "editing", jsonRes.items[ 0 ] ) );
                } else {
                    dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_ERROR", errorMessage: jsonRes.errorMessage } );
                }
            } )
            .catch( ( err ) => {
                dispatch( { type: "MANAGER_CUSTOMERS_LIST_FETCH_ERROR", errorMessage: err.message } );
            } );
    }
    return null;
};

export const managerNewCustomerRequest = ( saveAndContinue ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_NEW_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    let transaction = { newCustomerId: null, newCustomer: null };
    return ManagerApi.newCustomer( ManagerCustomers.editing )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                if ( ManagerCustomers.editing.from === "sale" ) {
                    dispatch( { type: "MANAGER_SALE_CHANGE_EDIT_ATTR", key: "saleCustomerId", value: jsonRes.item.customerId } );
                }
                if ( [ "calendar" ].includes( ManagerCustomers.editing.from ) ) {
                    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerId", value: jsonRes.item.customerId } );
                }
                if ( ManagerCustomers.editing.from === "location" ) {
                    dispatch( { type: "MANAGER_LOCATION_CHANGE_EDIT_ATTR", key: "locationCustomerId", value: jsonRes.item.customerId } );
                }
                dispatch( { type: "MANAGER_CUSTOMER_NEW_SUCCESS", saveAndContinue } );
                transaction.newCustomerId = jsonRes.item.customerId;
                transaction.newCustomer = jsonRes.item;
                return dispatch( requestManagerCustomers() );
            }
            if ( jsonRes.errorMessage ) {
                dispatch( managerCustomerModalShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
            }
            dispatch( { type: "MANAGER_CUSTOMER_NEW_ERROR" } );
            return true;
        } )
        .then( () => {
            if ( ManagerCustomers.editing.from === "invoice" ) {
                dispatch( { type: "MANAGER_INVOICE_CHANGE_EDIT_ATTR", key: "invoiceCustomerId", value: transaction.newCustomerId } );
                dispatch( { type: "MANAGER_INVOICE_CHANGE_EDIT_ATTR", key: "customerChanged", value: now() } );
            }
            return { item: transaction.newCustomer };
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_NEW_ERROR", err } );
        } );
};
export const requestManagerCustomerPendingBookings = ( saleCustomerId ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: true } );
    return ManagerApi.fetchCustomerPendingBookings( saleCustomerId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerPendingBookings", value: jsonRes.items } );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
            console.log( err.message );
        } );
};
export const requestManagerCustomerSales = ( saleCustomerId ) => ( dispatch, getState ) => {
    if ( !saleCustomerId ) {
        return new Promise( ( resolve, reject ) => reject( new Error( "No customer Id" ) ) );
    }
    const { ManagerCustomers } = getState();
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: true } );
    return ManagerApi.fetchCustomerSales( saleCustomerId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerSales", value: jsonRes.items } );
            }
            if ( ManagerCustomers.editing
                && ManagerCustomers.editing.customerId === saleCustomerId ) {
                dispatch( changeManagerCustomerEditAttr( "customerSales", jsonRes.items ) );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
            console.log( err.message );
        } );
};
export const requestManagerCustomerConsents = ( customerId ) => ( dispatch ) => {
    if ( !customerId ) {
        return new Promise( ( resolve, reject ) => reject( new Error( "No customer Id" ) ) );
    }
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: true } );
    return ManagerApi.fetchCustomerConsents( customerId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerConsents", value: jsonRes.items } );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
            console.log( err.message );
        } );
};
export const managerUpdateCustomerRequest = ( saveAndContinue ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ATTEMPT" } );
    const { ManagerCustomers, ManagerInvoices } = getState();
    return ManagerApi.updateCustomer( ManagerCustomers.editing )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CUSTOMER_UPDATE_SUCCESS", saveAndContinue } ) );
                return dispatch( requestManagerCustomers() );
            }
            if ( jsonRes.errorMessage ) {
                dispatch( managerCustomerModalShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
            }
            dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ERROR" } );
            return true;
        } )
        .then( () => {
            if ( ManagerInvoices.isEditing ) {
                dispatch( { type: "MANAGER_INVOICE_CHANGE_EDIT_ATTR", key: "customerChanged", value: now() } );
            }
            return true;
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ERROR", err } );
        } );
};

export const requestManagerCustomerStatusUpdate = ( newStatus, customerId, customerEmail, newActive ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_UPDATE_STATUS_ATTEMPT" } );
    ManagerApi.updateCustomerStatus( newStatus, customerId, customerEmail, )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( {
                    type: "MANAGER_CUSTOMER_UPDATE_STATUS_SUCCESS", customerStatus: newStatus, customerActive: newActive, customerId
                } );
            } else {
                dispatch( { type: "MANAGER_CUSTOMER_UPDATE_STATUS_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_UPDATE_STATUS_ERROR", errorMessage: err.message } );
        } );
};

export const requestMergeCustomers = ( mainCustomer, customerDuplicate ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ATTEMPT" } );
    return ManagerApi.mergeCustomers( mainCustomer, customerDuplicate )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CUSTOMER_UPDATE_SUCCESS" } ) );
                dispatch( requestManagerCustomers() );
            } else {
                dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ERROR", err } );
        } );
};

export const managerUpdateCustomerStatusRequest = () => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    ManagerApi.updateCustomer( ManagerCustomers.editing )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CUSTOMER_UPDATE_SUCCESS" } ) );
                dispatch( requestManagerCustomers() );
            } else {
                dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_UPDATE_ERROR", err } );
        } );
};

export const requestManagerCustomerPaySalesDebth = ( payments ) => () => ManagerApi.paySalesDebt( payments )
    .then( ( res ) => res.json() )
    .then( ( jsonRes ) => {
        if ( jsonRes.success ) {
            // maybe reload some items
        } else {
            // dispatch an error
        }
        return true;
    } )
    .catch( () => {
        // dispatch an error
    } );

// Pets
export const requestManagerPets = () => ( dispatch ) => ManagerApi.fetchCustomerPets()
    .then( ( jsonRes ) => {
        if ( jsonRes.success ) {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "petsItems", value: jsonRes.items } );
        }
    } )
    .catch( ( err ) => {
        console.log( err.message );
    } );

export const requestManagerCustomerPets = ( customerId ) => ( dispatch, getState ) => {
    const { ManagerCustomers } = getState();
    if ( !customerId ) {
        customerId = ManagerCustomers.editing.customerId;
    }
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: true } );
    ManagerApi.fetchCustomerPets( customerId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerPets", value: jsonRes.items } );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
            console.log( err.message );
        } );
};
export const managerNewCustomerPetRequest = () => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    return ManagerApi.newCustomerPet( ManagerCustomers.editingChild )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_SUCCESS" } );
                return dispatch( requestManagerCustomerPets( ManagerCustomers.editingChild.customerPetCustomerId ) );
            }
            if ( jsonRes.errorMessage ) {
                dispatch( managerCustomerModalShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_ERROR" } );
            return true;
        } )
        .then( () => dispatch( requestManagerPets() ) )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_ERROR", err } );
        } );
};
export const managerUpdateCustomerPetRequest = () => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    return ManagerApi.updateCustomerPet( ManagerCustomers.editingChild )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_SUCCESS" } );
                return dispatch( requestManagerCustomerPets( ManagerCustomers.editingChild.customerPetCustomerId ) );
            }
            if ( jsonRes.errorMessage ) {
                dispatch( managerCustomerModalShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_ERROR" } );
            return true;
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_ERROR", err } );
        } );
};
// payment links
export const requestManagerPaymentLinks = () => ( dispatch ) => {
    ManagerApi.fetchPaymentLinks()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "paymentLinksItems", value: jsonRes.items } );
            }
        } )
        .catch( ( err ) => {
            console.log( err.message );
        } );
};
export const requestManagerCustomerPaymentLinks = ( customerId ) => ( dispatch, getState ) => {
    const { ManagerCustomers } = getState();
    if ( !customerId ) {
        customerId = ManagerCustomers.editing.customerId;
    }
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: true } );
    ManagerApi.fetchPaymentLinks( customerId )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerPaymentLinks", value: jsonRes.items } );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "tmpSelectedCustomerIsLoading", value: false } );
            console.log( err.message );
        } );
};
export const managerNewPaymentLinkRequest = ( paymentLink ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    return ManagerApi.newPaymentLink( paymentLink || ManagerCustomers.editingChild )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_SUCCESS" } );
                return dispatch( requestManagerCustomerPaymentLinks( ManagerCustomers.editingChild.paymentLinkCustomerId ) );
            }
            if ( jsonRes.errorMessage ) {
                dispatch( managerCustomerModalShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_ERROR" } );
            return true;
        } )
        .then( () => dispatch( requestManagerPaymentLinks() ) )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_NEW_ERROR", err } );
        } );
};
export const managerUpdatePaymentLinkRequest = ( paymentLink ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    let paymentLinkItem = paymentLink || ManagerCustomers.editingChild;
    return ManagerApi.updatePaymentLink( paymentLinkItem )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_SUCCESS" } );
                return dispatch( requestManagerCustomerPaymentLinks( ManagerCustomers.editingChild.customerPetCustomerId ) );
            }
            if ( jsonRes.errorMessage ) {
                dispatch( managerCustomerModalShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
            }
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_ERROR" } );
            return true;
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHILD_UPDATE_ERROR", err } );
        } );
};
export const payManagerCustomerDebt = ( paymentMethod, customer, excludeSaleId, paymentTotalAmount, prioritizeSaleId ) => ( dispatch, getState ) => {
    let availableAmount = paymentTotalAmount;
    const { ManagerCalendar } = getState();
    dispatch( changeManagerCustomerAttr( "isLoadingDebt", true ) );
    let customerSalesWithDebt = customer.customerSalesWithDebt.map( tmp => tmp );
    if ( prioritizeSaleId ) {
        let prioritizedSale = customerSalesWithDebt.find( tmpSale => tmpSale.saleId === prioritizeSaleId );
        if ( prioritizedSale ) {
            let tmpSalesWithDebt = [ prioritizedSale ];
            customerSalesWithDebt.forEach( tmpSaleWithDebt => {
                if ( tmpSaleWithDebt.saleId !== prioritizeSaleId ) {
                    tmpSalesWithDebt.push( tmpSaleWithDebt );
                }
            } );
            customerSalesWithDebt = tmpSalesWithDebt;
        }
    }
    let salesWithDebt = customerSalesWithDebt.map( tmpSaleWithDebt => {
        if ( availableAmount === 0 || tmpSaleWithDebt.saleId === excludeSaleId ) {
            return null;
        }
        let debtAmountToPay = tmpSaleWithDebt.saleTotalAmount - tmpSaleWithDebt.salePaidTotalAmount;
        if ( availableAmount < debtAmountToPay ) {
            debtAmountToPay = availableAmount;
        }
        availableAmount -= debtAmountToPay;
        return Object.assign( {}, tmpSaleWithDebt, { debtAmountToPay } );
    } ).filter( tmpNotNull => tmpNotNull );
    const payments = salesWithDebt.map( selectedDebt => Object.assign( {}, { saleId: selectedDebt.saleId, salePaymentPaymentMethod: paymentMethod, salePaymentTotalAmount: selectedDebt.debtAmountToPay } ) );
    return dispatch( requestManagerCustomerPaySalesDebth( payments ) )
        .then( () => {
            if ( ManagerCalendar.items && ManagerCalendar.items.length > 0 ) {
                dispatch( requestManagerBookings() );
            }
            return dispatch( reloadEditingCustomer() );
        } )
        .then( () => dispatch( changeManagerCustomerAttr( "isLoadingDebt", false ) ) )
        .then( () => dispatch( requestManagerCustomers() ) )
        .catch( () => dispatch( changeManagerCustomerAttr( "isLoadingDebt", false ) ) );
};
// Subscriptions
export const requestManagerSubscriptions = () => ( dispatch ) => ManagerApi.fetchSubscriptions()
    .then( ( jsonRes ) => {
        if ( jsonRes.success ) {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "subscriptionsItems", value: jsonRes.items } );
        }
    } )
    .catch( ( err ) => {
        console.log( err.message );
    } );

export const managerNewSubscription = ( item ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "editingSubscription", value: item } );
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isEditingSubscription", value: true } );
};
export const managerEditSubscription = ( item ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "editingSubscription", value: item } );
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isEditingSubscription", value: true } );
};
export const managerSaveSubscriptionRequest = ( subscription ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isLoadingSubscription", value: true } );
    const { ManagerCustomers } = getState();
    let promise = null;
    let subscriptionToSend = subscription || ManagerCustomers.editingSubscription;
    if ( subscriptionToSend.subscriptionId ) {
        promise = ManagerApi.updateSubscription( subscriptionToSend );
    } else {
        promise = ManagerApi.newSubscription( subscriptionToSend );
    }
    return promise
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isLoadingSubscription", value: false } );
            if ( jsonRes.success ) {
                return dispatch( requestManagerSubscriptions() );
            }
            return true;
        } )
        .catch( () => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isLoadingSubscription", value: false } );
        } );
};
// Promo Codes
export const changeManagerCustomerPromoCodeAttr = ( key, value ) => ( {
    type: "MANAGER_CUSTOMER_CHANGE_EDIT_PROMOCODE_ATTR",
    key,
    value
} );
export const requestManagerPromoCodes = () => ( dispatch ) => ManagerApi.fetchPromoCodes()
    .then( ( jsonRes ) => {
        if ( jsonRes.success ) {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "promoCodesItems", value: jsonRes.items } );
        }
    } )
    .catch( ( err ) => {
        console.log( err.message );
    } );

export const managerNewPromoCode = ( item ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "editingPromoCode", value: item } );
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isEditingPromoCode", value: true } );
};
export const managerEditPromoCode = ( item ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "editingPromoCode", value: item } );
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isEditingPromoCode", value: true } );
};
export const managerSavePromoCodeRequest = ( promoCode, teamMemberImage ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isLoadingPromoCode", value: true } );
    const { ManagerCustomers } = getState();
    let promise = null;
    let promoCodeToSend = promoCode || ManagerCustomers.editingPromoCode;
    if ( promoCodeToSend.promoCodeId ) {
        promise = ManagerApi.updatePromoCode( promoCodeToSend, teamMemberImage );
    } else {
        promise = ManagerApi.newPromoCode( promoCodeToSend, teamMemberImage );
    }
    return promise
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isLoadingPromoCode", value: false } );
            if ( jsonRes.success ) {
                return dispatch( requestManagerPromoCodes() );
            }
            return true;
        } )
        .catch( () => {
            dispatch( { type: "MANAGER_CUSTOMER_CHANGE_ATTR", key: "isLoadingPromoCode", value: false } );
        } );
};
