export default {
    generic: {
        title: "",
        subTitle: "",
        targetRowHeight: 400,
        photos: [
            {
                src: "/static/ayunacentro-77e127ee-dd14-4db1-a474-b1081e54a161.jpg",
                width: 1,
                height: 0.6675759228950718,
                alt: "Ayuna Beauty Mallorca"
            },
            {
                src: "/static/ayunacentro-054b4602-6503-4d14-b5fd-2e3d02d0fb33.jpg",
                width: 1,
                height: 1.4980205859065716,
                alt: "Ayuna Beauty Mallorca"
            },
            {
                src: "/static/ayunacentro-310cb1de-02d1-42f8-ae01-9a98f7027598.jpg",
                width: 1,
                height: 1.4978296256104178,
                alt: "Ayuna Beauty Mallorca"
            },
            {
                src: "/static/ayunacentro-1e200162-f83d-42f5-b767-a070404cc85a.jpg",
                width: 1,
                height: 0.6675333680013867,
                alt: "Ayuna Beauty Mallorca"
            },
            {
                src: "/static/ayunacentro-9235d579-aedc-4513-9195-4d80910eb5ac.jpg",
                width: 1,
                height: 0.6675531914893617,
                alt: "Ayuna Beauty Mallorca"
            },
            {
                src: "/static/ayunacentro-6ebda090-8bfe-4636-aa5f-cf010d0fe118.jpg",
                width: 1,
                height: 0.6676422169379916,
                alt: "Ayuna Beauty Mallorca"
            },
            {
                src: "/static/ayunacentro-9a74ec84-395c-4671-8736-57afb411882a.jpg",
                width: 1,
                height: 0.6675972454866927,
                alt: "Ayuna Beauty Mallorca"
            },
            {
                src: "/static/ayunacentro-be8738e4-20ce-4734-a8ba-6fdca0cb7ca6.jpg",
                width: 1,
                height: 0.6675612810878512,
                alt: "Ayuna Beauty Mallorca"
            }
        ]
    }
};
