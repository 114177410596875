import React from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import * as Pages from "../../utils/Pages";
import { loadUIContext, acceptCookies } from "../../actions/UIContextActions";
import { contactSend, contactOnChange } from "../../actions/ContactActions";

class Splash extends React.Component {
    constructor ( props ) {
        super( props );
        this.cookies = new Cookies();
        this.state = {
            acceptAdultsOnly: false
        };
        if ( Pages.company.adultsOnlyPage ) {
            this.state.acceptAdultsOnly = this.cookies.get( "acceptAdultsOnly" );
        }
    }
    componentDidMount() {
        this.props.loadUIContext();
    }
    render () {
        if ( this.fake ) {
            return null;
        }
        return (
            <div className={ `pw_splash_container pw_splash_${ Pages.company.splash.animation }` }>
                <span id="splash-overlay" className="splash" />
                <span id="splash-circle" />
                <div className="pw-splash-content">
                    <img src="/static/logo-splash.png" alt="logo" />
                    <p className="pw-splash-logo-phrase">{ Pages.company.splash.mainText }</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    contact: state.contact,
    UIContext: state.UIContext
} );

const mapDispatchToProps = {
    loadUIContext,
    contactSend,
    contactOnChange,
    acceptCookies
};

export default connect( mapStateToProps, mapDispatchToProps )( Splash );
