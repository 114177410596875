/* eslint-disable prefer-rest-params */
/* eslint-disable no-restricted-syntax */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { loadableReady } from "@loadable/component";
import Layout from "./components/Layout";
import store from "./store";

const jsx = (
    <ReduxProvider store={ store }>
        <Router>
            <Layout language={ store.getState().pwSession.language } />
        </Router>
    </ReduxProvider>
);

loadableReady( () => {
    const app = document.getElementById( "app" );
    ReactDOM.hydrate( jsx, app );
} );
