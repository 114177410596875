import React from "react";
import { Switch, Route } from "react-router-dom";
import routes from "../../routes";
import NavBar from "./layout/NavBar";
import Footer from "./layout/Footer";
import PaymentLinkModal from "./PaymentLinkModal";
import CookiesAcceptance from "../layout/CookiesAcceptance";

const Layout = () => (
    <div>
        <NavBar />
        <div id="pws-manage-content">
            <Switch>
                { routes.map( route => <Route key={ route.path } { ...route } /> ) }
            </Switch>
        </div>
        <Footer />
        <CookiesAcceptance />
        <PaymentLinkModal />
    </div>
);

export default Layout;
