export default ( state = {
    sending: false,
    errorMessage: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    contactMessage: "",
    reCaptchaToken: "",
    contactSent: false,
    privacyPolicyAccept: false
}, action ) => {
    switch ( action.type ) {
        case "CONTACT_SEND_START": {
            return Object.assign( {}, state, { sending: true, errorMessage: "" } );
        }
        case "CONTACT_SEND_ERROR": {
            return Object.assign( {}, state, { sending: false, errorMessage: action.errorMessage } );
        }
        case "CONTACT_SEND_OK": {
            return Object.assign( {}, state, { sending: false, errorMessage: "", contactSent: true } );
        }
        case "CONTACT_ON_CHANGE": {
            return Object.assign( {}, state, action.mutation );
        }
        default: return state;
    }
};
