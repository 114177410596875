import React from "react";
import { Navbar, Nav, Container, Offcanvas, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadUIContext } from "../../../actions/UIContextActions";
import { managerLogout } from "../../../actions/SessionActions";
import * as Pages from "../../../../src/utils/Pages";
import { uuid } from "../../../../src/utils/DataUtils";
import { listenHotkeys } from "../../../../src/utils/ManagerUtils";

class NavBar extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { expanded: false };
        this.state.brandImg = "/static/manager-logo-rectangle.png";
    }

    componentDidMount() {
        this.props.loadUIContext();
        document.addEventListener( "keyup", listenHotkeys, false );
    }
    render() {
        const isLoggedIn = this.props.pwSession.manager.accessToken !== "";
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        return (
            <Navbar fixed="top" collapseOnSelect onToggle={ () => this.setState( { expanded: !this.state.expanded } ) } scrolling="true" expand={ false } expanded={ this.state.expanded } bg="pw-transparent" className="pw_navbar_offcanvas">
                <Container fluid>
                    { isLoggedIn ?
                        <Navbar.Toggle
                            onMouseEnter={ () => {
                                this.setState( { expanded: true } );
                            } }
                            onMouseLeave={ () => {
                                this.setState( { expanded: true } );
                            } }
                        /> : null }
                    <Navbar.Brand href="/">
                        <img
                            id="pw-logo-navbar"
                            alt=""
                            src="/static/logo-navbar.png"
                        />
                    </Navbar.Brand>
                    { isLoggedIn ?
                        <Navbar.Offcanvas
                            placement="start"
                            backdrop={ false }
                            onMouseLeave={ () => this.setState( { expanded: !this.state.expanded } ) }
                            onHide={ () => this.setState( { expanded: !this.state.expanded } ) }
                            className="pw_navbar_offcanvas_content"
                        >
                            <Offcanvas.Header closeButton={ !isLandscape }>
                                <Offcanvas.Title>
                                    <img
                                        alt=""
                                        src="/static/logo-navbar.png"
                                    />
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    { isLoggedIn && this.props.pwSession.manager.capabilities.managerMenuItems ? this.props.pwSession.manager.capabilities.managerMenuItems.map( menuItem => {
                                        if ( menuItem.menuItems ) {
                                            return (
                                                <NavDropdown key={ uuid() } title={ menuItem.menuText } className="pws-manager-menu-item">
                                                    { menuItem.menuItems.filter( subItem => subItem.active || typeof subItem.active === "undefined" ).map( subItem => (
                                                        <Nav.Link key={ uuid() } className="pws-manager-menu-item pws-manager-menu-subitem" href={ Pages.manager.getRelativeUrl( subItem.pageId ) }>{ subItem.menuText }</Nav.Link>
                                                    ) ) }
                                                </NavDropdown>
                                            );
                                        }
                                        return ( <Nav.Link key={ menuItem.pageId } className="pws-manager-menu-item" href={ Pages.manager.getRelativeUrl( menuItem.pageId ) }>{ menuItem.menuText }</Nav.Link> );
                                    } ) : null }
                                </Nav>
                                <div className="pw_navbar_user_container">
                                    { isLoggedIn ? <Nav.Link className="pws-manager-menu-item" onClick={ () => this.props.managerLogout( this.props.history ) }>Cerrar sesión</Nav.Link> : null }
                                </div>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas> : null }
                </Container>
            </Navbar>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = {
    loadUIContext,
    managerLogout
};

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NavBar ) );
