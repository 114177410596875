/* eslint-disable complexity */
import * as DataUtils from "../utils/DataUtils";

const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isEditingChild: false,
    isLoading: false,
    isLoadingChild: false,
    isError: false,
    listIsLoading: false,
    listErrorMessage: "",
    itemsBookedSlotsLoading: false,
    itemsBookedSlotsErrorMessage: "",
    errorMessage: "",
    editing: {},
    editingChild: {},
    items: [],
    itemsBookedSlots: [],
    toastMessages: [],
    remindersItems: [],
    showReminders: false
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_CALENDAR_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_CALENDAR_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_CALENDAR_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items.map( tmp => Object.assign( {}, tmp, { start: DataUtils.dateTimeToDate( tmp.start ), end: DataUtils.dateTimeToDate( tmp.end ) } ) )
            } );
        }
        case "MANAGER_REMINDERS_LIST_FETCH_SUCCESS": {
            return update( state, {
                remindersItems: action.items
            } );
        }
        case "MANAGER_CALENDAR_BOOKEDSLOTS_FETCH_ATTEMPT": {
            return update( state, {
                itemsBookedSlotsLoading: true,
                itemsBookedSlotsErrorMessage: ""
            } );
        }
        case "MANAGER_CALENDAR_BOOKEDSLOTS_FETCH_ERROR": {
            return update( state, {
                itemsBookedSlotsLoading: false,
                itemsBookedSlotsErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_CALENDAR_BOOKEDSLOTS_FETCH_SUCCESS": {
            return update( state, {
                itemsBookedSlotsLoading: false,
                itemsBookedSlotsErrorMessage: "",
                itemsBookedSlots: action.items
            } );
        }
        case "MANAGER_CALENDAR_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_CALENDAR_CHANGE_EDIT_CHILD_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_CALENDAR_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_CALENDAR_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessages: newToastMessages } );
        }
        case "MANAGER_CALENDAR_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessages: newToastMessages } );
        }
        case "MANAGER_CALENDAR_NEW_BOOKING_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_CALENDAR_NEW_BOOKING_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_CALENDAR_NEW_BOOKING_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        default: return state;
    }
};
