/* eslint-disable prefer-destructuring */
import * as ManagerApi from "../apimanager";
import * as ManagerCustomersActions from "./ManagerCustomersActions";
import { uuid, sortArray } from "../utils/DataUtils";

export const changeManagerSaleEditAttr = ( key, value ) => ( {
    type: "MANAGER_SALE_CHANGE_EDIT_ATTR",
    key,
    value
} );

export const changeManagerSaleAttr = ( key, value ) => ( {
    type: "MANAGER_SALE_CHANGE_ATTR",
    key,
    value
} );
export const changeManagerSaleAddPayment = ( item ) => ( {
    type: "MANAGER_SALE_CHANGE_ADD_PAYMENT",
    item
} );
export const changeManagerSaleUpdatePayment = ( item ) => ( {
    type: "MANAGER_SALE_CHANGE_UPDATE_PAYMENT",
    item
} );
export const changeManagerSaleRemovePayment = ( item ) => ( {
    type: "MANAGER_SALE_CHANGE_REMOVE_PAYMENT",
    item
} );
export const changeManagerSaleAddItem = ( item ) => ( {
    type: "MANAGER_SALE_CHANGE_ADD_ITEM",
    item
} );
export const changeManagerSaleUpdateItem = ( item ) => ( {
    type: "MANAGER_SALE_CHANGE_UPDATE_ITEM",
    item
} );
export const changeManagerSaleRemoveItem = ( item ) => ( {
    type: "MANAGER_SALE_CHANGE_REMOVE_ITEM",
    item
} );

export const managerNewSale = ( item ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_SALE_NEW_EDIT", item } );
};

export const managerEditSale = ( item ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_SALE_EDIT_EDIT", item } );
};

export const requestManagerSales = ( filters, rehydrateItems ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_SALES_LIST_FETCH_ATTEMPT" } );
    return ManagerApi.fetchSales( filters )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                if ( rehydrateItems ) {
                    let newItems = getState().ManagerSales.items.map( currentSale => {
                        let updatedSaleFound = jsonRes.items.find( resultSale => resultSale.saleId === currentSale.saleId );
                        if ( updatedSaleFound ) {
                            return updatedSaleFound;
                        }
                        return currentSale;
                    } );
                    let salesNotFound = jsonRes.items.filter( resultSale => !newItems.find( currentSale => currentSale.saleId === resultSale.saleId ) );
                    if ( salesNotFound.length > 0 ) {
                        newItems = newItems.concat( salesNotFound );
                        newItems = sortArray( newItems, { sortingKeys: [ { alias: "saleDatetimeCreation", direction: "desc" } ] } );
                    }
                    dispatch( { type: "MANAGER_SALES_LIST_FETCH_SUCCESS", items: newItems } );
                } else {
                    dispatch( { type: "MANAGER_SALES_LIST_FETCH_SUCCESS", items: jsonRes.items } );
                }
                return jsonRes.items;
            }
            dispatch( { type: "MANAGER_SALES_LIST_FETCH_ERROR", errorMessage: jsonRes.errorMessage } );
            return null;
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_SALES_LIST_FETCH_ERROR", errorMessage: err.message } );
        } );
};
export const managerSaleShowToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_SALE_SHOW_TOAST_MESSAGE", message } );
    setTimeout( () => dispatch( { type: "MANAGER_SALE_HIDE_TOAST_MESSAGE", message } ), 5000 );
};
export const managerSaleHideToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_SALE_HIDE_TOAST_MESSAGE", message } );
};

export const managerNewSaleRequest = ( argSale, continueEditing ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_SALE_NEW_ATTEMPT" } );
    const { ManagerSales } = getState();
    let sale = ManagerSales.editing;
    if ( argSale ) {
        sale = argSale;
    }
    return ManagerApi.newSale( sale )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                if ( continueEditing ) {
                    dispatch( managerSaleShowToastMessage( {
                        id: uuid(), title: "✅ Cambios guardados", message: "Los cambios han sido guardados exitosamente", backgroundColor: "#d0e6dd"
                    } ) );
                    dispatch( changeManagerSaleAttr( "editing", jsonRes.item ) );
                } else {
                    dispatch( dispatch( { type: "MANAGER_SALE_NEW_SUCCESS" } ) );
                }
                dispatch( requestManagerSales( { saleId: jsonRes.item.saleId }, true ) );
                return jsonRes.item;
            }
            if ( jsonRes.errorMessage ) {
                dispatch( managerSaleShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
            }
            dispatch( { type: "MANAGER_SALE_NEW_ERROR" } );
            return null;
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_SALE_NEW_ERROR", err } );
        } );
};
export const managerUpdateSaleRequest = ( optionalSale, continueEditing ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_SALE_UPDATE_ATTEMPT" } );
    const { ManagerSales } = getState();
    const editing = optionalSale || ManagerSales.editing;
    return ManagerApi.updateSale( editing )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            const editingSale = Object.assign( {}, editing );
            if ( jsonRes.success ) {
                if ( continueEditing ) {
                    dispatch( managerSaleShowToastMessage( {
                        id: uuid(), title: "✅ Cambios guardados", message: "Los cambios han sido guardados exitosamente", backgroundColor: "#d0e6dd"
                    } ) );
                } else {
                    dispatch( dispatch( { type: "MANAGER_SALE_UPDATE_SUCCESS" } ) );
                }
                dispatch( requestManagerSales( { saleId: jsonRes.item.saleId }, true ) );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerSaleShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_SALE_UPDATE_ERROR" } );
            }
            return editingSale;
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_SALE_UPDATE_ERROR", err } );
        } );
};
export const managerUpdateSaleStatusRequest = ( saleId, saleStatus, continueEditing, calledFrom ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_SALE_UPDATE_ATTEMPT" } );
    const { ManagerCustomers } = getState();
    return ManagerApi.updateSaleStatus( saleId, saleStatus )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                if ( continueEditing ) {
                    dispatch( managerSaleShowToastMessage( {
                        id: uuid(), title: "✅ Cambios guardados", message: "Los cambios han sido guardados exitosamente", backgroundColor: "#d0e6dd"
                    } ) );
                    dispatch( changeManagerSaleAttr( "editing", jsonRes.item ) );
                } else {
                    dispatch( dispatch( { type: "MANAGER_SALE_UPDATE_SUCCESS" } ) );
                }
                if ( calledFrom && calledFrom === "calendar" ) {
                    // managed from calendar
                } else {
                    if ( ManagerCustomers.editing && ManagerCustomers.editing.customerId && ManagerCustomers.tmpSelectedCustomerSales && ManagerCustomers.tmpSelectedCustomerSales.length > 0 ) {
                        const isInSales = ManagerCustomers.tmpSelectedCustomerSales.find( tmp => tmp.saleId === saleId );
                        if ( isInSales ) {
                            dispatch( ManagerCustomersActions.requestManagerCustomerSales( ManagerCustomers.editing.customerId, true ) );
                            dispatch( ManagerCustomersActions.reloadEditingCustomer() );
                        }
                    }
                    dispatch( requestManagerSales( { saleId }, true ) );
                }
            } else {
                dispatch( { type: "MANAGER_SALE_UPDATE_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_SALE_UPDATE_ERROR", err } );
        } );
};
