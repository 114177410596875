/* eslint-disable prefer-destructuring */
/* eslint-disable complexity */
/* eslint-disable no-constant-condition */
import * as Pages from "./Pages";

export const checkToken = ( history ) => {
    if ( typeof localStorage === "undefined" ) {
        return "";
    }
    const accessToken = localStorage.getItem( "pwmt" );
    if ( !accessToken ) {
        history.push( `${ Pages.company.managerURLRelative }` );
    }
    return accessToken;
};

export const getToken = () => {
    if ( typeof localStorage === "undefined" ) {
        return "";
    }
    return localStorage.getItem( "pwmt" );
};

export const setToken = ( token ) => {
    localStorage.setItem( "pwmt", token );
};

export const removeToken = () => {
    localStorage.removeItem( "pwmt" );
};

export const base64Encode = ( data ) => {
    if ( typeof btoa !== "undefined" ) {
        return btoa( data );
    }
    return Buffer.from( data ).toString( "base64" );
};

export const base64Decode = ( data ) => {
    if ( typeof atob !== "undefined" ) {
        return atob( data );
    }
    return Buffer.from( data, "base64" ).toString();
};

export const getPDFProtectedFolderURL = () => `/pdf_protected/${ base64Encode( getToken() ) }/`;
export const getPDFProtectedURL = ( fileName ) => `${ getPDFProtectedFolderURL() }${ fileName }`;
export const getProtectedVideoURL = ( fileName ) => `/video_protected/manager/${ base64Encode( getToken() ) }/${ fileName }`;
export const getProtectedDocumentFolderURL = () => `/document_protected/manager/${ base64Encode( getToken() ) }/`;
export const getProtectedDocumentURL = ( fileName ) => `/document_protected/manager/${ base64Encode( getToken() ) }/${ fileName }`;

export const getUserPreference = ( key ) => {
    if ( typeof localStorage === "undefined" ) {
        return null;
    }
    let tmp = localStorage.getItem( "pwusrp" );
    if ( !tmp ) {
        return null;
    }
    tmp = JSON.parse( tmp );
    return tmp[ key ];
};
export const saveUserPreference = ( key, value ) => {
    if ( typeof localStorage === "undefined" ) {
        return null;
    }
    let tmp = localStorage.getItem( "pwusrp" );
    if ( tmp ) {
        tmp = JSON.parse( tmp );
    } else {
        tmp = {};
    }
    tmp[ key ] = value;
    localStorage.setItem( "pwusrp", JSON.stringify( tmp ) );
    return tmp;
};
export const listenHotkeys = ( e ) => {
    console.log( e.metaKey );
    let keynum = null;
    let pressedChar = null;
    if ( e.ctrlKey ) {
        if ( window.event ) {
            // IE
            keynum = e.keyCode;
        } else if ( e.which ) {
            // Netscape/Firefox/Opera
            keynum = e.which;
        }
        pressedChar = String.fromCharCode( keynum );
        switch ( pressedChar ) {
            case "A": {
                // window.location.href = "/palmawebs/calendar";
                break;
            }
            case "C": {
                // window.location.href = "/palmawebs/customers";
                break;
            }
            default: {
                break;
            }
        }
    }
};
export const getMessageTemplate = ( messageTemplates, messageTemplateType, teamMemberId ) => {
    // filtering by global or specific overrides for team member. Excluding others overrides
    let filteredTemplates = messageTemplates.filter( tmp => tmp.messageTemplateType === messageTemplateType && ( !tmp.messageTemplateTeamMemberId || tmp.messageTemplateTeamMemberId === teamMemberId ) );
    let result = null;
    if ( filteredTemplates.length === 0 ) {
        return result;
    }
    if ( teamMemberId ) {
        let messageTemplateForTeamMember = filteredTemplates.find( tmp => tmp.messageTemplateTeamMemberId === teamMemberId );
        if ( messageTemplateForTeamMember ) {
            result = messageTemplateForTeamMember;
        } else {
            result = filteredTemplates[ 0 ];
        }
    } else {
        result = filteredTemplates[ 0 ];
    }
    return result;
};

export const getMessageTemplateTypes = () => {
    // booking_prepayment, booking_prepayment_paid, booking_expired_otherclient, booking_reminder_48hs_before, booking_service_done, booking_service_done_with_recommendations, reminder_customer_service_ago
    let result = [
        {
            id: "booking_prepayment",
            name: "Confirmar cita (Link de pago)",
            replacements: [
                {
                    key: "{nombrecliente}",
                    desc: "Nombre del cliente"
                },
                {
                    key: "{citafechahora}",
                    desc: "Fecha y hora de la cita"
                },
                {
                    key: "{citaservicio}",
                    desc: "Servicio de la cita"
                },
                {
                    key: "{linkpago}",
                    desc: "Enlace de pago"
                }
            ]
        },
        {
            id: "booking_prepayment_paid",
            name: "Cita confirmada/Pago realizado (Link de pago)",
            replacements: [
                {
                    key: "{nombrecliente}",
                    desc: "Nombre del cliente"
                },
                {
                    key: "{citafechahora}",
                    desc: "Fecha y hora de la cita"
                },
                {
                    key: "{citaservicio}",
                    desc: "Servicio de la cita"
                },
                {
                    key: "{linkventa}",
                    desc: "Enlace de citas/venta"
                }
            ]
        },
        {
            id: "booking_expired_otherclient",
            name: "Cita confirmada por otro cliente",
            replacements: [
                {
                    key: "{nombrecliente}",
                    desc: "Nombre del cliente"
                },
                {
                    key: "{citafechahora}",
                    desc: "Fecha y hora de la cita"
                },
                {
                    key: "{citaservicio}",
                    desc: "Servicio de la cita"
                },
                {
                    key: "{linkventa}",
                    desc: "Enlace para modificar cita/venta"
                }
            ]
        },
        {
            id: "booking_reminder_48hs_before",
            name: "Recordatorio 48hs antes de la cita",
            replacements: [
                {
                    key: "{nombrecliente}",
                    desc: "Nombre del cliente"
                },
                {
                    key: "{citafechahora}",
                    desc: "Fecha y hora de la cita"
                },
                {
                    key: "{citaservicio}",
                    desc: "Servicio de la cita"
                },
                {
                    key: "{linkventa}",
                    desc: "Enlace de citas/venta"
                },
                {
                    key: "{dia}",
                    desc: "Día de las citas"
                },
                {
                    key: "{citasdeldia}",
                    desc: "Lista de citas del día con enlace"
                }
            ]
        },
        {
            id: "booking_service_done",
            name: "Servicio realizado",
            replacements: [
                {
                    key: "{nombrecliente}",
                    desc: "Nombre del cliente"
                },
                {
                    key: "{citafechahora}",
                    desc: "Fecha y hora de la cita"
                },
                {
                    key: "{citaservicio}",
                    desc: "Servicio de la cita"
                }
            ]
        },
        {
            id: "booking_service_done_with_recommendations",
            name: "Servicio realizado (Con recomendaciones 24hs)",
            replacements: [
                {
                    key: "{nombrecliente}",
                    desc: "Nombre del cliente"
                },
                {
                    key: "{citafechahora}",
                    desc: "Fecha y hora de la cita"
                },
                {
                    key: "{citaservicio}",
                    desc: "Servicio de la cita"
                },
                {
                    key: "{recomendaciones}",
                    desc: "Recomendaciones para después del tratamiento"
                }
            ]
        },
        {
            id: "reminder_customer_service_ago",
            name: "Recordatorio de X tiempo del último servicio",
            replacements: [
                {
                    key: "{nombrecliente}",
                    desc: "Nombre del cliente"
                },
                {
                    key: "{ultimacitafechahora}",
                    desc: "Fecha y hora de la cita anterior"
                },
                {
                    key: "{citaservicio}",
                    desc: "Servicio de la cita"
                },
                {
                    key: "{hacetiempo}",
                    desc: "Hace x tiempo de la cita anterior"
                }
            ]
        }
    ];
    return result;
};
export const getMessageTemplateTypeDesc = ( id ) => {
    let result = "";
    const found = getMessageTemplateTypes().find( tmp => tmp.id === id );
    if ( found && found.id ) {
        result = found.name;
    }
    return result;
};
export const getPromoCodeTypes = () => {
    let promoCodeTypes = [];
    promoCodeTypes.push( {
        id: "global",
        name: "Promo Global"
    } );
    promoCodeTypes.push( {
        id: "customer",
        name: "Exclusivo de 1 cliente"
    } );
    promoCodeTypes.push( {
        id: "multiple_customers",
        name: "Selección de clientes"
    } );
    promoCodeTypes.push( {
        id: "service_category",
        name: "Selección de categorías de servicios"
    } );
    promoCodeTypes.push( {
        id: "shopitem_category",
        name: "Selección de categorías de productos"
    } );
    promoCodeTypes.push( {
        id: "custom",
        name: "Personalizado"
    } );
    return promoCodeTypes;
};
export const getPromoCodeTypeName = ( id ) => {
    let result = "";
    let type = getPromoCodeTypes().find( tmp => tmp.id === id );
    if ( type ) {
        result = type.name;
    }
    return result;
};
export const getPromoCodeStatusName = ( code ) => {
    let result = code;
    switch ( code ) {
        case "active":
            result = "Activo";
            break;
        case "inactive":
            result = "Desactivado";
            break;
        case "expired":
            result = "Expirado";
            break;
        default:
            break;
    }
    return result;
};

export const hasPrivileges = ( action, auxData, sessionData ) => {
    let result = false;
    if ( sessionData.manager.userPermissionRole === "administrator" ) {
        return true;
    }
    switch ( action ) {
        case "calendar.booking.reminder.new": {
            if ( sessionData.manager.capabilities.calendarFullAccess ) {
                return true;
            } else if ( auxData.booking && auxData.booking.bookingTeamMemberId === sessionData.manager.userTeamMemberId ) {
                // this one also deny the posibility to move resources or my own booking to another team member agenda
                result = true;
            }
            break;
        }
        case "calendar.event.new":
        case "calendar.event.move": {
            if ( sessionData.manager.capabilities.calendarFullAccess ) {
                return true;
            }
            if ( action === "calendar.event.new" && auxData.event && auxData.event.resourceId === sessionData.manager.userTeamMemberId ) {
                // new event in my agenda
                return true;
            }
            let currentEvent = auxData.event.event;
            let newEvent = auxData.event;
            if ( action === "calendar.event.move" && auxData.event.resourceId === sessionData.manager.userTeamMemberId && newEvent.resourceId === currentEvent.resourceId ) {
                // moving my own events to my own agenda
                return true;
            }
            break;
        }
        case "calendar.quickactions.booking.view":
        case "calendar.booking.salas.modify":
        case "calendar.booking.generic.modify":
        case "calendar.booking.salecard.view":
        case "calendar.booking.status.modify": {
            if ( sessionData.manager.capabilities.calendarFullAccess ) {
                return true;
            }
            if ( auxData.booking && auxData.booking.bookingTeamMemberId === sessionData.manager.userTeamMemberId ) {
                // only owner
                return true;
            }
            break;
        }
        case "calendar.booking.salas.view": {
            if ( sessionData.manager.capabilities.enableSalas ) {
                result = true;
            }
            break;
        }
        case "sales.modal.view": {
            if ( sessionData.manager.capabilities.manageSales ) {
                result = true;
            }
            break;
        }
        case "messagestemplates.new": {
            result = sessionData.manager.capabilities.manageMessagesTemplates;
            if ( sessionData.manager.capabilities.onlyOwnMessagesTemplates ) {
                // user that can only modify their own, can't create new ones
                result = false;
            }
            break;
        }
        case "messagestemplates.listitem.view": {
            result = sessionData.manager.capabilities.manageMessagesTemplates;
            if ( sessionData.manager.capabilities.onlyOwnMessagesTemplates && auxData.messageTemplate.messageTemplateTeamMemberId !== sessionData.manager.userTeamMemberId ) {
                result = false;
            }
            break;
        }
        case "messagestemplates.edit.active":
        case "messagestemplates.edit.type":
        case "messagestemplates.edit.teammember": {
            result = sessionData.manager.capabilities.manageMessagesTemplates;
            if ( sessionData.manager.capabilities.onlyOwnMessagesTemplates ) {
                result = false;
            }
            break;
        }
        default: {
            result = false;
            break;
        }
    }
    return result;
};
