const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    planId: null,
    stripePlanId: null,
    stripeSkuId: null,
    errorMessage: ""
}, action ) => {
    switch ( action.type ) {
        case "CHANGE_CHECKOUT_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        default: return state;
    }
};
