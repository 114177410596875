const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    dataLoading: false,
    dataErrorMessage: false,
    items: [],
    sales: {
        current: null,
        saleBookings: []
    }
}, action ) => {
    switch ( action.type ) {
        case "CONTENT_DATA_FETCH_ATTEMPT": {
            return update( state, {
                dataLoading: true,
                dataErrorMessage: ""
            } );
        }
        case "CONTENT_DATA_FETCH_ERROR": {
            return update( state, {
                dataLoading: false,
                dataErrorMessage: action.errorMessage
            } );
        }
        case "CONTENT_DATA_FETCH_SUCCESS": {
            return update( state, update(
                {
                    dataLoading: false,
                    dataErrorMessage: ""
                },
                action.data
            ) );
        }
        case "CHANGE_CONTENT_SALES_ATTR": {
            return update( state, { sales: update( state.sales, { [ action.key ]: action.value } ) } );
        }
        case "PROCESS_PAYMENT_LINK_ERROR": {
            return update( state, { paymentLinks: update( state.paymentLinks, { errorMessage: action.errorMessage } ) } );
        }
        default: return state;
    }
};
