import { contactSendPost, contactSendCustomPost } from "../api";
import * as Pages from "../utils/Pages";

export const contactSend = () => ( dispatch, getState ) => {
    dispatch( { type: "CONTACT_SEND_START" } );
    const { contact: data } = getState();
    let promise = null;
    if ( data.formId ) {
        promise = contactSendCustomPost( data );
    } else {
        promise = contactSendPost( data.contactName, data.contactEmail, data.contactPhone, data.contactMessage, data.reCaptchaToken );
    }
    promise
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                if ( typeof window !== "undefined" && typeof window.gtag === "function" ) {
                    if ( Pages.gtag && Pages.gtag.contactOK ) {
                        window.gtag( Pages.gtag.contactOK.type, Pages.gtag.contactOK.eventName, { send_to: Pages.gtag.contactOK.sendTo } );
                    }
                }
                if ( Pages.company.contactOKRedirectTo && typeof window !== "undefined" ) {
                    window.location.href = Pages.company.contactOKRedirectTo;
                } else {
                    dispatch( { type: "CONTACT_SEND_OK" } );
                }
            } else {
                dispatch( { type: "CONTACT_SEND_ERROR" } );
            }
        } )
        .catch( () => dispatch( { type: "CONTACT_SEND_ERROR" } ) );
};

export const contactOnChange = ( mutation ) => ( { type: "CONTACT_ON_CHANGE", mutation } );
