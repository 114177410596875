import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";
import { getPage } from "../../utils/Pages";
import * as DataUtils from "../../utils/DataUtils";
import { loadUIContext, acceptCookies } from "../../actions/UIContextActions";
import { contactSend, contactOnChange } from "../../actions/ContactActions";
import Styles from "../styles/Global";

class CookiesAcceptance extends React.Component {
    constructor ( props ) {
        super( props );
        this.cookies = new Cookies();
        this.state = {
            acceptCookies: this.cookies.get( "acceptCookies" )
        };
    }
    componentDidMount() {
        this.props.loadUIContext();
    }
    render () {
        if ( !this.props.UIContext.loaded || this.state.acceptCookies ) {
            return [];
        }
        const buttonLeftMargin = this.props.UIContext.windowWidth < 600 ? "0" : "10px";
        return (
            <div style={ Styles.CookiesAcceptance.AcceptCookies } >
                Utilizamos cookies propias y de terceros para mejorar nuestros servicios, elaborar información estadística y analizar sus hábitos de navegación. Esto nos permite personalizar el contenido que ofrecemos y mostrarle publicidad relacionada con sus preferencias. Al clickar en &quot;Entendido&quot;, acepta su uso. También puede &quot;Configurar&quot; o &quot;Rechazar&quot; la instalación de Cookies. Para <a href={ getPage( "cookies-policy" ).relativeUrl } style={ Styles.CookiesAcceptance.Link } >MÁS INFORMACIÓN, pulse aquí</a>.
                <Button
                    href={ `${ getPage( "cookies-policy" ).relativeUrl }#configcookies` }
                    size="sm"
                    variant="secondary"
                    style={ {
                        display: "inline-block",
                        padding: "2px 4px",
                        lineHeight: "16px",
                        marginLeft: buttonLeftMargin
                    } }
                >
                    Configurar/Rechazar
                </Button>
                <Button
                    onClick={ () => {
                        this.cookies.set( "acceptCookies", true, { path: "/", expires: DataUtils.nowAddDays( 365 ) } );
                        this.setState( { acceptCookies: true } );
                        this.props.acceptCookies();
                        ReactGA.event( { category: "User", action: "Cookies Acceptance Click" } );
                    } }
                    size="sm"
                    style={ {
                        display: "inline-block",
                        padding: "2px 4px",
                        lineHeight: "16px",
                        marginLeft: buttonLeftMargin
                    } }
                >
                    Entendido, acepto su uso
                </Button>
            </div> );
    }
}

const mapStateToProps = ( state ) => ( {
    contact: state.contact,
    UIContext: state.UIContext
} );

const mapDispatchToProps = {
    loadUIContext,
    contactSend,
    contactOnChange,
    acceptCookies
};

export default connect( mapStateToProps, mapDispatchToProps )( CookiesAcceptance );
