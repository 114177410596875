/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isEditingChild: false,
    isEditingResource: false,
    isLoading: false,
    isLoadingChild: false,
    isError: false,
    isErrorChild: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    errorMessageChild: "",
    editing: {},
    editingResource: {},
    editingChild: {},
    teamItems: [],
    servicesItems: [],
    teamFeeSchemasItems: [],
    bonosItems: [],
    resourcesItems: [],
    toastMessages: [],
    servicesCategoriesItems: [],
    consentsItems: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_TEAM_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_TEAM_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_TEAM_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                teamItems: action.items
            } );
        }
        case "MANAGER_TEAM_FEE_SCHEMA_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                teamFeeSchemasItems: action.items
            } );
        }
        case "MANAGER_TEAM_MEMBER_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.customer
            } );
        }
        case "MANAGER_TEAM_MEMBER_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_TEAM_MEMBER_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_TEAM_MEMBER_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_TEAM_MEMBER_NEW_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_TEAM_MEMBER_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_TEAM_MEMBER_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_TEAM_MEMBER_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_TEAM_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_TEAM_MEMBER_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_TEAM_RESOURCE_CHANGE_EDIT_ATTR": {
            return update( state, { editingResource: update( state.editingResource, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_TEAM_MEMBER_CHANGE_EDIT_CHILD_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_TEAM_FEE_SCHEMA_NEW": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_TEAM_FEE_SCHEMA_TARGET_NEW": {
            return update( state, {
                isEditingChild: true,
                isErrorChild: false,
                isLoadingChild: false,
                errorMessageChild: "",
                editingChild: action.editingChild,
                editing: action.editing
            } );
        }
        case "MANAGER_TEAM_FEE_SCHEMA_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_TEAM_FEE_SCHEMA_CHILD_CHANGE_EDIT_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_SERVICES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                servicesItems: action.items
            } );
        }
        case "MANAGER_SERVICES_CATEGORIES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                servicesCategoriesItems: action.items
            } );
        }
        case "MANAGER_RESOURCES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                resourcesItems: action.items
            } );
        }
        case "MANAGER_BONOS_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                bonosItems: action.items
            } );
        }
        case "MANAGER_TEAM_EDIT_ITEM":
        case "MANAGER_TEAM_NEW_ITEM": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_TEAM_EDIT_ITEM_CHILD":
        case "MANAGER_TEAM_NEW_ITEM_CHILD": {
            return update( state, {
                isEditingChild: true,
                isErrorChild: false,
                isLoadingChild: false,
                errorMessageChild: "",
                editingChild: action.item
            } );
        }
        case "MANAGER_CONSENTS_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                consentsItems: action.items
            } );
        }
        case "MANAGER_TEAM_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessages: newToastMessages } );
        }
        case "MANAGER_TEAM_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessages: newToastMessages } );
        }
        default: return state;
    }
};
