import React from "react";
import { Container, Toast } from "react-bootstrap";
import { connect } from "react-redux";
import * as Pages from "../../../utils/Pages";
import { loadUIContext } from "../../../actions/UIContextActions";
import * as SessionActions from "../../../actions/SessionActions";

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = Object.assign( { loadUIContext }, SessionActions );

const SessionToastsComponent = ( props ) => {
    if ( !props.pwSession.manager.toastMessages ) {
        return null;
    }
    return (
        <div style={ {
            position: "fixed", top: 0, right: 0, zIndex: 9000
        } }
        >
            { props.pwSession.manager.toastMessages.map( ( toastMessage ) => (
                <Toast
                    show
                    className="pw_session_manager_toast"
                    style={ { backgroundColor: toastMessage.backgroundColor || "#fff3cd" } }
                    onClose={ () => props.managerSessionHideToastMessage( toastMessage ) }
                >
                    <Toast.Header style={ { backgroundColor: toastMessage.backgroundColor || "#fff3cd" } }>
                        <strong className="mr-auto">{ toastMessage.title || "Atención" }</strong>
                    </Toast.Header>
                    <Toast.Body>{ toastMessage.isLangKey ? Pages.text( "es", toastMessage.message ) : <div dangerouslySetInnerHTML={ { __html: toastMessage.message } } /> }</Toast.Body>
                </Toast>
            ) ) }
        </div>
    );
};
const SessionToasts = connect( mapStateToProps, mapDispatchToProps )( SessionToastsComponent );
const Footer = () => (
    <Container fluid id="pws-manager-footer">
        <div>
            Web Manager: Copyright Palma Webs
        </div>
        <SessionToasts />
    </Container>
);
export default connect( mapStateToProps, mapDispatchToProps )( Footer );
