/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isEditingChild: false,
    isLoading: false,
    isLoadingChild: false,
    isError: false,
    isErrorChild: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    errorMessageChild: "",
    editing: {},
    editingChild: {},
    selectedShopItemStock: null,
    items: [],
    shopItemsCategoriesItems: [],
    shopSuppliersItems: [],
    shopBrandsItems: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_SHOP_ITEM_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_SHOP_ITEM_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_SHOP_ITEM_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_SHOP_ITEM_STOCK_FETCH_SUCCESS": {
            return update( state, {
                selectedShopItemStock: action.item
            } );
        }
        case "MANAGER_SHOP_CATEGORIES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                shopItemsCategoriesItems: action.items
            } );
        }
        case "MANAGER_SHOP_SUPPLIERS_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                shopSuppliersItems: action.items
            } );
        }
        case "MANAGER_SHOP_ITEM_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.customer
            } );
        }
        case "MANAGER_SHOP_ITEM_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_SHOP_ITEM_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_SHOP_ITEM_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_SHOP_ITEM_NEW_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_SHOP_BRAND_NEW_SUCCESS":
        case "MANAGER_SHOP_BRAND_UPDATE_SUCCESS":
        case "MANAGER_SHOP_SUPPLIER_NEW_SUCCESS":
        case "MANAGER_SHOP_SUPPLIER_UPDATE_SUCCESS":
        case "MANAGER_SHOP_CATEGORY_NEW_SUCCESS":
        case "MANAGER_SHOP_CATEGORY_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_SHOP_BRANDS_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                shopBrandsItems: action.items
            } );
        }
        case "MANAGER_SHOP_ITEM_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_SHOP_ITEM_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_SHOP_ITEM_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_SHOP_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_SHOP_ITEM_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_SHOP_ITEM_CHILD_CHANGE_EDIT_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_SHOP_ITEM_EDIT_ITEM":
        case "MANAGER_SHOP_ITEM_NEW_ITEM": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        default: return state;
    }
};
