/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isLoading: false,
    isError: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    editing: {},
    items: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_OUTGOINGS_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_OUTGOINGS_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_OUTGOINGS_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_OUTGOINGS_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_OUTGOINGS_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_OUTGOING_SAVE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_OUTGOING_SAVE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: ""
            } );
        }
        case "MANAGER_OUTGOING_SAVE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        default: return state;
    }
};
