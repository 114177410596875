import * as UIContext from "../utils/UIContext";

export const loadUIContext = () => ( dispatch, getState ) => {
    if ( getState().UIContext.loaded ) {
        return;
    }
    dispatch( { type: "UICONTEXT_LOADED", windowWidth: UIContext.getWindowWidth(), windowHeight: UIContext.getWindowHeight() } );
    // window.addEventListener( "resize", () => dispatch( { type: "UICONTEXT_RESIZE", windowWidth: UIContext.getWindowWidth(), windowHeight: UIContext.getWindowHeight() } ) );
    // if ( !options || !options.noScrollEvents ) {
    //     window.addEventListener( "scroll", () => dispatch( { type: "UICONTEXT_SCROLL", scrollY: UIContext.getScrollY() } ) );
    // }
};
export const acceptCookies = () => ( dispatch ) => {
    dispatch( { type: "UICONTEXT_ACCEPT_COOKIES" } );
};
