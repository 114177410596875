/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable complexity */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Fade from "react-reveal/Fade";
import { loadUIContext } from "../../actions/UIContextActions";
import * as SessionActions from "../../actions/SessionActions";
import * as Pages from "../../utils/Pages";
import WebContext from "../../utils/WebContext";
import * as DataUtils from "../../utils/DataUtils";

class DynamicSlider extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            sliders: []
        };
        this.prevCarouselSlide = this.prevCarouselSlide.bind( this );
        this.nextCarouselSlide = this.nextCarouselSlide.bind( this );
        this.setCarouselInterval = this.setCarouselInterval.bind( this );
        this.carouselInterval = null;
    }
    componentDidMount() {
        this.props.loadUIContext( { noScrollEvents: true } );
    }
    setCarouselInterval() {
        if ( this.carouselInterval ) {
            clearInterval( this.carouselInterval );
        }
        this.carouselInterval = setInterval( () => {
            const newSliders = this.state.sliders.map( slider => {
                if ( slider.currentSlide < slider.slides.length - 1 ) {
                    slider.currentSlide += 1;
                } else {
                    slider.currentSlide = 0;
                }
                return slider;
            } );
            this.setState( { sliders: newSliders } );
        }, 10000 );
    }
    prevCarouselSlide( slider ) {
        const prevSlide = slider.currentSlide - 1 < 0 ? slider.slides.length - 1 : slider.currentSlide - 1;
        this.setCarouselInterval();
        this.setState( {
            sliders: this.state.sliders.map( tmpSlider => {
                if ( tmpSlider.id === slider.id ) {
                    tmpSlider.currentSlide = prevSlide;
                }
                return tmpSlider;
            } )
        } );
    }
    nextCarouselSlide( slider ) {
        const nextSlide = slider.currentSlide + 1 < slider.slides.length ? slider.currentSlide + 1 : 0;
        this.setCarouselInterval();
        this.setState( {
            sliders: this.state.sliders.map( tmpSlider => {
                if ( tmpSlider.id === slider.id ) {
                    tmpSlider.currentSlide = nextSlide;
                }
                return tmpSlider;
            } )
        } );
    }
    render() {
        const { content } = this.props;
        let slider = this.state.sliders.find( tmp => tmp.id === content.dynamicContentValue.id );
        if ( !slider || !slider.id ) {
            slider = content.dynamicContentValue;
            if ( !slider.currentSlide ) {
                slider.currentSlide = 0;
            }
            const newSliders = this.state.sliders.map( tmp => tmp );
            newSliders.push( Object.assign( {}, slider ) );
            this.setState( { sliders: newSliders } );
            if ( !this.carouselInterval && this.props.UIContext.loaded ) {
                this.setCarouselInterval();
            }
        }
        const showArrows = slider.slides.length > 1;
        const currentSlide = slider.slides[ slider.currentSlide ];
        const leftPaneActive = currentSlide.leftPaneImageUrl || currentSlide.leftPaneTitle || currentSlide.leftPaneDisplayLogo;
        const rightPaneActive = currentSlide.rightPaneImageUrl || currentSlide.rightPaneTitle || currentSlide.rightPaneParagraph || currentSlide.rightPaneDisplayLogo;
        let className = "pw_dyncontent_slider";
        if ( slider.variant ) {
            if ( slider.variant === "16x9" ) {
                className += " pw_variant_fullwidth";
            }
            className += ` pw_variant_${ slider.variant }`;
        } else {
            className += " pw_variant_fullwidth";
        }
        let onClickAction = null;
        let customStyles = null;
        if ( currentSlide.linkToUrl ) {
            onClickAction = () => {
                window.location.href = currentSlide.linkToUrl;
            };
            customStyles = { cursor: "pointer" };
        }
        return (
            <div id={ this.props.containerHtmlId || null } className={ className } style={ customStyles } key="slider">
                { showArrows ? <button className="pw_dyncontent_slider__prev" onClick={ () => this.prevCarouselSlide( slider ) }><IoIosArrowBack /></button> : null }
                { showArrows ? <button className="pw_dyncontent_slider__next" onClick={ () => this.nextCarouselSlide( slider ) }><IoIosArrowForward /></button> : null }
                <TransitionGroup>
                    <CSSTransition
                        style={ currentSlide.imageUrl && !currentSlide.videoUrl ? {
                            backgroundImage: `url(${ DataUtils.getPublicImageUrl( currentSlide.imageUrl ) })`
                        } : null }
                        classNames="blur"
                        className="pw_dyncontent_slider__csstransition"
                        timeout={ 1000 }
                        in
                        key={ slider.currentSlide }
                    >
                        <div className="pw_dyncontent_slider__slide" role="link" href={ currentSlide.linkToUrl || null } tabIndex="-1" onKeyPress={ onClickAction } onClick={ onClickAction }>
                            <div className="pw_dyncontent_slider__slide_content">
                                <div className="pw_dyncontent_slider__slide_content_row">
                                    { leftPaneActive ?
                                        <Fade bottom delay={ 1100 }>
                                            <div className="pw_dyncontent_slider_slide_contentleft">
                                                { currentSlide.leftPaneDisplayLogo ? <img src="/static/carousel-logo-transparente.png" alt={ currentSlide.seoDefaultAlt || Pages.company.domain } title={ currentSlide.seoDefaultAlt || Pages.company.domain } /> : null }
                                                { currentSlide.leftPaneTitle ? <h2 className="pw_dyncontent_slider__h2"> { currentSlide.leftPaneTitle } { currentSlide.leftPaneLink ? <span><br /><a href={ currentSlide.rightPaneLink } className="pw_slide_link">{ currentSlide.leftPaneLinkText } <IoIosArrowForward /></a></span> : null }</h2> : null }
                                            </div>
                                        </Fade> : <div className="pw_dyncontent_slider_slide_contentleft" /> }
                                    { rightPaneActive ?
                                        <Fade bottom delay={ 1000 }>
                                            <div className="pw_dyncontent_slider_slide_contentright">
                                                { currentSlide.rightPaneDisplayLogo ? <img src="/static/carousel-logo-transparente.png" alt={ currentSlide.seoDefaultAlt || Pages.company.domain } title={ currentSlide.seoDefaultAlt || Pages.company.domain } /> : null }
                                                { currentSlide.rightPaneTitle ?
                                                    <div className="pw_dyncontent_slider__h2">
                                                        <div className="pw_dyncontent_slider_title">{ currentSlide.rightPaneTitle }</div>
                                                        { currentSlide.rightPaneParagraph ? <div className="pw_dyncontent_slider_paragraph">{ currentSlide.rightPaneParagraph }</div> : null }
                                                        { currentSlide.rightPaneLink ? <span><a href={ currentSlide.rightPaneLink } className="pw_slide_link">{ currentSlide.rightPaneLinkText } <IoIosArrowForward /></a></span> : null }
                                                    </div> : null }
                                            </div>
                                        </Fade> : <div className="pw_dyncontent_slider_slide_contentright" /> }
                                </div>
                                { currentSlide.videoUrl ? <div className="pw_dyncontent_slider_overlay" role="link" href={ currentSlide.linkToUrl || null } tabIndex="-1" onKeyPress={ onClickAction } onClick={ onClickAction } /> : null }
                                { currentSlide.videoUrl ? <ReactPlayer
                                    style={ {
                                        position: "absolute",
                                        zIndex: 1,
                                        left: 0,
                                        top: 0,
                                        width: "100%",
                                        height: "100%"
                                    } }
                                    url={ currentSlide.videoUrl }
                                    width="100%"
                                    height="100%"
                                    playing
                                    loop
                                    muted
                                /> : null }
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
                <div className="pw_dyncontent_slider_overlay" />
                { Pages.company.showSliderAnimatedBackground ? <ul className="pw_animated_background"><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul> : null }
            </div>
        );
    }
}

DynamicSlider.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );

export default connect( mapStateToProps, mapDispatchToProps )( withRouter( DynamicSlider ) );
