/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable complexity */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import * as Pages from "../../utils/Pages";
import { loadUIContext } from "../../actions/UIContextActions";
import Icon from "../widgets/Icon";
import * as SessionActions from "../../actions/SessionActions";
import WebContext from "../../utils/WebContext";

class NavBarShop extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.shopRelativeUrl = Pages.getPage( "shop" ).relativeUrl;
        this.renderGrouped = this.renderGrouped.bind( this );
    }
    componentDidMount() {
        this.props.loadUIContext();
    }
    renderGroupedOffcanvas( mainCategories ) {
        return (
            <div className="pw_navbar_offcanvas_container">
                { mainCategories.map( argCat => {
                    const cat = Pages.applyShopOverides( argCat );
                    if ( [ "giftcards", "specialprices" ].includes( cat.id ) ) {
                        return (
                            <Nav.Link key={ cat.id } href={ `${ this.shopRelativeUrl }/${ cat.id }` } className={ `pw-navbar-item-${ cat.id }` }>{ cat.iconName ? <Icon name={ cat.iconName } /> : null }{ cat.name }</Nav.Link>
                        );
                    } else if ( ( cat.subCategories && cat.subCategories.length > 0 ) ) {
                        return (
                            <NavDropdown key={ cat.id } className="pw_navbar_offcanvas_firstlevel" title={ cat.name }>
                                { cat.subCategories.map( argSub => {
                                    const sub = Pages.applyShopOverides( argSub );
                                    let subSubCategories = this.props.content.shop.categories.find( tmpCatFilter => tmpCatFilter.id === sub.id ).subCategories;
                                    if ( !subSubCategories || subSubCategories.length === 0 ) {
                                        return ( <NavDropdown.Item key={ sub.id } className="pw_navbar_offcanvas_mainitem" href={ `${ this.shopRelativeUrl }/${ sub.id }` }>{ sub.name }</NavDropdown.Item> );
                                    }
                                    return (
                                        <NavDropdown key={ sub.id } title={ sub.name }>
                                            { this.props.content.shop.categories.find( tmpCatFilter => tmpCatFilter.id === sub.id ).subCategories.map( argSubSub => {
                                                const subSub = Pages.applyShopOverides( argSubSub );
                                                return ( <NavDropdown.Item key={ subSub.id } href={ `${ this.shopRelativeUrl }/${ subSub.id }` }>{ subSub.name }</NavDropdown.Item> );
                                            } ) }
                                        </NavDropdown>
                                    );
                                } ) }
                            </NavDropdown>
                        );
                    }
                    return ( <Nav.Link key={ cat.id } href={ `${ this.shopRelativeUrl }/${ cat.id }` }>{ cat.iconName ? <Icon name={ cat.iconName } /> : null }{ cat.name }</Nav.Link> );
                } ) }
            </div>
        );
    }
    renderGrouped() {
        const giftCardsText = Pages.text( this.context.language, "navbar.shop.giftcards" );
        const mainCategories = [];
        if ( Pages.NavBar && !Pages.NavBar.hideServices ) {
            this.props.content.shop.categories.filter( tmp => tmp.type === "services" && !tmp.parentCategoryId ).forEach( tmp => {
                mainCategories.push( tmp );
            } );
        }
        if ( Pages.NavBar && !Pages.NavBar.hideProducts ) {
            this.props.content.shop.categories.filter( tmp => tmp.type === "products" && !tmp.parentCategoryId ).forEach( tmp => {
                mainCategories.push( tmp );
            } );
        }
        if ( Pages.NavBar && !Pages.NavBar.hideSpecialPrices ) {
            mainCategories.push( {
                id: "specialprices",
                name: Pages.text( this.context.language, "shop.general.specialprices" ),
                relativeUrl: null,
                subCategories: []
            } );
        }
        if ( Pages.NavBar && !Pages.NavBar.hideGiftCards ) {
            mainCategories.push( {
                id: "giftcards",
                name: giftCardsText || "Tarjeta Regalo",
                iconName: "gift",
                relativeUrl: null,
                subCategories: []
            } );
        }
        if ( this.props.type === "offcanvas" ) {
            return this.renderGroupedOffcanvas( mainCategories );
        }
        return (
            <div className="pw-multilevel-navbar">
                <div className="pw-multilevel-navbar-content">
                    { mainCategories.map( argCat => {
                        const cat = Pages.applyShopOverides( argCat );
                        if ( [ "giftcards" ].includes( cat.id ) ) {
                            return (
                                <div key={ cat.id } className="pw-multilevel-dropdown pw-multilevel-main-item">
                                    <a className="pw-multilevel-dropbtn" href={ `${ this.shopRelativeUrl }/${ cat.id }` }>{ cat.iconName ? <Icon name={ cat.iconName } /> : null }{ cat.name }
                                        <i className="fa fa-caret-down" />
                                    </a>
                                </div> );
                        } else if ( ( cat.subCategories && cat.subCategories.length > 0 ) ) {
                            return (
                                <div key={ cat.id } className="pw-multilevel-dropdown pw-multilevel-main-item">
                                    <button className="pw-multilevel-dropbtn">{ cat.name }
                                        <i className="fa fa-caret-down" />
                                    </button>
                                    <div className="pw-multilevel-dropdown-content">
                                        <div className="pw-multilevel-row">
                                            { cat.subCategories.map( argSub => {
                                                if ( argSub.resellersOnly && !this.props.pwSession.userIsReseller ) {
                                                    // return null;
                                                }
                                                const sub = Pages.applyShopOverides( argSub );
                                                return (
                                                    <div key={ sub.id } className="pw-multilevel-column">
                                                        <h3><a href={ `${ this.shopRelativeUrl }/${ sub.id }` } className="pw-multilevel-header-link">{ sub.name }</a></h3>
                                                        { this.props.content.shop.categories.find( tmpCatFilter => tmpCatFilter.id === sub.id ).subCategories.map( argSubSub => {
                                                            const subSub = Pages.applyShopOverides( argSubSub );
                                                            return ( <a key={ subSub.id } href={ `${ this.shopRelativeUrl }/${ subSub.id }` }>{ subSub.name }</a> );
                                                        } ) }
                                                    </div> );
                                            } ) }
                                        </div>
                                    </div>
                                </div> );
                        }
                        // if ( !cat.totalItems ) {
                        //     return null;
                        // }
                        return ( <a key={ cat.id } className="pw-multilevel-main-item" href={ `${ this.shopRelativeUrl }/${ cat.id }` }>{ cat.name }</a> );
                    } ) }
                </div>
            </div>
        );
    }
    render() {
        if ( Pages.NavBar.showShopCategoriesMainGroups ) {
            return this.renderGrouped();
        }
        const mainCategories = this.props.content.shop.categories.filter( cat1 => {
            let visible = false;
            if ( cat1.level === 1 && ( cat1.active || cat1.active === undefined ) ) {
                visible = true;
            }
            if ( cat1.resellersOnly && !this.props.pwSession.userIsReseller ) {
                // visible = false;
            }
            return visible;
        } ).splice( 0, 20 );
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < 991 ) {
            // return null;
        }
        return (
            <div className="pw-multilevel-navbar">
                <div className="pw-multilevel-navbar-content">
                    { mainCategories.map( argCat => {
                        const cat = Pages.applyShopOverides( argCat );
                        if ( cat.subCategories && cat.subCategories.length > 0 ) {
                            return (
                                <div key={ cat.id } className="pw-multilevel-dropdown pw-multilevel-main-item">
                                    <button className="pw-multilevel-dropbtn">{ cat.name }
                                        <i className="fa fa-caret-down" />
                                    </button>
                                    <div className="pw-multilevel-dropdown-content">
                                        <div className="pw-multilevel-header">
                                            <a className="pw-multilevel-header-link" href={ `${ this.shopRelativeUrl }/${ cat.id }` } >{ cat.name }</a>
                                        </div>
                                        <div className="pw-multilevel-row">
                                            { cat.subCategories.map( argSub => {
                                                const sub = Pages.applyShopOverides( argSub );
                                                return (
                                                    <div key={ sub.id } className="pw-multilevel-column">
                                                        <h3><a href={ `${ this.shopRelativeUrl }/${ sub.id }` } className="pw-multilevel-header-link">{ sub.name }</a></h3>
                                                        { this.props.content.shop.categories.find( tmpCatFilter => tmpCatFilter.id === sub.id ).subCategories.map( argSubSub => {
                                                            const subSub = Pages.applyShopOverides( argSubSub );
                                                            return ( <a key={ subSub.id } href={ `${ this.shopRelativeUrl }/${ subSub.id }` }>{ subSub.name }</a> );
                                                        } ) }
                                                    </div> );
                                            } ) }
                                        </div>
                                    </div>
                                </div> );
                        }
                        // if ( !cat.totalItems ) {
                        //     return null;
                        // }
                        return ( <a key={ cat.id } className="pw-multilevel-main-item" href={ `${ this.shopRelativeUrl }/${ cat.id }` }>{ cat.name }</a> );
                    } ) }
                </div>
            </div>
        );
    }
}

NavBarShop.contextType = WebContext;

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    pwSession: state.pwSession,
    content: state.content
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, SessionActions );
export default connect( mapStateToProps, mapDispatchToProps )( withRouter( NavBarShop ) );
