import { matchPath } from "react-router-dom";
import GenericPage from "../../components/GenericPage";

import Home from "../../components/Home";
import store from "../../store";

// eslint-disable-next-line no-undef
export const ENVIRONMENT = typeof WEBPACK_ENVIRONMENT === "undefined" ? process.env.NODE_ENV : WEBPACK_ENVIRONMENT;

export const PageComponents = {};
PageComponents.PrivacyPolicy = require( "./components/PrivacyPolicy" ).default;
PageComponents.ServiceConditions = require( "./components/ServiceConditions" ).default;
PageComponents.CookiesPolicy = require( "./components/CookiesPolicy" ).default;

export const getUrlBase = ( env ) => {
    if ( env === "development" ) {
        return "http://localhost:2048";
    }
    if ( env === "staging" ) {
        return "http://staging.ayunabeautymallorca.com";
    }
    return "https://ayunabeautymallorca.com";
};

export const company = {
    name: "Ayuna Beauty Mallorca",
    legalName: "Khanda Kanatkalieva",
    domain: "ayunabeautymallorca.com",
    lopdFullName: "Khanda Kanatkalieva",
    lopdNIF: "X8827150A",
    lopdEmail: "kanatkalieva@gmail.com",
    lopdPostalAddress: "",
    lopdPhone: "",
    facebookPageURL: "https://www.facebook.com/ayunabeautymallorca",
    instagramPageURL: "https://www.instagram.com/ayunabeautymallorca",
    whatsappContactURL: "https://wa.me/34664490138",
    googleFontsTag: "<link href=\"https://fonts.googleapis.com/css?family=Alfa+Slab+One|Montserrat:300,400,500,600,700|Quicksand|Roboto+Mono:300&display=swap\" rel=\"stylesheet\" />",
    googleAnalyticsTrackingID: "",
    facebookAPPID: "348655195810991",
    contactEmail: "kanatkalieva@gmail.com",
    managerURLRelative: "/ayunabeautyonline",
    googleSignInClientID: "",
    baseURL: getUrlBase( ENVIRONMENT ),
    smartlookCode: "",
    cliengoScriptUrl: "",
    ZohoChatSalesIQCode: null,
    facebookPixelId: "",
    dashboardURLRelative: "/dashboard",
    modSignupEnabled: false,
    shopCart: false,
    modSignupFacebookEnabled: false,
    dashboardModules: [ "welcome", "questions", "myplan" ],
    adultsOnlyPage: false,
    footerHideFacebook: true,
    footerDisplaySocialIcons: true,
    footerHideContactButtons: true,
    googleTagManagerId: "G-JVR9X1DEJP"
};

export const gtag = null;

export const stripe = null;

export const pagantis = { enabled: false };

export const config = {
    reCaptchaSiteKey: "6LeqmKoUAAAAAPBWBD8aQmVM9XIc4qNQgsjGHdFJ"
};

export const NavBar = {
    logoHeight: 72,
    topNavBar: {
        enabled: true,
        instagramLink: company.instagramPageURL,
        facebookLink: company.facebookPageURL,
        phoneText: "664 49 01 38",
        phoneLink: "tel:+34664490138",
        sendMessageText: "Mensaje",
        whatsappLink: "https://wa.me/34664490138",
        whatsappText: "WhatsApp"
    },
    customMenuItems: [
        {
            id: "about-us",
            menuText: "CONÓCENOS",
            relativeUrl: "/#c943ffb0-88f3-11ec-aa3a-81e39feed796-0Rp8"
        },
        {
            id: "centro",
            menuText: "CENTRO",
            relativeUrl: "/#21d77990-88f4-11ec-aa3a-81e39feed796-JW0n"
        },
        {
            id: "tratamientos",
            menuText: "TRATAMIENTOS",
            relativeUrl: "/#3f4d48b0-88f4-11ec-aa3a-81e39feed796-IkE4"
        },
        {
            id: "productos",
            menuText: "PRODUCTOS",
            relativeUrl: "/#products"
        },
        {
            id: "cancellation",
            menuText: "CANCELACIONES",
            relativeUrl: "/p/politica-cancelaciones"
        },
        {
            id: "contacto",
            menuText: "CONTACTO",
            relativeUrl: "/#contacto"
        }
    ]
};

export const all = [
    {
        path: "(/|/en/|/es/)",
        relativeUrl: "/",
        relativeUrlEn: "/en",
        url: "https://ayunabeautymallorca.com",
        component: Home,
        exact: true,
        id: "home",
        menuText: "Inicio",
        menuTextEn: "Home",
        menuHidden: true,
        title: "Inicio",
        titleEn: "Home",
        subTitle: "Ayuna Beauty en Mallorca te ofrece tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA",
        subTitleEn: "Ayuna Beauty en Mallorca te ofrece tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA",
        breadcrumb: [],
        seoTitle: "Ayuna Beauty en Mallorca | Tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA",
        seoTitleEn: "Ayuna Beauty en Mallorca | Tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA",
        seoDescription: "Ayuna Beauty en Mallorca te ofrece tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA. Amabilidad, una actitud sensible hacia cada cliente y un alto nivel de profesionalismo.",
        seoKeywords: "Ayuna Beauty en Mallorca te ofrece tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA. Amabilidad, una actitud sensible hacia cada cliente y un alto nivel de profesionalismo.",
        seoAuthor: "Ayuna Beauty en Mallorca",
        seoDefaultAlt: "Ayuna Beauty en Mallorca | Tratamientos avanzados en cuidado facial y corporal",
        embeds: [],
        navBarCSSPosition: "fixed",
        gallery: {}
    },
    {
        path: "/politica-privacidad",
        relativeUrl: "/politica-privacidad",
        url: "https://ayunabeautymallorca.com/politica-privacidad",
        exact: true,
        component: GenericPage,
        childComponentFile: "PrivacyPolicy",
        id: "privacy-policy",
        menuText: "Política de privacidad",
        menuTextEn: "Privacy Policy",
        menuHidden: true,
        title: "Política de privacidad",
        subTitle: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        breadcrumb: [ "home", "privacy-policy" ],
        seoTitle: "Política de privacidad",
        seoDescription: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        seoKeywords: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/politica-cookies",
        relativeUrl: "/politica-cookies",
        url: "https://ayunabeautymallorca.com/politica-cookies",
        exact: true,
        component: GenericPage,
        childComponentFile: "CookiesPolicy",
        id: "cookies-policy",
        menuText: "Política de Cookies",
        menuTextEn: "Cookies Policy",
        menuHidden: true,
        title: "Política de Cookies",
        subTitle: "",
        breadcrumb: [ "home", "cookies-policy" ],
        seoTitle: "",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "(/p/.*)",
        relativeUrl: "/p",
        url: "https://ayunabeautymallorca.com/p",
        component: GenericPage,
        id: "pages",
        menuText: "",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: [],
        embeds: [],
        seoTitle: "Ayuna Beauty en Mallorca | Novedades en tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA",
        seoTitleEn: "Ayuna Beauty en Mallorca | Novedades en tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA",
        seoDescription: "Ayuna Beauty en Mallorca te ofrece tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA. Amabilidad, una actitud sensible hacia cada cliente y un alto nivel de profesionalismo.",
        seoKeywords: "Ayuna Beauty en Mallorca te ofrece tratamientos avanzados en cuidado facial y corporal, masajes y tratamientos SPA. Amabilidad, una actitud sensible hacia cada cliente y un alto nivel de profesionalismo.",
        seoAuthor: "Ayuna Beauty en Mallorca",
        seoDefaultAlt: "Ayuna Beauty en Mallorca | Novedades en tratamientos avanzados en cuidado facial y corporal"
    }
];
const getPagePropByLang = ( page, lang, prop ) => {
    const langProp = `${ prop }${ lang.charAt( 0 ).toUpperCase() + lang.slice( 1 ) }`;
    if ( typeof page[ langProp ] !== "undefined" && page[ langProp ] ) {
        return page[ langProp ];
    }
    return page[ prop ];
};

const applyPageOverwrites = ( page ) => {
    let result = page;
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            result = Object.assign( {}, page, { title: getPagePropByLang( page, lang, "title" ) } );
            Object.keys( page ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( page, lang, key );
            } );
        }
    }
    return result;
};

export const getPage = ( id ) => {
    let result = all.find( ( page ) => page.id === id );
    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

const texts = {
    es: {
        "navbar.title": "",
        "footer.contact.text1": "¿Necesitas más información?",
        "footer.contact.text2": "Déjame un mensaje comentándome acerca de tu caso y te contestaré a la brevedad",
        "footer.contact.fullname": "Nombre Completo",
        "footer.contact.fullname.description": "",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "",
        "footer.contact.phone": "Teléfono",
        "footer.contact.phone.description": "",
        "footer.contact.message": "Mensaje",
        "footer.contact.privacypolicy": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y los <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span>` : "" }`,
        "footer.contact.sendmessage": "Enviar consulta",
        "footer.contact.orremember": "O recuerda que también puede contactarnos a través de",
        "footer.contact.emailbutton": "Correo electrónico",
        "footer.contact.whatsapp": "Enviar un WhatsApp",
        "footer.contact.visitfacebook": "Visítanos en Facebook",
        "footer.contact.successmessage.text1": "Gracias por ponerte en contacto con nosotros.",
        "footer.contact.successmessage.text2": "Hemos recibido tu mensaje y te responderemos a la brevedad a",
        "footer.contact.successmessage.text3": "En {companyName} nos tomamos muy en serio las consultas de nuestros usuarios. Muchas gracias por confiar en nosotros.",
        "footer.copyright.text1": "Copyright Ayuna Beauty Mallorca",
        "navbar.search": "Buscar",
        // news
        "news.categories": "Categorías",
        "news.viewmore": "Ver más",
        // customs
        // mg
        "mg.generic.validation.empty": "Debes completar todos los datos para continuar",
        "mg.generic.validation.invalidemail": "El email ingresado no es válido",
        "mg.customers.status.active": "Cliente",
        "mg.customers.status.inactive": "Desactivado",
        "mg.invoices.status.editing": "Editando",
        "mg.invoices.status.sent": "Enviada",
        "mg.invoices.status.paid": "Pagada",
        "mg.courses.status.editing": "En edición",
        "mg.courses.status.active": "Publicado",
        "mg.courses.status.inactive": "Desactivado",
        "mg.team.status.active": "Activo",
        "mg.team.status.inactive": "Desactivado"
    },
    en: {
        "navbar.title": "",
        "footer.contact.text1": "Any further questions?",
        "footer.contact.text2": "Please do not hesitate to contact us, tell us about your business or project and We will reply as soon as possible.",
        "footer.contact.fullname": "Full name",
        "footer.contact.fullname.description": "Please enter your full name",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Enter your email to reply as soon as possible.",
        "footer.contact.phone": "Phone",
        "footer.contact.phone.description": "Enter your phone and we can contact you via Whatsapp or a call.",
        "footer.contact.message": "Message",
        "footer.contact.privacypolicy": `I have read and accept the <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Privacy Policy</a>${ company.footerTermsConditions ? `<span> and the <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>` : "" }`,
        "footer.contact.sendmessage": "Send message",
        "footer.contact.orremember": "Or remember you can also send us a message via",
        "footer.contact.emailbutton": "Email",
        "footer.contact.whatsapp": "Send WhatsApp",
        "footer.contact.visitfacebook": "Visit us on Facebook",
        "footer.contact.successmessage.text1": "Thank you to contact us",
        "footer.contact.successmessage.text2": "Your message has been successfully sent and we´ll reply you as soon as possible.",
        "footer.contact.successmessage.text3": "At {companyName} we work hard to reply our customers messages in a professional way. Thank you for your patience. ",
        "footer.copyright.text1": "Copyright Ayuna Beauty Mallorca",
        // news
        "news.categories": "Categories",
        "news.viewmore": "View more"
        // customs
    }
};

export const defaultLanguage = "es";
export const availableLanguages = [ "es" ];

// GENERIC
export const getPageByPath = ( path ) => {
    let result = all.find( ( page ) => {
        if ( page.path === path ) {
            return page;
        }
        if ( matchPath( path, page ) ) {
            return page;
        }
        return null;
    } );

    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

export const manager = {};
manager.getRelativeUrl = ( relative ) => `${ company.managerURLRelative }/${ relative }`;

export const text = ( lang, id, replacements ) => {
    let result = "";
    if ( texts[ lang ] && texts[ lang ][ id ] ) {
        result = texts[ lang ][ id ];
    } else if ( texts.es && texts.es[ id ] ) {
        result = texts.es[ id ];
    }
    if ( typeof replacements !== "undefined" ) {
        Object.keys( replacements ).forEach( key => {
            result = result.replace( new RegExp( `{${ key }}`, "g" ), replacements[ key ] );
        } );
    }
    return result;
};

export const getLanguageName = ( lang ) => {
    switch ( lang ) {
        case "en":
            return "English";
        case "es":
            return "Español";
        default:
            return "Español";
    }
};

export const getLangByUrlPath = ( path ) => {
    let result = defaultLanguage;
    availableLanguages.forEach( ( lang ) => {
        if ( path.indexOf( `/${ lang }/` ) > -1 ) {
            result = lang;
        }
    } );
    return result;
};

export const applyShopOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const applyNewsOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const contactForms = null;
// Example:
// export const floatingVideos = [
//     {
//         relativeUrl: "/",
//         exactUrl: true,
//         src: "https://www.youtube.com/embed/5MTYDYkGxP4",
//         provider: "youtube",
//         sendMessage: true,
//         displayScrollY: 0,
//         disableAutoplay: true
//     }
// ];
export const floatingVideos = null;
export const emailTemplates = {
    theme: {
        logoBackgroundColor: "white",
        logoUrl: `${ company.baseURL }/static/logo-rectangle.png`,
        thanksBackgroundColor: "#F8F8F8",
        buttonBackgroundColor: "#fbc965",
        thanksTitleColor: "#333333",
        buttonTextColor: "black",
        purchaseDetailsUrl: `${ company.baseURL }/shop/purchase`,
        successBackgroundColor: "#333333",
        successTextColor: "#333333",
        defaultFont: "Arial",
        defaultFontColor: "#333333",
        purchaseThanksTitle: "Muchas gracias por su compra",
        purchaseThanksSubTitle: "Por favor, conserve la siguiente información.",
        footerText: `Para cualquier información no dude en contactarnos a través de nuestra web ${ company.baseURL }`
    }
};

// export const floatingActionButtons = [
//     {
//         relativeUrl: "/",
//         exactUrl: true,
//         src: "/static/floating-whatsapp.png",
//         displayScrollY: 0,
//         href: company.whatsappContactURL
//     }
// ];
export const floatingActionButtons = null;
