import * as DataUtils from "./utils/DataUtils";
import * as ManagerUtils from "./utils/ManagerUtils";

export function contactSendPost ( contactName, contactEmail, contactPhone, contactMessage, reCaptchaToken ) {
    return fetch( "/api/lite/contact", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( {
            contactName,
            contactEmail,
            contactPhone,
            contactMessage,
            reCaptchaToken
        } )
    } );
}

export function contactSendCustomPost ( data ) {
    return fetch( "/api/lite/contact", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( data )
    } );
}

export function apiLogin ( body ) {
    return fetch( "/api/lite/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( body )
    } )
        .then( ( res ) => res.json() );
}
export function apiForgot ( body ) {
    return fetch( "/api/lite/forgot", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( body )
    } )
        .then( ( res ) => res.json() );
}
export function apiReset ( body ) {
    return fetch( "/api/lite/reset", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( body )
    } )
        .then( ( res ) => res.json() );
}
export function apiManagerLogin ( userEmail, userPass, acceptPrivacyAndTerms ) {
    return fetch( "/api/manager/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( {
            userEmail,
            userPass,
            acceptPrivacyAndTerms
        } )
    } );
}
export function apiProcessPurchase ( shopAttributes, shopData ) {
    const token = DataUtils.getToken();
    return fetch( "/api/lite/shop/process", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( {
            shopAttributes,
            shopData,
            token
        } )
    } );
}
export function apiSendWebUserMessage ( message ) {
    const token = DataUtils.getToken();
    if ( !token ) {
        return false;
    }
    return fetch( "/api/lite/webuser/message", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ token }`
        },
        body: JSON.stringify( message )
    } );
}

export function apiPurchaseDetails ( shopAttributes ) {
    return fetch( "/api/lite/shop/details", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( {
            shopAttributes
        } )
    } );
}
export function apiSignup ( body ) {
    return fetch( "/api/lite/signup", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( body )
    } )
        .then( ( res ) => res.json() );
}

export function apiSessionRehydrateFromServer () {
    const token = DataUtils.getToken();
    if ( !token ) {
        return false;
    }
    return fetch( "/api/lite/login/rehydrate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ token }`
        },
        body: JSON.stringify( { token } )
    } )
        .then( ( res ) => res.json() );
}
export function apiManagerSessionRehydrateFromServer () {
    const token = ManagerUtils.getToken();
    if ( !token ) {
        return false;
    }
    return fetch( "/api/manager/login/rehydrate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ token }`
        },
        body: JSON.stringify( { token } )
    } )
        .then( ( res ) => res.json() );
}

export function apiCoursesAddClassStep ( classStep ) {
    const token = DataUtils.getToken();
    if ( !token ) {
        return false;
    }
    return fetch( "/api/lite/courses/classes/steps", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${ token }`
        },
        body: JSON.stringify( classStep )
    } );
}

export function apiFetchDynamicContent ( type, id ) {
    return fetch( `/api/lite/dynamiccontent/${ type }/${ id }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    } )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                return jsonRes.result;
            }
            return null;
        } );
}
export function apiUpdateLocation ( locationImageFile, item ) {
    const token = DataUtils.getToken();
    if ( !token ) {
        return false;
    }
    const data = new FormData();
    data.append( "locationImageFile", locationImageFile );
    data.append( "data", JSON.stringify( item ) );
    return fetch( "/api/lite/locations", {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${ token }`
        },
        body: data
    } );
}
export function apiFetchBookedSlots () {
    return fetch( "/api/lite/shop/bookedslots", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( { type: "bookedslots" } )
    } )
        .then( ( res ) => res.json() );
}
export function apiFetchCalendarTeamMembers () {
    return fetch( "/api/lite/shop/team", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    } )
        .then( ( res ) => res.json() );
}
export function apiFetchCalendarServices ( optionalActiveFilter ) {
    let queryParams = "";
    if ( optionalActiveFilter === 0 ) {
        queryParams = "?active=0";
    } else if ( optionalActiveFilter === 1 ) {
        queryParams = "?active=1";
    }
    return fetch( `/api/lite/shop/services${ queryParams }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    } )
        .then( ( res ) => res.json() );
}
export function apiFetchCalendarBonos ( optionalActiveFilter ) {
    let queryParams = "";
    if ( optionalActiveFilter === 0 ) {
        queryParams = "?active=0";
    } else if ( optionalActiveFilter === 1 ) {
        queryParams = "?active=1";
    }
    return fetch( `/api/lite/shop/bonos${ queryParams }`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    } )
        .then( ( res ) => res.json() );
}
export function apiProcessPaymentLink ( paymentLink ) {
    return fetch( "/api/lite/paymentlinks/process", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( paymentLink )
    } );
}
export function apiProcessSalePayment ( paymentLink ) {
    return fetch( "/api/lite/sale/processpayment", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( paymentLink )
    } );
}
export function apiFetchShopItems ( categoryId, searchText, brandId ) {
    let params = [];
    let url = "/api/lite/shop/items";
    if ( categoryId ) {
        params.push( { key: "categoryId", value: categoryId } );
    }
    if ( searchText ) {
        params.push( { key: "searchText", value: searchText } );
    }
    if ( brandId ) {
        params.push( { key: "brandId", value: brandId } );
    }
    if ( params.length > 0 ) {
        let urlParams = params.map( tmp => `${ tmp.key }=${ tmp.value }` );
        url += `?${ urlParams.join( "&" ) }`;
    }
    return fetch( url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    } )
        .then( ( res ) => res.json() );
}
export function apiFetchShopCategoryItems ( categoryId ) {
    return apiFetchShopItems( categoryId, null, null );
}

export function apiSaveCustomerSale ( sale, saleBookings ) {
    return fetch( "/api/lite/sale/save", {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( { sale, saleBookings } )
    } )
        .then( ( res ) => res.json() );
}

export function apiValidatePromoCode ( shopAttributes, shopData ) {
    const token = DataUtils.getToken();
    return fetch( "/api/lite/shop/validatepromocode", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify( {
            shopAttributes,
            shopData,
            token
        } )
    } );
}
