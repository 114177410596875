const update = ( ...objects ) => Object.assign( {}, ...objects );
export default ( state = {
    listIsLoading: false,
    listErrorMessage: "",
    items: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_CART_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_CART_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_CART_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_CART_UPDATE_STATUS_SUCCESS": {
            return update( state, {
                items: state.items.map( item => {
                    if ( item.cartCode === action.cartCode ) {
                        return update( item, { cartStatus: action.cartStatus } );
                    }
                    return item;
                } )
            } );
        }
        default: return state;
    }
};
