import { createStore, combineReducers, applyMiddleware, compose, bindActionCreators } from "redux";
import thunkMiddleware from "redux-thunk";
import Cookies from "universal-cookie";
import uiContextReducer from "./reducers/UIContextReducer";
import sessionReducer from "./reducers/SessionReducer";
import contactReducer from "./reducers/ContactReducer";
import checkoutReducer from "./reducers/CheckoutReducer";
import newsReducer from "./reducers/NewsReducer";
import shopReducer from "./reducers/ShopReducer";
import locationsReducer from "./reducers/LocationsReducer";
import contentReducer from "./reducers/ContentReducer";
import ManagerCustomersReducer from "./reducers/ManagerCustomers";
import ManagerWebUsersReducer from "./reducers/ManagerWebUsers";
import ManagerCartsReducer from "./reducers/ManagerCarts";
import ManagerInvoicesReducer from "./reducers/ManagerInvoices";
import ManagerCoursesReducer from "./reducers/ManagerCourses";
import ManagerTeamReducer from "./reducers/ManagerTeam";
import ManagerSalesReducer from "./reducers/ManagerSales";
import ManagerPagesReducer from "./reducers/ManagerPages";
import ManagerShopReducer from "./reducers/ManagerShop";
import ManagerReportsReducer from "./reducers/ManagerReports";
import ManagerCalendarReducer from "./reducers/ManagerCalendar";
import ManagerLocationsReducer from "./reducers/ManagerLocations";
import ManagerOutgoingsReducer from "./reducers/ManagerOutgoings";
import ManagerMessagesReducer from "./reducers/ManagerMessages";
import * as SessionActions from "./actions/SessionActions";
import * as LocalStorage from "./utils/LocalStorage";
import * as ManagerUtils from "./utils/ManagerUtils";
import * as DataUtils from "./utils/DataUtils";

const reducer = combineReducers( {
    pwSession: sessionReducer,
    UIContext: uiContextReducer,
    contact: contactReducer,
    checkout: checkoutReducer,
    news: newsReducer,
    shop: shopReducer,
    locations: locationsReducer,
    content: contentReducer,
    ManagerCustomers: ManagerCustomersReducer,
    ManagerCarts: ManagerCartsReducer,
    ManagerInvoices: ManagerInvoicesReducer,
    ManagerWebUsers: ManagerWebUsersReducer,
    ManagerCourses: ManagerCoursesReducer,
    ManagerTeam: ManagerTeamReducer,
    ManagerSales: ManagerSalesReducer,
    ManagerShop: ManagerShopReducer,
    ManagerPages: ManagerPagesReducer,
    ManagerReports: ManagerReportsReducer,
    ManagerCalendar: ManagerCalendarReducer,
    ManagerLocations: ManagerLocationsReducer,
    ManagerOutgoings: ManagerOutgoingsReducer,
    ManagerMessages: ManagerMessagesReducer
} );

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__( {
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
} ) : compose;

let initialState = {};
const getLangByUrlPath = ( path ) => {
    let result = "";
    [ "en", "es", "ca", "it", "de" ].forEach( ( lang ) => {
        if ( path.indexOf( `/${ lang }/` ) > -1 ) {
            result = lang;
        }
    } );
    return result;
};

if ( typeof window !== "undefined" && typeof window.REDUX_DATA !== "undefined" ) {
    initialState = window.REDUX_DATA;
    const urlLanguage = getLangByUrlPath( window.location.pathname );
    let userLanguageFound = false;
    if ( urlLanguage !== "" ) {
        initialState.pwSession.language = urlLanguage;
        userLanguageFound = true;
    }
    if ( window.location.pathname === "/" ) {
        const cookies = new Cookies();
        const cookiesLang = cookies.get( "language" );
        if ( cookiesLang ) {
            initialState.pwSession.language = cookiesLang;
            userLanguageFound = true;
        }
    }
    if ( !userLanguageFound && navigator && navigator.language ) {
        const browserLanguage = navigator.language;
        [ "en", "es" ].forEach( ( language ) => {
            if ( browserLanguage.startsWith( language ) ) {
                initialState.pwSession.language = language;
            }
        } );
    }
}
// initial state from localstorage
const shopData = LocalStorage.getObject( "pwShopData" );
const managerToken = ManagerUtils.getToken();
const userToken = DataUtils.getToken();
let hydrateOk = true;
if ( shopData ) {
    if ( shopData.version !== initialState.pwSession.shop.version ) {
        hydrateOk = false;
    }
    if ( !shopData.cartItems || !shopData.cartBookings ) {
        hydrateOk = false;
    }
    if ( hydrateOk ) {
        initialState.pwSession.shop = shopData;
    }
}
if ( managerToken ) {
    initialState.pwSession.manager.accessToken = managerToken;
}

const store = createStore(
    reducer,
    initialState,
    composeEnhancers( applyMiddleware( thunkMiddleware ) ),
);

store.origInitialState = initialState;
if ( userToken ) {
    const initActions = bindActionCreators(
        {
            sessionRehydrateFromServer: SessionActions.sessionRehydrateFromServer
        },
        store.dispatch,
    );
    initActions.sessionRehydrateFromServer();
}
// remember don't update store.js
if ( managerToken ) {
    const initActions = bindActionCreators(
        {
            sessionManagerRehydrateFromServer: SessionActions.sessionManagerRehydrateFromServer
        },
        store.dispatch,
    );
    initActions.sessionManagerRehydrateFromServer();
}

export default store;
