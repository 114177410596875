const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    dataLoading: false,
    dataErrorMessage: false,
    items: [],
    currentItems: [],
    brands: [],
    categories: []
}, action ) => {
    switch ( action.type ) {
        case "SHOP_DATA_FETCH_ATTEMPT": {
            return update( state, {
                dataLoading: true,
                dataErrorMessage: ""
            } );
        }
        case "SHOP_DATA_FETCH_ERROR": {
            return update( state, {
                dataLoading: false,
                dataErrorMessage: action.errorMessage
            } );
        }
        case "SHOP_DATA_FETCH_SUCCESS": {
            return update( state, update(
                {
                    dataLoading: false,
                    dataErrorMessage: ""
                },
                action.data
            ) );
        }
        case "SHOP_CATEGORY_ITEMS_ATTEMPT": {
            return update( state, {
                dataLoading: true
            } );
        }
        case "SHOP_CATEGORY_ITEMS_ERROR": {
            return update( state, {
                dataLoading: false
            } );
        }
        case "SHOP_CATEGORY_ITEMS_SUCCESS": {
            return update( state, {
                currentItems: action.items
            } );
        }
        default: return state;
    }
};
