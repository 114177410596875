import React from "react";
import Styles from "./styles/Global";
import * as Pages from "../../../utils/Pages";

const ServiceConditions = () => (
    <div style={ { fontSize: "14px", textAlign: "left" } }>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p><b>&bull;</b> Las presentes Condiciones Generales, junto con las Condiciones Particulares, las correspondientes descripciones de prestaciones y las listas de tarifas, que en cada caso se establezcan, regularán la prestación, por parte de { Pages.company.legalName }, de los servicios de Diseño, Configuración y Publicación de Sitios Web, alojamiento Web, gestión de nombre de dominios, servidores, aplicaciones y herramientas informáticas (en adelante, &quot;los Servicios&quot;), así como otros servicios que en un futuro se pudieren ofrecer al CLIENTE a cambio de la correspondiente remuneración a satisfacer por éste. Dichos Servicios conferirán al CLIENTE un derecho de uso de los productos ofertados, sometido a los plazos, condiciones y términos de las presentes condiciones generales y de las condiciones particulares que, en su caso, se establezcan.</p>
            <p><b>&bull;</b> Las presentes Condiciones Generales prevalecerán sobre cualquier condición general que pudiera alegar el CLIENTE, salvo pacto expreso en contrario en el que se haga constar por escrito su aceptación por parte de { Pages.company.legalName }.</p>
            <p><b>&bull;</b>Las presentes Condiciones Generales serán de aplicación siempre y cuando no entre en contradicción con las Condiciones Particulares o no se hayan dispuesto Condiciones Particulares.</p>
        </div>
        <h4 style={ Styles.GenericPage.SectionTitle }>Derechos y obligaciones de las partes</h4>
        <h5 style={ Styles.GenericPage.SectionSubTitle }>CLIENTE</h5>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p><b>&bull;</b>El CLIENTE tendrá derecho a utilizar el Servicio o Servicios contratados conforme a las Condiciones Generales y Particulares que en cada caso se acuerden.</p>
            <p><b>&bull;</b>El CLIENTE deberá utilizar el Servicio o Servicios contratados conforme a las condiciones pactadas entre las partes, a la legislación vigente y a la buena fe.</p>
            <p><b>&bull;</b>El CLIENTE deberá ser mayor de edad, es decir, mayor de 18 años cumplidos.</p>
            <p><b>&bull;</b>El CLIENTE deberá satisfacer la remuneración pactada para cada Servicio o Servicios en los términos y formas contenidos en las Condiciones Particulares y listas de precios.</p>
            <p><b>&bull;</b>El CLIENTE deberá facilitar a { Pages.company.legalName } sus datos correctos y completos. Se obliga, por tanto, a informar a { Pages.company.legalName } de forma inmediata sobre cualquier modificación de los datos facilitados y a confirmárselos nuevamente a { Pages.company.legalName } , a petición de ésta, en un plazo de 15 días desde la fecha de la modificación.
                Salvo que en las Condiciones Particulares se pacte otra cosa, deberán facilitarse los siguientes datos:
                Nombre completo, NIF/DNI/CIF, confirmación de que el cliente es mayor de edad, dirección, dirección e-mail, teléfono, los datos de pago (bien tarjeta de crédito o débito) y el titular de los datos de pago. En el caso de que el CLIENTE sea una persona jurídica, se facilitará también su forma legal.
            </p>
            <p><b>&bull;</b>El CLIENTE acepta que la dirección de correo electrónico facilitada por el CLIENTE a { Pages.company.legalName }, como dato de contacto, sea el medio de recepción de cualquier comunicación por parte de { Pages.company.legalName } en relación al presente contrato y a las obligaciones derivadas del mismo. De este modo, se considerará que { Pages.company.legalName } cumple con la obligación de informar al CLIENTE al enviar mediante correo electrónico cualquier comunicación relevante.</p>
            <p><b>&bull;</b>EL CLIENTE es responsable del cumplimiento de las leyes y reglamentos que sean de aplicación y, de manera meramente enunciativa, de las reglas que tienen que ver con el funcionamiento del plan online, comercio electrónico, derechos de autor, mantenimiento del orden público, así como principios universales de uso de Internet. Por lo tanto, el CLIENTE acepta cumplir con todas las leyes y reglamentos aplicables en relación con este acuerdo.</p>
            <p><b>&bull;</b>En relación a cualquier contenido que el CLIENTE pueda alojar en el espacio web y/o servidores proporcionados por { Pages.company.legalName }, durante la utilización de cualquier servicio prestado por { Pages.company.legalName } al CLIENTE y durante toda la duración del contrato, el CLIENTE asume que es el titular de todos los derechos necesarios (en particular derechos de propiedad intelectual, patentes y marcas) y que no infringe ningún derecho de terceros. Así el CLIENTE será el único responsable de dicho contenido. En caso de cualquier reclamación de terceros, como consecuencia de un incumplimiento o infracción por parte del CLIENTE, { Pages.company.legalName } podrá solicitar una indemnización al CLIENTE por los daños causados, incluyendo los costes de cualquier actuación legal necesaria para la defensa de los legítimos intereses de { Pages.company.legalName } .</p>
            <p>Por lo tanto el CLIENTE se compromete a NO usar los Servicios de forma contraria a la buena fe y, en particular, de forma meramente enunciativa, no realizará respecto de los mismos:<br /></p>
            <p>Una utilización que resulte contraria a las leyes españolas o que infrinja los derechos de terceros.</p>
            <p>Cualquier publicación o transmisión de contenidos que, a juicio de { Pages.company.legalName }, resulte violento, obsceno, abusivo, ilegal, xenófobo o difamatorio.</p>
            <p>El uso de cracks, números de serie de programas o cualquier otro contenido que vulnere derechos de la propiedad intelectual de terceros.</p>
            <p>La recogida y/o utilización de datos personales de otros usuarios sin su consentimiento expreso o contraviniendo lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal.</p>
            <p>La utilización del servidor de correo y/o de sus direcciones de correo electrónico con fines de spam, mail bombing, phishing, escrow fraud, scam 419, pharming, difusión de virus (troyanos, gusanos, etc.), o cualquier otro tipo de actividad realizada con ánimo saboteador, fraudulento o delictivo.</p>
            <p>La utilización del espacio web para subir archivos no adecuados para las finalidades de hosting o alojamiento telemático como por ejemplo, a título meramente enunciativo, la realización de backups de cualquier tipo, almacenamiento para subidas remotas, almacenamiento de datos para la compartición de archivos o comportamientos similares no relacionados directamente con los contenidos y aplicaciones de dicho espacio Web.</p>
            <p>En todo caso, el CLIENTE no utilizará el espacio Web de manera que suponga una carga excesiva para los equipos de { Pages.company.legalName } o que de alguna manera perjudique el funcionamiento o las operaciones de los equipos o de la red de { Pages.company.legalName } .</p>
            <p>El CLIENTE se obliga a adoptar cuantas medidas de seguridad sean convenientes o necesarias para preservar la confidencialidad y el secreto de su Usuario (Login) y Contraseña (Password) de acceso al portal de { Pages.company.name } , que serán, en todo caso, personales e intransferibles.</p>
            <p>En caso de infracción de cualquiera de las obligaciones indicadas, { Pages.company.legalName } tendrá derecho a resolver el contrato con el CLIENTE sin que éste tenga derecho a ninguna reclamación. Igualmente, { Pages.company.legalName } tendrá derecho a interrumpir el suministro del servicio previa notificación escrita con 48 horas de antelación, que también podrá realizarse a través del correo electrónico, y, en su caso, a la cancelación posterior del mismo.</p>
        </div>
        <h5 style={ Styles.GenericPage.SectionSubTitle }>{ Pages.company.legalName }</h5>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p><b>&bull;</b>{ Pages.company.legalName } garantiza que los Servicios contratados serán prestados en la forma prevista en las presentes Condiciones Generales y, en su caso, en lo establecido en las Condiciones Particulares.</p>
            <p><b>&bull;</b>El CLIENTE, tiene inicialmente 1 Gigabyte de espacio web. { Pages.company.legalName } se reserva el derecho a trasladar al cliente a servidores más adecuados al uso realizado por éste en dicho momento y en tal caso, el cliente acepta que durante el traslado pueda tener el servicio no disponible temporalmente por cuestiones técnicas.</p>
            <p>{ Pages.company.legalName } ofrece una garantía de funcionamiento de los Servicios que faculta al CLIENTE a recibir un abono total o parcial de las tarifas pagadas en el caso de que la disponibilidad total de las páginas web gestionadas por { Pages.company.legalName } sea inferior a 99%. En el caso que el CLIENTE demuestre a satisfacción de { Pages.company.legalName } que la disponibilidad total de las páginas albergadas es inferior a la antedicha referencia, el CLIENTE podrá contactar { Pages.company.legalName } para solicitar un abono de tarifa para dicho mes proporcional al tiempo de falta de disponibilidad, abono que se asignará a la futura compra de Servicios de { Pages.company.name }. Los abonos no son canjeables por dinero y no afectan a los impuestos que sean de aplicación. Los abonos no se aplicarán a interrupciones causadas por (i) mantenimiento periódico previsto o reparaciones llevadas a cabo de cuando en cuando por { Pages.company.legalName } ; (ii) falta de disponibilidad causada por el CLIENTE, (iii) incidencias de disponibilidad que no limitan el acceso del navegador a la página web del CLIENTE (por ejemplo, interrupciones al de e-mail); (iv) suspensión de la cuenta del CLIENTE debido a acciones legales tomadas o anunciadas contra el CLIENTE o sus servicios; (v) suspensión de la cuenta del CLIENTE por violaciones de las Condiciones Generales de Contratación, tales como, a título meramente enunciativo, el uso excesivo de los recursos del sistema, impago o incidencias de pago o identificación de comportamientos fraudulentos o conculcadores de las Condiciones Generales de Contratación; o (vi) causas más allá del control de { Pages.company.legalName } o que no sean razonablemente previsibles por { Pages.company.legalName }.</p>
            <p>
                { Pages.company.legalName } podrá limitar o interrumpir provisionalmente el acceso del CLIENTE a sus prestaciones cuando lo hagan necesario la seguridad del servicio de la red, el mantenimiento de la integridad de la red y, especialmente, el evitar interrupciones graves de la red, del Software o datos guardados.
                Dichas interrupciones serán comunicadas, en la medida de lo posible, con la antelación suficiente al CLIENTE mediante { Pages.company.domain } o vía correo electrónico. La anterior obligación no será exigible a { Pages.company.legalName } en caso de fuerza mayor o si se produce una caída de la red de datos que sirve de base para la prestación del mismo ajena a su voluntad y control.
                { Pages.company.legalName } se reserva el derecho de cancelar definitivamente el antedicho acceso en el supuesto que el CLIENTE infrinja gravemente sus obligaciones y/o en el supuesto que, por motivos ajenos a la voluntad de { Pages.company.legalName } , sea indispensable realizar cambios tecnológicos que impidan la continuación del servicio, previa notificación al CLIENTE con 15 días de antelación.
            </p>
            <p>
                { Pages.company.legalName } no se responsabiliza de:<br /><br />
                &bull; El contenido alojado en el espacio atribuido al usuario por el servicio<br />
                &bull; Los posibles daños en los equipos debidos al uso incorrecto de los mismos (que serán responsabilidad del CLIENTE)<br />
                &bull; Los daños debidos a una infección por virus de sus equipos<br />
                &bull; Cualquier intromisión ilegítima por parte de un tercero<br />
                &bull; La configuración defectuosa por parte del CLIENTE.<br />
            </p>
            <p>{ Pages.company.legalName } podrá ceder los derechos y obligaciones contenidos en las presentes Condiciones Generales a uno o varios terceros. En este caso, el CLIENTE podrá resolver el contrato de manera inmediata.</p>
            <p>{ Pages.company.legalName } elegirá libremente los medios técnicos, que pueden ser relativos a la tecnología y/o infraestructura, con el objeto de facilitar el suministro de los servicios prestados.</p>
            <p>{ Pages.company.legalName } no será responsable de los daños y perjuicios de cualquier naturaleza que pudieran causarse a un tercero o al CLIENTE como consecuencia de la utilización indebida o ilegítima de los Servicios por parte del CLIENTE.</p>
            <p>
                Cualquier reclamación del CLIENTE a { Pages.company.legalName } deberá ser presentada de forma escrita, que podrá realizarse también mediante correo electrónico si el CLIENTE añade a la reclamación su nombre completo, NIF/DNI/CIF, dirección, dirección e-mail, teléfono así como el titular del servicio, y provee al documento electrónico de una firma electrónica, conforme a la normativa vigente.<br />
                Queda excluida la reclamación de defectos e interrupciones no avisadas a tiempo.<br />
                La reclamación debe dirigirse { Pages.company.lopdPostalAddress ? `a la siguiente dirección postal: ${ Pages.company.lopdPostalAddress }, o` : [] } mediante correo electrónico a { Pages.company.lopdEmail }.<br />
                Tras la notificación a { Pages.company.legalName } de los defectos e interrupciones, objetos de la reclamación, el CLIENTE concederá a { Pages.company.legalName } un plazo de 20 días para restablecer el correcto funcionamiento del servicio. Durante este plazo, el CLIENTE no podrá ejercer acción alguna contra { Pages.company.legalName } ni terminar el contrato por motivo de tales defectos e interrupciones.
            </p>
        </div>
        <h4 style={ Styles.GenericPage.SectionTitle }>Licencias, Propiedad Intelectual</h4>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p>{ Pages.company.legalName } es titular o, en su caso, está autorizado para su uso por el legítimo titular, de todos los derechos de autor, marcas, derechos de propiedad intelectual, know-how y cuantos otros derechos guarden relación con los servicios contratados por el CLIENTE, así como de los programas de ordenador necesarios para su implementación y la información que éste obtenga sobre el mismo.</p>
            <p>El CLIENTE debe respetar los programas de uso de terceros puestos a su disposición por { Pages.company.legalName } aunque fueran gratuitos, de los cuales { Pages.company.legalName } dispone de los derechos de explotación necesarios.</p>
            <p>El CLIENTE, en virtud de las presentes Condiciones Generales, no adquiere absolutamente ningún derecho o licencia sobre los Servicios prestados, sobre los programas de ordenador necesarios para la prestación de los Servicios ni tampoco sobre la información técnica de seguimiento de los Servicios, excepción hecha de los derechos y licencias necesarios para el cumplimiento con las presentes Condiciones Generales y únicamente para la duración temporal de los Servicios contratados.</p>
            <p>Para algunos de los servicios, { Pages.company.legalName }, puede poner a disposición del CLIENTE una galería de imágenes con fotografías que el CLIENTE podrá mostrar en su página web alojada por { Pages.company.legalName }. En cualquier caso dichas imágenes/fotografías serán propiedad de { Pages.company.legalName } , o de un proveedor suyo. Así { Pages.company.legalName } permite el uso de las mencionadas imágenes/fotografías exclusivamente en páginas web diseñadas por { Pages.company.legalName }, y únicamente para visualizaciones online. No está permitido ningún uso distinto del anterior, incluidos anuncios en cualquier medio audiovisual, publicidad impresa etc. Cualquier otro uso distinto del permitido, será considerado no legítimo por parte de { Pages.company.legalName } y toda la responsabilidad derivada del mismo será únicamente del CLIENTE.</p>
            <p>Debido a que { Pages.company.legalName } , o un proveedor suyo, mantendrá en todo caso la propiedad de las imágenes/fotografías, { Pages.company.legalName } podrá solicitar al CLIENTE la retirada de cualquier imagen/fotografía en el supuesto de que la propia licencia de uso de { Pages.company.legalName } haya expirado, o en función de cualquier otra circunstancia amparada bajo los legítimos intereses de { Pages.company.legalName } . Si el CLIENTE no eliminase la imagen/fotografía en cuestión, en caso de cualquier reclamación de terceros sobre licencias, marcas, patentes o propiedad intelectual, la responsabilidad ante dichos terceros recaerá sobre el CLIENTE exclusivamente.</p>
        </div>
        <h4 style={ Styles.GenericPage.SectionTitle }>Tarifas y forma de pago</h4>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p> Las tarifas incluidas en las listas de tarifas son fijas. Las tarifas dependen de la elección de la tarifa pactada, contenida en las correspondientes Condiciones Particulares. Si se hubiesen pactado tarifas independientes del uso, { Pages.company.legalName } podrá establecer un sistema de prepago.</p>
            <p>{ Pages.company.legalName } podrá aumentar el importe de las tarifas no más de una vez por trimestre natural con un preaviso de cuatro semanas antes del final del trimestre. Para la validez del aumento se requiere el consentimiento del CLIENTE, que se tendrá por otorgado si el CLIENTE no declara su disconformidad con dicho aumento, dentro de las cuatro semanas siguientes a su notificación por escrito, remitida por correo electrónico a { Pages.company.lopdEmail } o bien a través de { Pages.company.domain }. Si no declara su disconformidad, tendrá alternativamente el derecho de resolver el contrato durante el mismo plazo, transcurrido el cual, el contrato continuará su vigencia con las nuevas tarifas notificadas.</p>
            <p>Se informará al cliente del precio final, incluyendo los impuestos aplicables, al formalizar el proceso de compra y siempre previamente al envío por parte del cliente de su pedido a { Pages.company.legalName }.</p>
            <p>Si se produjese una variación en los impuestos aplicables a los servicios pactados, { Pages.company.legalName } podrá adaptar sus precios en consecuencia.</p>
            <p>Las facturas se emitirán y enviarán al CLIENTE por correo electrónico. El CLIENTE acepta el uso del correo electrónico como la única vía aplicable para la recepción de las facturas generadas por la prestación de los Servicios.</p>
            <p>En función del servicio prestado y del método de pago facilitado por { Pages.company.legalName }, el pago de las facturas se cargará al número de tarjeta bancaria de débito o crédito proporcionado por el CLIENTE. El CLIENTE autoriza expresamente a { Pages.company.legalName } para realizar este cargo a tarjeta de las facturas durante todo el periodo de vigencia de la relación contractual.</p>
            <p>En caso de impago de la factura, el CLIENTE sufragará los costes de todos los requerimientos de pago, así como el coste de la devolución del recibo bancario y todos los demás gastos que se devengasen por dicho motivo atribuible al CLIENTE, incluidos honorarios y gastos de los abogados de { Pages.company.legalName }.</p>
            <p>{ Pages.company.legalName } se reserva el derecho a suspender los Servicios prestados ante cualquier incidencia experimentada en el cobro de los mismos y/o por falta de pago. Si el CLIENTE no procediera a pagar los Servicios en el plazo de los 20 días naturales siguientes a la facturación del Servicio, { Pages.company.legalName } podrá suspender de forma inmediata el mismo y proceder simultáneamente a la reclamación de las cantidades debidas, pudiendo con posterioridad resolver el contrato con el CLIENTE por incumplimiento.</p>
        </div>
        <h4 style={ Styles.GenericPage.SectionTitle }>Oferta, formalización y prórroga del contrato</h4>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p>Después de la petición telefónica o envío de la solicitud online por el CLIENTE, { Pages.company.legalName } podrá aceptar la solicitud de contrato o cancelar el mismo dentro de un plazo de 30 días con la correspondiente anulación del pago del ciclo, en caso de haberse hecho efectivo.</p>
            <p>Si el contrato se firmase para un periodo determinado o se hubiera acordado con el CLIENTE un periodo mínimo de validez, se prorrogará automáticamente en cada caso por el tiempo acordado o por el periodo mínimo, siempre y cuando no se renuncie con un plazo de cuatro semanas antes del tiempo acordado o de la finalización del periodo mínimo. Será de aplicación lo dispuesto en la presente cláusula salvo pacto en contrario contenido en las Condiciones Particulares.</p>
        </div>
        <h4 style={ Styles.GenericPage.SectionTitle }>Terminación del contrato</h4>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p>El contrato podrá finalizar por el mutuo acuerdo de las partes.</p>
            <p>Las relaciones contractuales entre { Pages.company.legalName } y el CLIENTE que no tuvieran una duración determinada podrán terminarse por cualquiera de las partes en todo momento sin necesidad de justificación alguna.</p>
            <p>El CLIENTE podrá solicitar la baja del contrato completo o de alguno de los servicios, enviando un email a { Pages.company.lopdEmail } desde la casilla de email vinculada a su cuenta. Dicha solicitud de baja generará el envío de un e-mail de confirmación por parte de { Pages.company.legalName } , que contendrá la información necesaria para completar el procedimiento de baja.</p>
        </div>
        <h4 style={ Styles.GenericPage.SectionTitle }>Protección de Datos Personales</h4>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p>{ Pages.company.legalName } manifiesta el máximo respeto y otorga una gran importancia a la protección de datos de sus clientes, cuyas decisiones sobre el uso de sus datos se respetarán en todo caso.
                { Pages.company.legalName } cumple con la Ley Orgánica 15/1999, de Protección de Datos de Carácter Personal y con cualquier otra normativa vigente en la materia, y mantiene una <a href={ Pages.getPage( "privacy-policy" ).relativeUrl }>Política de Privacidad</a> sobre datos personales, en la que se establece, principalmente, el uso que { Pages.company.legalName } hace de los datos de carácter personal, se informa a los clientes detalladamente de las circunstancias esenciales de dicho uso y de las medidas de seguridad que se aplican a sus datos de carácter personal para evitar que terceros no autorizados puedan acceder a ellos.
            </p>
        </div>
        <h4 style={ Styles.GenericPage.SectionTitle }>Legislación aplicable y fuero, Resolución alternativa de conflictos</h4>
        <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
            <p>En lo previsto en las presentes Condiciones Generales, así como en la interpretación y resolución de conflictos que pudieran surgir entre las Partes, será de aplicación la legislación española.</p>
            <p>Las Partes se someten para la resolución definitiva de todas las controversias resultantes de la relación contractual a los juzgados o tribunales que resultaran competentes en cada caso.</p>
        </div>
    </div>
);
export default ServiceConditions;
