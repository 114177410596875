export const getObject = ( key, defaultValue = null ) => {
    if ( typeof localStorage === "undefined" ) {
        return defaultValue;
    }
    return JSON.parse( localStorage.getItem( key ) );
};

export const setObject = ( key, object ) => {
    if ( typeof localStorage !== "undefined" ) {
        localStorage.setItem( key, JSON.stringify( object ) );
    }
};
