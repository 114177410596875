/* eslint-disable prefer-destructuring */
import React from "react";
import { connect } from "react-redux";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import * as DataUtils from "../../utils/DataUtils";
import { loadUIContext } from "../../actions/UIContextActions";
import ExpandCollapse from "../../components/widgets/ExpandCollapse";

class PromoModal extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = {};
    }
    componentDidMount() {
        this.props.loadUIContext();
    }
    render () {
        let { promoCode } = this.props;
        if ( !promoCode ) {
            return null;
        }
        let shopItemsCategoriesInfo = [];
        let servicesCategoriesInfo = [];
        const promoCodeRules = promoCode.promoCodeRules;
        promoCodeRules.forEach( tmp => {
            // customer, shopitem_category, service_category
            if ( tmp.promoCodeRuleType === "shopitem_category" && tmp.promoCodeRuleActive ) {
                let shopCategory = this.props.categories.find( cat => cat.id === tmp.promoCodeRuleLinkedId );
                if ( shopCategory ) {
                    shopItemsCategoriesInfo.push( shopCategory.name );
                }
            }
            if ( tmp.promoCodeRuleType === "service_category" && tmp.promoCodeRuleActive ) {
                let shopCategory = this.props.categories.find( cat => cat.id === tmp.promoCodeRuleLinkedId );
                if ( shopCategory ) {
                    servicesCategoriesInfo.push( shopCategory.name );
                }
            }
        } );
        let showMoreInfo = false;
        if ( shopItemsCategoriesInfo.length > 0 || servicesCategoriesInfo.length > 0 || promoCode.promoCodeTitle || promoCode.promoCodeDescription ) {
            showMoreInfo = true;
        }
        return (
            <div className="pw_promocode_card">
                { promoCode.promoCodeImage ? <img src={ `/content/public/${ promoCode.promoCodeImage }` } alt="promo" className="pw_promocode_image" /> : null }
                <p>El código <b>{ promoCode.promoCodeCode }</b> tiene <b>{ promoCode.promoCodeDiscountType }{ DataUtils.formatIntegerPriceTwoDecimals( promoCode.promoCodeDiscountAmount, true ) }</b> de descuento { promoCode.promoCodeDatetimeEnd ? <span>y expira { DataUtils.dateTimeAgo( promoCode.promoCodeDatetimeEnd ) }</span> : null }</p>
                { showMoreInfo ?
                    <div className="pw_promocode_moreinfo">
                        <ExpandCollapse
                            title="Más info"
                            expanded={ this.props.expanded }
                            expandIcon={ <IoIosArrowDown className="pw_widget_expand_collapse_icon_expand" /> }
                            collapseIcon={ <IoIosArrowUp className="pw_widget_expand_collapse_icon_collapse" /> }
                        >
                            { promoCode.promoCodeTitle ? <h6>{ promoCode.promoCodeTitle }</h6> : null }
                            { promoCode.promoCodeDescription ? <div dangerouslySetInnerHTML={ { __html: promoCode.promoCodeDescription } } /> : null }
                            { servicesCategoriesInfo.length > 0 ? <p>Sólo para servicios de las categorías: <b>{ servicesCategoriesInfo.join( ", " ) }</b></p> : null }
                            { shopItemsCategoriesInfo.length > 0 ? <p>Sólo para productos de las categorías: <b>{ shopItemsCategoriesInfo.join( ", " ) }</b></p> : null }
                        </ExpandCollapse>
                    </div> : null }
            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    contact: state.contact,
    UIContext: state.UIContext
} );

const mapDispatchToProps = {
    loadUIContext
};

export default connect( mapStateToProps, mapDispatchToProps )( PromoModal );
