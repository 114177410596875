const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    dataLoading: false,
    dataErrorMessage: false,
    items: []
}, action ) => {
    switch ( action.type ) {
        case "NEWS_DATA_FETCH_ATTEMPT": {
            return update( state, {
                dataLoading: true,
                dataErrorMessage: ""
            } );
        }
        case "NEWS_DATA_FETCH_ERROR": {
            return update( state, {
                dataLoading: false,
                dataErrorMessage: action.errorMessage
            } );
        }
        case "NEWS_DATA_FETCH_SUCCESS": {
            return update( state, update(
                {
                    dataLoading: false,
                    dataErrorMessage: ""
                },
                action.data
            ) );
        }
        default: return state;
    }
};
