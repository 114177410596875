import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ButtonToolbar } from "react-bootstrap";
import Cookies from "universal-cookie";
import * as Pages from "../../utils/Pages";
import * as DataUtils from "../../utils/DataUtils";
import { loadUIContext, acceptCookies } from "../../actions/UIContextActions";
import { contactSend, contactOnChange } from "../../actions/ContactActions";

class AdultsOnlyAcceptance extends React.Component {
    constructor ( props ) {
        super( props );
        this.cookies = new Cookies();
        this.state = {
            acceptAdultsOnly: false
        };
        if ( Pages.company.adultsOnlyPage ) {
            this.state.acceptAdultsOnly = this.cookies.get( "acceptAdultsOnly" );
        }
    }
    componentDidMount() {
        this.props.loadUIContext();
    }
    render () {
        if ( !Pages.company.adultsOnlyPage ) {
            return null;
        }
        return (
            <Modal
                show={ !this.state.acceptAdultsOnly }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                backdropClassName="pw_modal_backdrop_without_opacity"
            >
                <Modal.Body>
                    <img alt="modal adults only" src="/static/adultsOnlyModal.jpg" style={ { width: "100%" } } />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button className="pw_adultsonly_button" href="https://google.com">
                            Salir
                        </Button>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Button
                            className="pw_adultsonly_button"
                            onClick={ () => {
                                this.cookies.set( "acceptAdultsOnly", true, { path: "/", expires: DataUtils.nowAddDays( 1 ) } );
                                this.setState( { acceptAdultsOnly: true } );
                            } }
                        >
                            Entrar
                        </Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    contact: state.contact,
    UIContext: state.UIContext
} );

const mapDispatchToProps = {
    loadUIContext,
    contactSend,
    contactOnChange,
    acceptCookies
};

export default connect( mapStateToProps, mapDispatchToProps )( AdultsOnlyAcceptance );
