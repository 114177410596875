import { formatDateString, today, formatDateNice, formatIntegerPrice } from "./DataUtils";

export const classIsPublishedOrInProgress = ( item ) => {
    let result = true;
    if ( !formatDateString( item.courseClassDatetimeEnd ) < today() ) {
        result = false;
    }
    if ( !item.courseClassActive ) {
        result = false;
    }
    return result;
};
export const classIsPublished = ( item ) => {
    let result = true;
    if ( formatDateString( item.courseClassDatetimeStart ) <= today() ) {
        result = false;
    }
    if ( !item.courseClassActive ) {
        result = false;
    }
    return result;
};
export const courseIsPublished = ( item ) => {
    let result = true;
    if ( item.courseStatus !== "active" ) {
        result = false;
    }
    if ( !item.courseActive ) {
        result = false;
    }
    return result;
};
export const courseNextClass = ( item ) => {
    let nextClassDate = "";
    for ( let classIndex = 0; classIndex < item.courseClasses.length; classIndex += 1 ) {
        const courseClass = item.courseClasses[ classIndex ];
        if ( classIsPublished( courseClass ) ) {
            nextClassDate += formatDateNice( courseClass.courseClassDatetimeStart );
            break;
        }
    }
    return nextClassDate;
};
export const courseNextClasses = ( course, totalClasses ) => {
    const result = [];
    if ( !course || !course.courseClasses ) {
        return result;
    }
    for ( let classIndex = 0; classIndex < course.courseClasses.length; classIndex += 1 ) {
        const courseClass = course.courseClasses[ classIndex ];
        if ( classIsPublished( courseClass ) ) {
            result.push( { text: formatDateNice( courseClass.courseClassDatetimeStart ), object: courseClass } );
            if ( totalClasses === result.length ) {
                break;
            }
        }
    }
    return result;
};
export const courseFormatPriceNice = ( course ) => {
    if ( course.courseTotalAmount === null ) {
        return "Consultar";
    }
    if ( course.courseTotalAmount === 0 ) {
        return "Gratis";
    }
    return formatIntegerPrice( "EUR", course.courseTotalAmount );
};
export const courseStepContentTypeFromExtension = ( extension ) => {
    switch ( extension ) {
        case ".mp4":
        case ".avi":
        case ".mkv":
        case ".mov":
            return "video";
        case ".xlsx":
        case ".xls":
        case ".csv":
        case ".docx":
        case ".doc":
        case ".pdf":
            return "document";
        default:
            return "document";
    }
};
