import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ButtonToolbar } from "react-bootstrap";
import Cookies from "universal-cookie";
import * as DataUtils from "../../utils/DataUtils";
import { loadUIContext, acceptCookies } from "../../actions/UIContextActions";
import PromoCodeCard from "./PromoCodeCard";

class PromoModal extends React.Component {
    constructor ( props ) {
        super( props );
        this.cookies = new Cookies();
        this.state = {
            hidePromoCode: null
        };
        this.state.hidePromoCode = this.cookies.get( "hidePromoCode" );
    }
    componentDidMount() {
        this.props.loadUIContext();
    }
    hidePromoCode( promoCode ) {
        this.cookies.set( "hidePromoCode", promoCode.promoCodeId, { path: "/", expires: DataUtils.nowAddDays( 1 ) } );
        this.setState( { hidePromoCode: promoCode.promoCodeId } );
    }
    render () {
        let featuredPromoCode = null;
        if ( this.props.content && this.props.content.shop && this.props.content.shop.featuredPromoCodes && this.props.content.shop.featuredPromoCodes.length > 0 ) {
            [ featuredPromoCode ] = this.props.content.shop.featuredPromoCodes;
        }
        if ( !featuredPromoCode ) {
            return null;
        }
        if ( this.state.hidePromoCode === featuredPromoCode.promoCodeId ) {
            return null;
        }
        if ( this.props.pwSession.userIsReseller ) {
            return null;
        }
        return (
            <Modal
                show
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={ false }
                className="pw_promocode_modal"
            >
                <Modal.Body>
                    <h4 className="pw_promocode_modal_title">{ featuredPromoCode.promoCodeTitle }</h4>
                    <p>Sólo añade items a tu cesta y al pasar por caja lo tendrás activado. ¡No demores mucho que expira! </p>
                    <PromoCodeCard promoCode={ featuredPromoCode } categories={ this.props.shop ? this.props.shop.categories : [] } />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button
                            variant="pw-primary"
                            onClick={ () => {
                                this.hidePromoCode( featuredPromoCode );
                            } }
                        >
                            ¡Entendido, gracias!
                        </Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = ( state ) => ( {
    contact: state.contact,
    pwSession: state.pwSession,
    UIContext: state.UIContext,
    content: state.content,
    shop: state.shop
} );

const mapDispatchToProps = {
    loadUIContext,
    acceptCookies
};

export default connect( mapStateToProps, mapDispatchToProps )( PromoModal );
