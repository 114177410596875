/* eslint-disable prefer-destructuring */
import * as ManagerApi from "../apimanager";
import { uuid } from "../utils/DataUtils";
import * as ManagerCustomersActions from "./ManagerCustomersActions";

export const requestManagerBookings = () => ( dispatch ) => {
    dispatch( { type: "MANAGER_CALENDAR_LIST_FETCH_ATTEMPT" } );
    return ManagerApi.fetchCalendarBookings()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CALENDAR_LIST_FETCH_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "MANAGER_CALENDAR_LIST_FETCH_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_LIST_FETCH_ERROR", errorMessage: err.message } );
        } );
};
export const requestManagerBookedSlots = () => ( dispatch ) => {
    dispatch( { type: "MANAGER_CALENDAR_BOOKEDSLOTS_FETCH_ATTEMPT" } );
    return ManagerApi.fetchCalendarBookedSlots()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_CALENDAR_BOOKEDSLOTS_FETCH_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "MANAGER_CALENDAR_BOOKEDSLOTS_FETCH_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_BOOKEDSLOTS_FETCH_ERROR", errorMessage: err.message } );
        } );
};

export const changeManagerCalendarAttr = ( key, value ) => ( {
    type: "MANAGER_CALENDAR_CHANGE_ATTR",
    key,
    value
} );
export const changeManagerCalendarEditAttr = ( key, value ) => ( {
    type: "MANAGER_CALENDAR_CHANGE_EDIT_ATTR",
    key,
    value
} );
export const changeManagerCalendarEditChildAttr = ( key, value ) => ( {
    type: "MANAGER_CALENDAR_CHANGE_EDIT_CHILD_ATTR",
    key,
    value
} );
export const managerCalendarShowToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CALENDAR_SHOW_TOAST_MESSAGE", message } );
    setTimeout( () => dispatch( { type: "MANAGER_CALENDAR_HIDE_TOAST_MESSAGE", message } ), 5000 );
};
export const managerCalendarHideToastMessage = ( message ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CALENDAR_HIDE_TOAST_MESSAGE", message } );
};
export const managerNewBookingRequest = () => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ATTEMPT" } );
    const { ManagerCalendar } = getState();
    return ManagerApi.newBooking( ManagerCalendar.editing )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_SUCCESS" } ) );
                dispatch( requestManagerBookings() );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ERROR", err } );
        } );
};
export const managerUpdateBookingRequest = ( newBooking ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ATTEMPT" } );
    const { ManagerCalendar } = getState();
    return ManagerApi.updateBooking( newBooking || ManagerCalendar.editing )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_SUCCESS" } ) );
                dispatch( requestManagerBookings() );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ERROR", err } );
        } );
};
export const managerUpdateBookingsRequest = ( bookings ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ATTEMPT" } );
    return ManagerApi.updateBookings( bookings )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_SUCCESS" } ) );
                dispatch( requestManagerBookings() );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_ERROR", err } );
        } );
};
export const managerDeleteBookingRequest = () => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CALENDAR_DELETE_BOOKING_ATTEMPT" } );
    const { ManagerCalendar } = getState();
    return ManagerApi.deleteBooking( ManagerCalendar.editing )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CALENDAR_DELETE_BOOKING_SUCCESS" } ) );
                dispatch( requestManagerBookings() );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_CALENDAR_DELETE_BOOKING_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_DELETE_BOOKING_ERROR", err } );
        } );
};
export const managerUpdateBookingStatusRequest = ( argBooking, status ) => ( dispatch ) => {
    dispatch( { type: "MANAGER_CALENDAR_UPDATE_BOOKING_STATUS_ATTEMPT" } );
    let booking = Object.assign( {}, argBooking, { bookingStatus: status } );
    return ManagerApi.updateBooking( booking )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CALENDAR_UPDATE_BOOKING_STATUS_SUCCESS" } ) );
                dispatch( requestManagerBookings() );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_CALENDAR_UPDATE_BOOKING_STATUS_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_UPDATE_BOOKING_STATUS_ERROR", err } );
        } );
};
export const requestManagerReminders = () => ( dispatch ) => {
    dispatch( { type: "MANAGER_REMINDERS_LIST_FETCH_ATTEMPT" } );
    return ManagerApi.fetchReminders()
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( { type: "MANAGER_REMINDERS_LIST_FETCH_SUCCESS", items: jsonRes.items } );
            } else {
                dispatch( { type: "MANAGER_REMINDERS_LIST_FETCH_ERROR", errorMessage: jsonRes.errorMessage } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_REMINDERS_LIST_FETCH_ERROR", errorMessage: err.message } );
        } );
};
export const managerNewReminderRequest = ( reminder ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_ATTEMPT" } );
    const { ManagerCalendar } = getState();
    return ManagerApi.newReminder( reminder || ManagerCalendar.editingChild )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_SUCCESS" } ) );
                dispatch( requestManagerReminders() );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_ERROR", err } );
        } );
};
export const managerUpdateReminderRequest = ( reminder ) => ( dispatch, getState ) => {
    dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_ATTEMPT" } );
    const { ManagerCalendar } = getState();
    return ManagerApi.updateReminder( reminder || ManagerCalendar.editingChild )
        .then( ( res ) => res.json() )
        .then( ( jsonRes ) => {
            if ( jsonRes.success ) {
                dispatch( dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_SUCCESS" } ) );
                dispatch( requestManagerReminders() );
            } else {
                if ( jsonRes.errorMessage ) {
                    dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
                }
                dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_ERROR" } );
            }
        } )
        .catch( ( err ) => {
            dispatch( { type: "MANAGER_CALENDAR_NEW_REMINDER_ERROR", err } );
        } );
};
export const managerBookingSend48hsReminderRequest = ( booking ) => ( dispatch ) => ManagerApi.sendBooking48hsReminder( booking )
    .then( ( res ) => res.json() )
    .then( ( jsonRes ) => {
        if ( jsonRes.success ) {
            dispatch( dispatch( { type: "MANAGER_CALENDAR_NEW_BOOKING_SUCCESS" } ) );
            dispatch( requestManagerReminders() );
            return jsonRes.item;
        } else if ( jsonRes.errorMessage ) {
            dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
        }
        return true;
    } )
    .catch( ( err ) => {
        dispatch( managerCalendarShowToastMessage( { id: uuid(), message: err, isLangKey: true } ) );
    } );
export const managerScheduleNotificationRequest = ( transaction ) => ( dispatch ) => ManagerApi.scheduleNotification( transaction )
    .then( ( res ) => res.json() )
    .then( ( jsonRes ) => {
        if ( jsonRes.success ) {
            dispatch( requestManagerReminders() );
            return jsonRes.item;
        } else if ( jsonRes.errorMessage ) {
            dispatch( managerCalendarShowToastMessage( { id: uuid(), message: jsonRes.errorMessage, isLangKey: true } ) );
        }
        return true;
    } )
    .catch( ( err ) => {
        dispatch( managerCalendarShowToastMessage( { id: uuid(), message: err, isLangKey: true } ) );
    } );
export const managerRehydrateCalendarRequests = ( onlyCustomerId ) => ( dispatch ) => dispatch( requestManagerBookings() )
    .then( ( ) => dispatch( requestManagerBookedSlots() ) )
    .then( ( ) => dispatch( ManagerCustomersActions.requestManagerCustomers( null, onlyCustomerId ) ) )
    .then( () => dispatch( changeManagerCalendarAttr( "isRehydrating", false ) ) )
    .catch( () => dispatch( changeManagerCalendarAttr( "isRehydrating", false ) ) );

export const managerRehydrateCalendar = ( onlyCustomerId ) => ( dispatch ) => {
    dispatch( changeManagerCalendarAttr( "isRehydrating", true ) );
    return dispatch( managerRehydrateCalendarRequests( onlyCustomerId ) );
};
