/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isEditingChild: false,
    isEditingChildItem: false,
    isEditingCategory: false,
    isLoading: false,
    isLoadingChild: false,
    isLoadingChildItem: false,
    isLoadingCategory: false,
    isError: false,
    isErrorChild: false,
    isErrorChildItem: false,
    isErrorCategory: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    editing: {},
    editingChild: {},
    editingChildItem: {},
    editingCategory: {},
    items: [],
    categoriesItems: [],
    toastMessages: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_COURSES_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_COURSES_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_COURSES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_COURSES_CATEGORIES_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_COURSES_CATEGORIES_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_COURSES_CATEGORIES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                categoriesItems: action.items
            } );
        }
        case "MANAGER_COURSE_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_COURSE_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_COURSE_CATEGORY_NEW_EDIT": {
            return update( state, {
                isEditingCategory: true,
                isErrorCategory: false,
                isLoadingCategory: false,
                errorMessageCategory: "",
                editingCategory: action.item
            } );
        }
        case "MANAGER_COURSE_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_COURSE_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_COURSE_NEW_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_COURSE_CATEGORY_NEW_ATTEMPT": {
            return update( state, {
                isLoadingCategory: true,
                isErrorCategory: false
            } );
        }
        case "MANAGER_COURSE_CATEGORY_NEW_ERROR": {
            return update( state, {
                isLoadingCategory: false,
                isErrorCategory: true
            } );
        }
        case "MANAGER_COURSE_CATEGORY_NEW_SUCCESS": {
            return update( state, {
                isLoadingCategory: false,
                isErrorCategory: false,
                isEditingCategory: false,
                editingCategory: {}
            } );
        }
        case "MANAGER_COURSE_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_COURSE_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_COURSE_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_COURSE_CATEGORY_UPDATE_ATTEMPT": {
            return update( state, {
                isLoadingCategory: true,
                isErrorCategory: false
            } );
        }
        case "MANAGER_COURSE_CATEGORY_UPDATE_ERROR": {
            return update( state, {
                isLoadingCategory: false,
                isErrorCategory: true
            } );
        }
        case "MANAGER_COURSE_CATEGORY_UPDATE_SUCCESS": {
            return update( state, {
                isLoadingCategory: false,
                isErrorCategory: false,
                isEditingCategory: false,
                editingCategory: {}
            } );
        }
        case "MANAGER_COURSE_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_COURSE_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_COURSE_CATEGORY_CHANGE_EDIT_ATTR": {
            return update( state, { editingCategory: update( state.editingCategory, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_COURSE_CHILD_CHANGE_EDIT_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_COURSE_CHILDITEM_CHANGE_EDIT_ATTR": {
            return update( state, { editingChildItem: update( state.editingChildItem, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_COURSE_UPDATE_STATUS_SUCCESS": {
            return update( state, {
                items: state.items.map( item => {
                    if ( item.customerId === action.customerId ) {
                        return update( item, { customerStatus: action.customerStatus } );
                    }
                    return item;
                } )
            } );
        }
        case "MANAGER_COURSE_EDIT_CLASS_EDIT":
        case "MANAGER_COURSE_NEW_CLASS_EDIT":
        case "MANAGER_COURSE_EDIT_STEP_EDIT":
        case "MANAGER_COURSE_NEW_STEP_EDIT": {
            return update( state, {
                isEditingChild: true,
                isErrorChild: false,
                isLoadingChild: false,
                errorMessageChild: "",
                editingChild: action.item
            } );
        }
        case "MANAGER_COURSE_EDIT_CLASS_USER_EDIT":
        case "MANAGER_COURSE_EDIT_STEP_CONTENT_EDIT":
        case "MANAGER_COURSE_NEW_STEP_CONTENT_EDIT": {
            return update( state, {
                isEditingChildItem: true,
                isErrorChildItem: false,
                isLoadingChildItem: false,
                errorMessageChildItem: "",
                editingChildItem: action.item
            } );
        }
        default: return state;
    }
};
