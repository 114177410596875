/* eslint-disable complexity */
/* eslint-disable prefer-destructuring */
import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GenericGallery from "./GenericGallery";
import { getPageByPath, getPage, PageComponents, company, getPageClassNames } from "../utils/Pages";
import { contentStateData } from "../actions/ContentActions";
import Styles from "./styles/Global";
import GenericDynamicContent from "./GenericDynamicContent";

class GenericPage extends React.Component {
    constructor( props ) {
        super( props );
        this.page = getPageByPath( props.match.path );
        this.state = { dynamicContent: null };
        if ( props.content.page && ( this.page.id === "pages" || this.page.getDynamicContent ) ) {
            this.state.dynamicContent = props.content.page.dynamicContent;
            if ( props.content.page.code ) {
                this.page.code = props.content.page.code;
            }
            if ( props.content.page.seoTitle ) {
                this.page.seoTitle = props.content.page.seoTitle;
            }
            if ( props.content.page.seoDescription ) {
                this.page.seoDescription = props.content.page.seoDescription;
            }
            if ( props.content.page.seoKeywords ) {
                this.page.seoKeywords = props.content.page.seoKeywords;
            }
            if ( props.content.page.url ) {
                this.page.url = props.content.page.url;
            }
        }
    }
    componentDidMount() {
        this.page = Object.assign( {}, getPageByPath( this.props.match.path ) );
    }
    render () {
        let ChildComponent = null;
        if ( this.page.childComponentFile ) {
            ChildComponent = PageComponents[ this.page.childComponentFile ];
        }
        const classNames = getPageClassNames( this.page );
        let pageCodeClass = "";
        if ( this.page.code ) {
            pageCodeClass = `pw_page_code_${ this.page.code }`;
        }

        return (
            <div id={ `generic-page-${ this.page.id }` } className={ classNames ? `pw_generic_page ${ classNames } ${ pageCodeClass }` : `pw_generic_page ${ pageCodeClass }` }>
                {
                    !this.page.breadcrumb || this.page.breadcrumb.length === 0 ? [] :
                        // eslint-disable-next-line react/jsx-indent
                        <Breadcrumb>
                            {
                                this.page.breadcrumb.map( ( pageId ) => {
                                    const breadcrumbPage = getPage( pageId );
                                    return ( <Breadcrumb.Item href={ breadcrumbPage.relativeUrl } key={ pageId }>{breadcrumbPage.menuText}</Breadcrumb.Item> );
                                } )
                            }
                        </Breadcrumb>
                }
                { this.page.title === "" ? null : <h1 style={ Styles.GenericPage.SectionTitle }>{ this.page.title }</h1> }
                { this.page.subTitle === "" ? null : <h3 style={ Styles.GenericPage.SectionSubTitle }>{ this.page.subTitle }</h3> }
                <div style={ Styles.GenericPage.SectionContentContainer }>
                    { this.page.dynamicContent ? <GenericDynamicContent items={ this.page.dynamicContent } /> : null }
                    { ChildComponent ? <ChildComponent pageId={ this.page.id } /> : null }
                    { this.page.embeds ? this.page.embeds.map( ( embed ) => <div dangerouslySetInnerHTML={ { __html: embed.code } } /> ) : null }
                    { this.page.gallery && this.page.gallery.id ? <GenericGallery id={ this.page.gallery.id } /> : [] }
                    { this.state.dynamicContent ? <GenericDynamicContent items={ this.state.dynamicContent } /> : null }
                    { this.page.lastDynamicContent ? <GenericDynamicContent items={ this.page.lastDynamicContent } /> : null }
                </div>
                <Helmet>
                    <title>{ this.page.seoTitle }</title>
                    <meta name="description" content={ this.page.seoDescription } />
                    <meta name="keywords" content={ this.page.seoKeywords } />
                    <meta name="author" content={ this.page.seoAuthor } />
                    <meta httpEquiv="content-Language" content="es" />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content="es" />
                    <meta name="DC.title" content={ this.page.seoTitle } />
                    <meta name="DC.description" content={ this.page.seoDescription } />
                    <meta property="og:title" content={ this.page.seoTitle } />
                    <meta property="og:url" content={ this.page.url } />
                    <meta property="og:image" content={ `https://${ company.domain }/static/og_image_default.png` } />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={ this.page.seoDescription } />
                    { this.page.headInlineScripts ? this.page.headInlineScripts.map( code => <script>{ code }</script> ) : null }
                </Helmet>
            </div>
        );
    }
}
GenericPage.serverFetch = null;
GenericPage.serverFetchType = {
    type: "data",
    modules: [
        { module: "content", serverFetch: contentStateData }
    ]
};

const mapStateToProps = ( state ) => ( {
    content: state.content
} );

export default connect( mapStateToProps )( withRouter( GenericPage ) );
