/* eslint-disable no-continue */
/* eslint-disable no-mixed-operators */
import uuidv1 from "uuid/v1";
import moment from "moment";
import * as Pages from "./Pages";
import { getDiscountTotals, getVATEquivalenceAmount } from "./SalesUtils";

moment.locale( "es" );

export const randomString = ( length ) => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for ( let i = length; i > 0; i -= 1 ) {
        result += chars[ Math.floor( Math.random() * chars.length ) ];
    }
    return result;
};

export const randomInt = ( min, max ) => {
    const minValue = Math.ceil( min );
    const maxValue = Math.floor( max );
    return Math.floor( Math.random() * ( maxValue - minValue ) + minValue );
};

export const uuid = ( short ) => {
    if ( short ) {
        return uuidv1();
    }
    return `${ uuidv1() }-${ randomString( 4 ) }`;
};
export const isDST = () => {
    const today = moment().format( "MM-DD" );
    let result = false;
    if ( today >= "03-28" && today < "10-31" ) {
        result = true;
    }
    return result;
};
export const now = () => moment().utcOffset( isDST() ? 2 : 1 ).format( "YYYY-MM-DD HH:mm:ss" );
export const nowId = () => moment().utcOffset( isDST() ? 2 : 1 ).format( "YYMMDDHHmmss" );
export const nowWithMillis = () => moment().utcOffset( isDST() ? 2 : 1 ).format( "YYYY-MM-DD HH:mm:ss.SSS" );
export const nowMillis = () => moment().utcOffset( isDST() ? 2 : 1 ).valueOf();
export const today = () => moment().utcOffset( 2 ).format( "YYYY-MM-DD" );
export const currentYear = () => moment().utcOffset( 2 ).format( "YYYY" );
export const currentMonth = () => moment().utcOffset( 2 ).format( "MM" );
export const todayDate = () => moment().utcOffset( 2 ).toDate();
export const dateTimeToDate = ( dateTime ) => moment( dateTime ).toDate();
export const sitemapNow = () => moment().utcOffset( 2 ).format( "YYYY-MM-DDTHH:mm:ss+00:00" );
export const formatDateToDisplay = ( string ) => moment( string ).utcOffset( 2 ).format( "YYYY-MM-DD" );
export const howManyYears = ( string ) => moment().diff( string, "years", false );
export const formatDateNice = ( string, optionalFormat ) => {
    if ( !string ) {
        return "";
    }
    return moment( string ).format( optionalFormat || "D [de] MMMM [de] YYYY" );
};
export const formatDateNiceShort = ( string, withYear ) => {
    if ( !string ) {
        return "";
    }
    return moment( string ).format( withYear ? "dddd DD MMM [de] YYYY " : "dddd DD MMM " );
};
export const formatDateTimeNiceShort = ( string, optionalFormat ) => moment( string ).format( optionalFormat || "dddd DD MMM HH:mm" );
export const currentMonthName = () => moment().format( "MMMM" );
export const formatDate = ( string ) => {
    if ( string !== "Invalid date" && moment( string ).isValid() ) {
        return moment( string ).toDate();
    }
    return null;
};

export const formatTimeString = ( string ) => {
    if ( string !== "Invalid date" && moment( string ).isValid() ) {
        return moment( string ).format( "HH:mm" );
    }
    return null;
};

export const formatDateString = ( string ) => {
    if ( string !== "Invalid date" && moment( string ).isValid() ) {
        return moment( string ).format( "YYYY-MM-DD" );
    }
    return null;
};

export const formatDateTimeString = ( string ) => {
    if ( string !== "Invalid date" && moment( string ).isValid() ) {
        return moment( string ).format( "YYYY-MM-DD HH:mm" );
    }
    return null;
};

export const isPastDateTime = ( datetime, todayDateTime ) => {
    if ( !datetime ) {
        return false;
    }
    let tmpNow = now();
    if ( todayDateTime ) {
        tmpNow = todayDateTime;
    }
    return datetime < tmpNow;
};

export const nowAddDays = ( days, format ) => {
    if ( typeof format === "undefined" ) {
        return moment().utcOffset( 1 ).add( days, "days" ).toDate();
    }
    return moment().utcOffset( 1 ).add( days, "days" ).format( format );
};
export const getFutureWeeks = ( months ) => {
    const weeks = [];
    // next Monday
    const startDate = moment( new Date() ).isoWeekday( 8 );
    // adding current week
    // Monday
    let startDateWeek = moment().isoWeekday( 1 ).format( "YYYY-MM-DD" );
    // Sunday
    let endDateWeek = moment().isoWeekday( 7 ).format( "YYYY-MM-DD" );
    weeks.push( [ startDateWeek, endDateWeek ] );
    // adding future weeks
    const before = moment().add( months, "M" ).isoWeekday( 7 );
    while ( startDate.isBefore( before ) ) {
        // Monday
        startDateWeek = startDate.isoWeekday( 1 ).format( "YYYY-MM-DD" );
        // Sunday
        endDateWeek = startDate.isoWeekday( 7 ).format( "YYYY-MM-DD" );
        startDate.add( 7, "days" );
        weeks.push( [ startDateWeek, endDateWeek ] );
    }
    return weeks;
};
export const isBefore = ( argStartDate, argMaxDate ) => {
    const startDate = moment( argStartDate );
    const endDate = moment( argMaxDate );
    return startDate.isBefore( endDate );
};
export const getWeekDayInteger = ( value ) => {
    const result = moment( value ).day();
    if ( result === 0 ) {
        return 7;
    }
    return result;
};
export const getPastDays = ( howManyDays ) => {
    const days = [];
    const currentDate = moment( new Date() );
    const firstDay = moment( new Date() );
    firstDay.subtract( howManyDays, "days" );
    while ( currentDate.isSameOrAfter( firstDay ) ) {
        days.push( currentDate.format( "YYYY-MM-DD" ) );
        currentDate.subtract( 1, "days" );
    }
    return days;
};
export const isSameDay = ( a, b ) => moment( a ).isSame( moment( b ), "day" );
export const getMonthDates = ( monthDate, onlyPastDates ) => {
    let useDate = moment();
    if ( monthDate ) {
        useDate = moment( monthDate );
    }
    let daysInMonth = useDate.daysInMonth();
    const currentDate = moment( `${ useDate.format( "YYYY-MM" ) }-01` ).add( daysInMonth - 1, "days" );
    const result = [];
    while ( daysInMonth > 0 ) {
        if ( !( onlyPastDates && currentDate.isAfter( new Date() ) ) ) {
            result.push( currentDate.format( "YYYY-MM-DD" ) );
        }
        currentDate.subtract( 1, "days" );
        daysInMonth -= 1;
    }
    return result;
};
export const dateTimeAdd = ( datetime, howMany, unit ) => {
    const dateStart = moment( datetime );
    return dateStart.add( howMany, unit );
};
export const dateTimeSubtract = ( datetime, howMany, unit ) => {
    const dateStart = moment( datetime );
    return dateStart.subtract( howMany, unit );
};
export const dateTimeSetYear = ( datetime, year ) => moment( datetime ).set( { year } );
export const dateTimeAgo = ( datetime, from ) => {
    if ( from ) {
        return moment( datetime ).from( from );
    }
    return moment( datetime ).fromNow();
};

// to display a price with currency symbol, getting the price in cents as param
export const formatIntegerPrice = ( currencyCode, amountInteger, skipZeros ) => {
    let tmp = ( amountInteger / 100 ).toFixed( 2 );
    if ( skipZeros ) {
        tmp = parseFloat( tmp );
    }
    switch ( currencyCode ) {
        case "EUR":
            return `€ ${ tmp }`;
        default:
            return `€ ${ tmp }`;
    }
};
// to display a price as 2 decimals, from price in cents, specially for inputs
export const formatIntegerPriceTwoDecimals = ( amountInteger, skipZeros ) => {
    let result = ( amountInteger / 100 ).toFixed( 2 );
    if ( skipZeros ) {
        result = parseFloat( result );
    }
    return result;
};
// to display a percentage with symbol from percentage in cents
export const formatIntegerPercentage = ( percentageInteger ) => {
    if ( percentageInteger && percentageInteger > 100 ) {
        return `${ ( percentageInteger / 100 ) }%`;
    }
    return `${ percentageInteger }%`;
};

// transform number to price in cents
export const formatIntegerPriceAmount = ( number ) => Math.round( number * 100 );

// filter value to number (decimals) only compatible values
export const filterNumber = ( value ) => value.replace( /[^.\d]/g, "" ).replace( /^(\d*\.?)|(\d*)\.?/g, "$1$2" );
export const filterNumberAndNegative = ( value ) => value.replace( /[^.\d-]/g, "" ).replace( /^(-?\d*\.?)|(\d*)\.?/g, "$1$2" );
// filter value to integer only compatible values
export const filterInteger = ( value, defaultValue ) => {
    const result = `${ value }`.replace( /[^\d]/g, "" );
    if ( result === "" ) {
        return typeof defaultValue !== "undefined" ? defaultValue : "";
    }
    return parseInt( result, 10 );
};
export const filterAlphanumeric = ( value, defaultValue ) => {
    const result = `${ value }`.replace( /[^a-z0-9]/gi, "" );
    if ( result === "" ) {
        return typeof defaultValue !== "undefined" ? defaultValue : "";
    }
    return result;
};
// calculates vat from a price already including vat
export const getVatIncludedInteger = ( amountInteger, vatPercentageInteger ) => {
    let divideVatPercentageBy = 10000;
    if ( vatPercentageInteger < 100 ) {
        divideVatPercentageBy = 100;
    }
    return amountInteger - ( Math.round( amountInteger / ( ( vatPercentageInteger / divideVatPercentageBy ) + 1 ) ) );
};
// calculates vat from a price not including vat
export const getVatExcludedInteger = ( amountInteger, vatPercentageInteger ) => {
    let divideVatPercentageBy = 10000;
    if ( vatPercentageInteger < 100 ) {
        divideVatPercentageBy = 100;
    }
    return Math.round( amountInteger * ( vatPercentageInteger / divideVatPercentageBy ) );
};
// calculates percentage integer
export const getPercentageAmount = ( amountInteger, percentageInteger, skipPercentageCheck ) => {
    let divideVatPercentageBy = 10000;
    if ( !skipPercentageCheck && percentageInteger < 100 ) {
        divideVatPercentageBy = 100;
    }
    return Math.round( amountInteger * ( percentageInteger / divideVatPercentageBy ) );
};
// todo: get vat values from a given price, isVatIncluded, quantity, vatPercentage
export const getVatValues = ( priceAmount, isVatIncluded, quantity, vatPercentage, discountAmount, discountType ) => {
    let totalVatAmount = 0;
    let vatAmount = 0;
    let totalAmount = 0;
    let priceWithoutVatAmount = 0;
    let priceWithVatAmount = 0;
    let unitPriceNoVat = 0;

    if ( isVatIncluded ) {
        priceWithVatAmount = priceAmount;
        vatAmount = getVatIncludedInteger( priceWithVatAmount, vatPercentage );
        priceWithoutVatAmount = priceWithVatAmount - vatAmount;
        unitPriceNoVat = priceWithoutVatAmount;
        totalAmount = priceWithVatAmount * quantity;
    } else {
        priceWithoutVatAmount = priceAmount;
        unitPriceNoVat = priceAmount;
        vatAmount = getVatExcludedInteger( priceAmount, vatPercentage );
        priceWithVatAmount = priceWithoutVatAmount + vatAmount;
        totalAmount = priceWithVatAmount * quantity;
    }
    totalVatAmount = getVatIncludedInteger( totalAmount, vatPercentage );
    if ( discountAmount ) {
        const discountTotals = getDiscountTotals( priceWithoutVatAmount, discountType, discountAmount );
        vatAmount = getVatExcludedInteger( discountTotals.totalAfterDiscount, vatPercentage );
        totalVatAmount = vatAmount * quantity;
        totalAmount = ( priceWithoutVatAmount + vatAmount ) * quantity;
    }
    return {
        priceWithoutVatAmount,
        priceWithVatAmount,
        vatAmount,
        totalAmount,
        totalVatAmount,
        unitPriceNoVat
    };
};

export const getTotalAmountValuesFromInvoice = ( invoice ) => {
    const vats = [];
    let totalAmount = 0;
    let totalBaseAmount = 0;
    let totalVATEquivalence = invoice.invoiceVATEquivalenceTaxAmount || 0;
    if ( invoice && invoice.invoiceItems ) {
        for ( let index = 0; index < invoice.invoiceItems.length; index += 1 ) {
            totalAmount += invoice.invoiceItems[ index ].invoiceItemTotalAmount;
            const vatIndex = vats.findIndex( vat => vat.percentage === invoice.invoiceItems[ index ].invoiceItemVatPercentage );
            if ( invoice.invoiceItems[ index ].invoiceItemVatPercentage.toString() !== "0" ) {
                let base = invoice.invoiceItems[ index ].invoiceItemTotalAmount - invoice.invoiceItems[ index ].invoiceItemVatAmount;
                // trying to fix -0.01 base due to getVatIncluded vs getVatExcluded roundings
                if ( base === 1 || base === -1 ) {
                    base = 0;
                }
                totalBaseAmount += base;
                if ( vatIndex > -1 ) {
                    vats[ vatIndex ].total += invoice.invoiceItems[ index ].invoiceItemVatAmount;
                    vats[ vatIndex ].base += base;
                } else {
                    vats.push( { percentage: invoice.invoiceItems[ index ].invoiceItemVatPercentage, total: invoice.invoiceItems[ index ].invoiceItemVatAmount, base } );
                }
            } else {
                totalBaseAmount += invoice.invoiceItems[ index ].invoiceItemTotalAmount;
            }
        }
    }
    if ( invoice.invoiceSale ) {
        // const totals = getSaleTotals( invoice.invoiceSale );
        // totalAmount = totals.total;
        // vats = totals.vatPercentages;
    }
    let IRPFWithholdingsAmount = 0;
    if ( invoice.invoiceIRPFWithholdingsPercentage ) {
        IRPFWithholdingsAmount = getPercentageAmount( totalBaseAmount, invoice.invoiceIRPFWithholdingsPercentage );
        totalAmount -= IRPFWithholdingsAmount;
    }
    // invoices from sales are already calculated
    if ( invoice.invoiceUseVATEquivalence && !invoice.invoiceSaleId ) {
        vats.forEach( ( vatPercentage ) => {
            totalVATEquivalence += getVATEquivalenceAmount( vatPercentage.base, vatPercentage.percentage );
        } );
    }
    totalAmount += totalVATEquivalence;
    return {
        totalAmount, totalBaseAmount, vats, IRPFWithholdingsAmount, totalVATEquivalence
    };
};
export const base64Encode = ( data ) => {
    if ( typeof btoa !== "undefined" ) {
        return btoa( data );
    }
    return Buffer.from( data ).toString( "base64" );
};

export const base64Decode = ( data ) => {
    if ( typeof atob !== "undefined" ) {
        return atob( data );
    }
    return Buffer.from( data, "base64" ).toString();
};

export const checkToken = ( history ) => {
    if ( typeof localStorage === "undefined" ) {
        return "";
    }
    const accessToken = localStorage.getItem( "pwt" );
    if ( !accessToken ) {
        history.push( `${ Pages.getPage( "login" ).relativeUrl }` );
    }
    return accessToken;
};

export const getToken = () => {
    if ( typeof localStorage === "undefined" ) {
        return "";
    }
    return localStorage.getItem( "pwt" );
};

export const setToken = ( token ) => {
    localStorage.setItem( "pwt", token );
};

export const removeToken = () => {
    localStorage.removeItem( "pwt" );
};

export const getStaticProtectedURL = ( path ) => `/static_protected/${ base64Encode( getToken() ) }/${ Pages.company.domain }/${ path }`;
export const getProtectedDocumentURL = ( fileName ) => `/document_protected/user/${ base64Encode( getToken() ) }/${ fileName }`;
export const uniqueArray = ( items, uniquePropName ) => {
    if ( !items ) {
        return items;
    }
    if ( uniquePropName ) {
        return items.filter( ( value, index, self ) => self.findIndex( ( tmpItem ) => tmpItem[ uniquePropName ] === value[ uniquePropName ] ) === index, items );
    }
    return items.filter( ( value, index, self ) => self.indexOf( value ) === index, items );
};
export const shuffleArray = ( items ) => items.map( item => item ).sort( () => Math.random() - 0.5 );
export const sortArray = ( items, sortingOptions ) => items.sort( ( a, b ) => {
    // sortingOptions example: { sortingKeys: [ { alias: "myProperty", direction: "asc" } ] }
    // also available to force nulls using nullsAtTheEnd
    for ( let sortIndex = 0; sortIndex < sortingOptions.sortingKeys.length; sortIndex += 1 ) {
        const sortItem = sortingOptions.sortingKeys[ sortIndex ];
        let aValue = a[ sortItem.alias ];
        let bValue = b[ sortItem.alias ];
        if ( sortItem.type && sortItem.type === "integer" ) {
            aValue = filterInteger( aValue, 999 );
            bValue = filterInteger( bValue, 999 );
        }
        if ( sortItem.nullsAtTheEnd ) {
            if ( bValue === null ) {
                return -1;
            }
            if ( aValue === null ) {
                return 1;
            }
        }
        if ( sortItem.direction === "asc" ) {
            // null first
            if ( bValue === null ) {
                return 1;
            }
            if ( aValue === null ) {
                return -1;
            }
            if ( aValue < bValue ) {
                return -1;
            } else if ( aValue > bValue ) {
                return 1;
            }
        }
        if ( sortItem.direction === "desc" ) {
            // null last
            if ( bValue === null ) {
                return -1;
            }
            if ( aValue === null ) {
                return 1;
            }
            if ( aValue > bValue ) {
                return -1;
            } else if ( aValue < bValue ) {
                return 1;
            }
        }
    }
    return 0;
} );

export const getPublicImageUrl = ( image ) => {
    if ( !image ) {
        return "";
    }
    if ( image.indexOf( "http" ) > -1 ) {
        return image;
    }
    let relativeUrl = "";
    if ( image.indexOf( "/" ) > -1 ) {
        if ( image.indexOf( "/" ) === 0 ) {
            relativeUrl = image;
        } else {
            relativeUrl = `/${ image }`;
        }
    } else {
        relativeUrl = `/content/public/${ image }`;
    }
    return `${ Pages.company.baseURL }${ relativeUrl }`;
};

export const getPetTypeDesc = ( type ) => {
    switch ( type ) {
        case "dog":
            return "Perro";
        case "cat":
            return "Gato";
        case "rabbit":
            return "Conejo";
        case "other":
            return "Otro";
        default:
            return "Otro";
    }
};
export const isEmptyHtml = ( html ) => {
    if ( !html ) {
        return true;
    }
    return html.replace( /<(.|\n)*?>/g, "" ).trim().length === 0;
};
export const stringReplacements = ( initialString, replacements ) => {
    let result = initialString;
    Object.keys( replacements ).forEach( key => {
        result = result.replace( new RegExp( `{${ key }}`, "g" ), replacements[ key ] );
    } );
    return result;
};
export const dateDiff = ( a, b, units ) => {
    const start = moment( a );
    const end = moment( b );
    if ( units === "ms" ) {
        return start.diff( end );
    }
    return end.diff( start, units );
};
export const isSameObject = ( a, b ) => JSON.stringify( a ) === JSON.stringify( b );
export const searchAttributeValues = ( searchInObject, searchKey ) => {
    let result = [];
    if ( searchInObject instanceof Array ) {
        for ( let i = 0; i < searchInObject.length; i += 1 ) {
            result = result.concat( searchAttributeValues( searchInObject[ i ], searchKey ) );
        }
    } else if ( searchInObject instanceof Object ) {
        Object.keys( searchInObject ).forEach( key => {
            if ( key === searchKey ) {
                result.push( searchInObject[ key ] );
            }
            if ( searchInObject[ key ] instanceof Object || searchInObject[ key ] instanceof Array ) {
                result = result.concat( searchAttributeValues( searchInObject[ key ], searchKey ) );
            }
        } );
    }
    return result;
};
export const filterPhoneNumber = ( value ) => {
    if ( !value ) {
        return value;
    }
    return value.replace( /[\s\-a-zA-Z]/g, "" );
};
export const getObjectSizeInBytes = ( obj, unit ) => {
    let str = null;
    if ( typeof obj === "string" ) {
        // If obj is a string, then use it
        str = obj;
    } else {
        // Else, make obj into a string
        str = JSON.stringify( obj );
    }
    // Get the length of the Uint8Array
    const bytes = new TextEncoder().encode( str ).length;
    const kb = ( bytes / 1024 );
    const mb = ( kb / 1024 );
    if ( unit === "KB" ) {
        return kb.toFixed( 2 );
    } else if ( unit === "MB" ) {
        return mb.toFixed( 2 );
    }
    return bytes;
};
export const formatInternationalMobile = ( value, removeSign ) => {
    if ( !value ) {
        return value;
    }
    let result = value;
    if ( value.charAt( 0 ) === "+" ) {
        result = value;
    } else {
        result = `+34${ value }`;
    }
    if ( removeSign ) {
        result = result.replace( "+", "" );
    }
    return result;
};
export const dateTimeRangesOverlap = ( datetimeStartA, datetimeEndA, datetimeStartB, datetimeEndB ) => {
    if ( ( datetimeStartA >= datetimeStartB && datetimeStartA < datetimeEndB ) ||
    ( datetimeEndA > datetimeStartB && datetimeEndA <= datetimeEndB ) ||
    ( datetimeStartA < datetimeStartB && datetimeEndA > datetimeEndB ) ) {
        return true;
    }
    return false;
};
export const htmlTableToCSV = ( id, fileName, separator, decimalSeparator ) => {
    const csvSeparator = separator || ";";
    const csvDecimalSeparator = decimalSeparator || ",";
    const csvFileName = fileName || "reporte.csv";
    // Query all rows
    const htmlRows = document.getElementById( id ).querySelectorAll( "tr" );
    const csvRows = [];
    for ( let rowIndex = 0; rowIndex < htmlRows.length; rowIndex += 1 ) {
        const htmlRow = htmlRows[ rowIndex ];
        if ( htmlRow.dataset && htmlRow.dataset.csvhidden ) {
            continue;
        }
        const htmlCells = htmlRow.querySelectorAll( "th,td" );
        const csvCells = [];
        for ( let cellIndex = 0; cellIndex < htmlCells.length; cellIndex += 1 ) {
            const htmlCell = htmlCells[ cellIndex ];
            if ( htmlCell.dataset && htmlCell.dataset.csvhidden ) {
                continue;
            }
            let csvCellValue = htmlCell.textContent.replace( separator, "" );
            if ( csvCellValue.match( new RegExp( ".*€.*" ) ) ) {
                csvCellValue = filterNumberAndNegative( csvCellValue ).replace( ".", csvDecimalSeparator );
                csvCells.push( `${ csvCellValue }` );
            } else {
                csvCells.push( `"${ csvCellValue }"` );
            }
        }
        csvRows.push( csvCells.join( csvSeparator ) );
    }
    const csvContent = csvRows.join( "\n" );
    const link = document.createElement( "a" );
    link.setAttribute( "href", `data:text/csv;charset=utf-8,${ encodeURIComponent( csvContent ) }` );
    link.setAttribute( "download", csvFileName );
    link.style.display = "none";
    document.body.appendChild( link );
    link.click();
    document.body.removeChild( link );
};
export const htmlToMarkdown = ( value ) => {
    if ( !value ) {
        return value;
    }
    let result = value.replace( "*", "" );
    result = value.replace( /&(nbsp)?;/gmi, " " );
    result = result.replace( /<(br|\/br|br\s\/|p|\/p)\s*.*?>/gmi, "\n" ).replace( /\n\n/gmi, "\n" );
    result = result.replace( /<(b|\/b|strong|\/strong)\s*?>/gmi, "*" );
    result = result.replace( /<(.|\n)*?>/gmi, "" );
    // removing resulting first new line
    if ( result.charAt( 0 ) === "\n" ) {
        result = result.substring( 1 );
    }
    // removing resulting last new line
    if ( result.charAt( result.length - 1 ) === "\n" ) {
        result = result.substring( 0, result.length - 1 );
    }
    console.log( result );
    return result;
};
export const htmlToText = ( value ) => {
    if ( !value ) {
        return value;
    }
    let result = value.replace( "*", "" );
    result = value.replace( /&(nbsp)?;/gmi, " " );
    result = result.replace( /<(br|\/br|br\s\/|p|\/p)\s*.*?>/gmi, "\n" ).replace( /\n\n/gmi, "\n" );
    result = result.replace( /<(b|\/b|strong|\/strong)\s*?>/gmi, "" );
    result = result.replace( /<(.|\n)*?>/gmi, "" );
    // removing resulting first new line
    if ( result.charAt( 0 ) === "\n" ) {
        result = result.substring( 1 );
    }
    // removing resulting last new line
    if ( result.charAt( result.length - 1 ) === "\n" ) {
        result = result.substring( 0, result.length - 1 );
    }
    return result;
};
export const getProvincePostalCodePrefixes = () => {
    const statePrefixes = [
        { stateCode: "01", state: "Araba-Álava" },
        { stateCode: "02", state: "Albacete" },
        { stateCode: "03", state: "Alicante" },
        { stateCode: "04", state: "Almería" },
        { stateCode: "05", state: "Ávila" },
        { stateCode: "06", state: "Badajoz" },
        { stateCode: "07", state: "Illes Balears" },
        { stateCode: "08", state: "Barcelona" },
        { stateCode: "09", state: "Burgos" },
        { stateCode: "10", state: "Cáceres" },
        { stateCode: "11", state: "Cádiz" },
        { stateCode: "12", state: "Castellón" },
        { stateCode: "13", state: "Ciudad Real" },
        { stateCode: "14", state: "Córdoba" },
        { stateCode: "15", state: "Coruña" },
        { stateCode: "16", state: "Cuenca" },
        { stateCode: "17", state: "Girona" },
        { stateCode: "18", state: "Granada" },
        { stateCode: "19", state: "Guadalajara" },
        { stateCode: "20", state: "Gipuzkoa" },
        { stateCode: "21", state: "Huelva" },
        { stateCode: "22", state: "Huesca" },
        { stateCode: "23", state: "Jaén" },
        { stateCode: "24", state: "León" },
        { stateCode: "25", state: "Lleida" },
        { stateCode: "26", state: "La Rioja" },
        { stateCode: "27", state: "Lugo" },
        { stateCode: "28", state: "Madrid" },
        { stateCode: "29", state: "Málaga" },
        { stateCode: "30", state: "Murcia" },
        { stateCode: "31", state: "Navarra" },
        { stateCode: "32", state: "Ourense" },
        { stateCode: "33", state: "Asturias" },
        { stateCode: "34", state: "Palencia" },
        { stateCode: "35", state: "Las Palmas" },
        { stateCode: "36", state: "Pontevedra" },
        { stateCode: "37", state: "Salamanca" },
        { stateCode: "38", state: "Santa Cruz de Tenerife" },
        { stateCode: "39", state: "Cantabria" },
        { stateCode: "40", state: "Segovia" },
        { stateCode: "41", state: "Sevilla" },
        { stateCode: "42", state: "Soria" },
        { stateCode: "43", state: "Tarragona" },
        { stateCode: "44", state: "Teruel" },
        { stateCode: "45", state: "Toledo" },
        { stateCode: "46", state: "Valencia" },
        { stateCode: "47", state: "Valladolid" },
        { stateCode: "48", state: "Bizkaia" },
        { stateCode: "49", state: "Zamora" },
        { stateCode: "50", state: "Zaragoza" },
        { stateCode: "51", state: "Ceuta" },
        { stateCode: "52", state: "Melilla" }
    ];
    return statePrefixes;
};

export const getProvincePostalCodeByPrefix = ( provincePostalCodePrefix ) => getProvincePostalCodePrefixes().find( tmp => provincePostalCodePrefix === tmp.stateCode );
export const getProvincePostalCodeByPostalCode = ( postalCode ) => {
    if ( !postalCode ) {
        return null;
    }
    const postalCodeAsString = postalCode.toString();
    return getProvincePostalCodePrefixes().find( tmp => postalCodeAsString.startsWith( tmp.stateCode ) );
};
export const getPostalCode = ( postalCode, validStatePrefixes ) => {
    let result = null;
    if ( !postalCode ) {
        return result;
    }
    const postalCodeAsString = postalCode.toString();
    if ( postalCodeAsString.length !== 5 ) {
        return result;
    }
    const stateFound = getProvincePostalCodeByPostalCode( postalCodeAsString );
    if ( stateFound ) {
        if ( !validStatePrefixes || validStatePrefixes.includes( stateFound.stateCode ) ) {
            result = stateFound;
        }
    }
    return result;
};

export const getWhatsAppLink = ( mobileNumber, message ) => `https://api.whatsapp.com/send/?phone=${ formatInternationalMobile( mobileNumber, true ) }&text=${ encodeURIComponent( message ) }`;

export const repeatString = ( howManyTimes, stringToRepeat, joinWithString ) => Array( howManyTimes ).fill( stringToRepeat ).join( joinWithString || "" );

export const removeAccents = ( text ) => {
    // removes á, é, í, ó, ú and more compound unicode chars
    if ( !text ) {
        return text;
    }
    return text.normalize( "NFD" ).replace( /[\u0300-\u036f]/g, "" );
};

export const getUrlString = ( description ) => {
    if ( !description ) {
        return "";
    }
    return removeAccents( description ).replaceAll( /[^a-zA-Z0-9]/g, "-" ).toLowerCase();
};
export const capitalize = ( text ) => {
    if ( !text ) {
        return text;
    }
    if ( text.length === 1 ) {
        return text.toUpperCase();
    }
    return text.charAt( 0 ).toUpperCase() + text.slice( 1 );
};
export const urlB64ToUint8Array = ( base64String ) => {
    const padding = "=".repeat( ( 4 - ( base64String.length % 4 ) ) % 4 );
    const base64 = ( base64String + padding ).replace( /-/g, "+" ).replace( /_/g, "/" );

    const rawData = window.atob( base64 );
    const outputArray = new Uint8Array( rawData.length );

    for ( let i = 0; i < rawData.length; i += 1 ) {
        outputArray[ i ] = rawData.charCodeAt( i );
    }
    return outputArray;
};
