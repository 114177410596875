/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isEditingChild: false,
    isLoading: false,
    isLoadingChild: false,
    isError: false,
    isErrorChild: false,
    listIsLoading: false,
    listChildIsLoading: false,
    listErrorMessage: "",
    listChildErrorMessage: "",
    errorMessage: "",
    editing: {},
    editingChild: {},
    items: [],
    incomesItems: [],
    toastMessages: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_INVOICES_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_INVOICES_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_INVOICES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_INCOMES_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listChildIsLoading: true,
                listChildErrorMessage: ""
            } );
        }
        case "MANAGER_INCOMES_LIST_FETCH_ERROR": {
            return update( state, {
                listChildIsLoading: false,
                listChildErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_INCOMES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listChildIsLoading: false,
                listChildErrorMessage: "",
                incomesItems: action.items
            } );
        }
        case "MANAGER_INVOICE_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_INVOICE_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_INVOICE_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_INVOICE_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_INVOICE_NEW_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_INVOICE_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_INVOICE_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_INVOICE_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_INVOICE_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_INVOICE_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_INVOICE_CHANGE_EDIT_CHILD_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_INVOICE_UPDATE_STATUS_SUCCESS": {
            return update( state, {
                items: state.items.map( item => {
                    if ( item.customerId === action.customerId ) {
                        return update( item, { customerStatus: action.customerStatus } );
                    }
                    return item;
                } )
            } );
        }
        case "MANAGER_INVOICE_CHANGE_ADD_ITEM": {
            const items = state.editing.invoiceItems ? state.editing.invoiceItems.map( item => item ) : [];
            items.push( action.item );
            return update( state, { editing: update( state.editing, { invoiceItems: items } ) } );
        }
        case "MANAGER_INVOICE_CHANGE_REMOVE_ITEM": {
            const items = state.editing.invoiceItems.filter( item => item.invoiceItemId !== action.item.invoiceItemId );
            return update( state, { editing: update( state.editing, { invoiceItems: items } ) } );
        }
        case "MANAGER_INVOICE_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessages: newToastMessages } );
        }
        case "MANAGER_INVOICE_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessages: newToastMessages } );
        }
        default: return state;
    }
};
