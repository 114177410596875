/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isLoading: false,
    isError: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    editing: {},
    items: [],
    isEditingChild: false,
    isLoadingChild: false,
    isErrorChild: false,
    listIsLoadingChild: false,
    listErrorMessageChild: "",
    errorMessageChild: "",
    editingChild: {},
    itemsChild: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_MESSAGES_ITEMSCHILD_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoadingChild: true,
                listErrorMessageChild: ""
            } );
        }
        case "MANAGER_MESSAGES_ITEMSCHILD_FETCH_ERROR": {
            return update( state, {
                listIsLoadingChild: false,
                listErrorMessageChild: action.errorMessage
            } );
        }
        case "MANAGER_MESSAGES_ITEMSCHILD_FETCH_SUCCESS": {
            return update( state, {
                listIsLoadingChild: false,
                listErrorMessageChild: "",
                itemsChild: action.items
            } );
        }
        case "MANAGER_MESSAGES_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_MESSAGES_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_MESSAGES_CHANGE_EDITCHILD_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_MESSAGE_TEMPLATE_SAVE_ATTEMPT": {
            return update( state, {
                isLoadingChild: true,
                isErrorChild: false,
                errorMessageChild: ""
            } );
        }
        case "MANAGER_MESSAGE_TEMPLATE_SAVE_ERROR": {
            return update( state, {
                isLoadingChild: false,
                isErrorChild: true,
                errorMessage: ""
            } );
        }
        case "MANAGER_MESSAGE_TEMPLATE_SAVE_SUCCESS": {
            return update( state, {
                isLoadingChild: false,
                isErrorChild: false,
                isEditingChild: false,
                errorMessageChild: "",
                editingChild: {}
            } );
        }
        default: return state;
    }
};
