/* eslint-disable prefer-destructuring */
/* eslint-disable complexity */
import { getWeekDayInteger, formatTimeString, isSameDay, formatDateString, uuid, dateTimeAdd, now, isPastDateTime, formatDate, nowAddDays, today, isBefore, formatDateTimeString } from "./DataUtils";

export const isTimeSlotInResourceAvailability = ( dateStart, dateEnd, resourceType, resourceObject, bookings ) => {
    let resourceAvailable = false;
    const checkWeekDayInteger = getWeekDayInteger( dateStart );
    const checkDate = formatDateString( dateStart );
    const checkTimeStart = formatTimeString( dateStart );
    const checkTimeEnd = formatTimeString( dateEnd );
    if ( !isSameDay( dateStart, dateEnd ) ) {
        return false;
    }
    let isResourceActive = false;
    let resourceAvailability = [];
    let resourceId = null;
    if ( !resourceObject ) {
        return false;
    }
    if ( resourceType === "teamMember" ) {
        isResourceActive = resourceObject.teamMemberActive;
        resourceAvailability = resourceObject.teamMemberAvailability;
        resourceId = resourceObject.teamMemberId;
    }
    if ( resourceType === "resource" ) {
        isResourceActive = resourceObject.resourceActive;
        resourceAvailability = resourceObject.resourceAvailability;
        resourceId = resourceObject.resourceId;
    }
    switch ( resourceType ) {
        case "resource":
        case "teamMember": {
            if ( !isResourceActive ) {
                return false;
            }
            let availableInDefault = false;
            let weekOverrideFound = false;
            let weekOverrideAvailable = false;
            // let overridesFound = [];
            // let availableInOverrides = false;
            resourceAvailability.forEach( availability => {
                // if availabilityType "availability" available 1 and active
                // checking default availability
                if ( availability.availabilityIsDefault && checkWeekDayInteger === availability.availabilityWeekDayInteger && availability.availabilityAvailable ) {
                    if ( checkTimeStart >= availability.availabilityTimeStart
                        && checkTimeStart <= availability.availabilityTimeEnd
                        && checkTimeEnd >= availability.availabilityTimeStart
                        && checkTimeEnd <= availability.availabilityTimeEnd ) {
                        availableInDefault = true;
                    }
                }
                // week override
                if ( !availability.availabilityIsDefault && checkDate >= availability.availabilityDateFrom && checkDate <= availability.availabilityDateTo && checkWeekDayInteger === availability.availabilityWeekDayInteger ) {
                    weekOverrideFound = true;
                    if ( availability.availabilityAvailable &&
                        checkTimeStart >= availability.availabilityTimeStart
                        && checkTimeStart <= availability.availabilityTimeEnd
                        && checkTimeEnd >= availability.availabilityTimeStart
                        && checkTimeEnd <= availability.availabilityTimeEnd ) {
                        weekOverrideAvailable = true;
                    }
                }
            } );
            if ( availableInDefault ) {
                resourceAvailable = true;
            }
            if ( weekOverrideFound ) {
                resourceAvailable = weekOverrideAvailable;
            }
            if ( resourceAvailable && bookings ) {
                const bookingInSlot = bookings.find( bookedSlot => {
                    const checkDatetimeStart = `${ checkDate } ${ checkTimeStart }`;
                    const checkDatetimeEnd = `${ checkDate } ${ checkTimeEnd }`;
                    // if the teamMemberId is booked
                    if ( bookedSlot.bookingTeamMemberId === resourceId || bookedSlot.bookingResourceId === resourceId ) {
                        // if ...
                        // A: Booking inside the check slot
                        // Booking end date is inside the check slot
                        // booking starts and ends outside the slot
                        // booking starts < slot start
                        if ( ( bookedSlot.bookingDatetime >= checkDatetimeStart && bookedSlot.bookingDatetime < checkDatetimeEnd ) ||
                        ( bookedSlot.bookingDatetimeEnd > checkDatetimeStart && bookedSlot.bookingDatetimeEnd <= checkDatetimeEnd ) ||
                        ( bookedSlot.bookingDatetime < checkDatetimeStart && bookedSlot.bookingDatetimeEnd > checkDatetimeEnd ) ) {
                            return true;
                        }
                    }
                    return false;
                } );
                if ( bookingInSlot ) {
                    // resource is not available
                    resourceAvailable = false;
                    return false;
                }
            }
            // checking resource capacity
            if ( resourceType === "resource" ) {
                if ( resourceObject.resourceCapacity === 0 ) {
                    resourceAvailable = false;
                }
                let resourceCapacity = resourceObject.resourceCapacity;
                let resourceTotalBookings = 0;
                if ( resourceAvailable && bookings ) {
                    for ( let bookingIndex = 0; bookingIndex < bookings.length; bookingIndex += 1 ) {
                        const bookedSlot = bookings[ bookingIndex ];
                        const checkDatetimeStart = `${ checkDate } ${ checkTimeStart }`;
                        const checkDatetimeEnd = `${ checkDate } ${ checkTimeEnd }`;
                        // if ...
                        // Booking start date >=
                        // Booking end date <=
                        // booking starts and ends outside the slot
                        if ( ( bookedSlot.bookingDatetime >= checkDatetimeStart && bookedSlot.bookingDatetime < checkDatetimeEnd ) ||
                        ( bookedSlot.bookingDatetimeEnd > checkDatetimeStart && bookedSlot.bookingDatetimeEnd <= checkDatetimeEnd ) ||
                        ( bookedSlot.bookingDatetime < checkDatetimeStart && bookedSlot.bookingDatetimeEnd > checkDatetimeEnd ) ) {
                            // if the resource is simply booked
                            if ( bookedSlot.bookingResourceId && bookedSlot.bookingResourceId === resourceObject.resourceId ) {
                                resourceTotalBookings += 1;
                            }
                            if ( bookedSlot.bookingResources ) {
                                // now let's check the resource is used in booking/service
                                let resourceFound = bookedSlot.bookingResources.findIndex( tmp => tmp.bookingResourceResourceId === resourceObject.resourceId );
                                if ( resourceFound !== -1 ) {
                                    resourceTotalBookings += 1;
                                }
                            }
                            if ( resourceTotalBookings >= resourceCapacity ) {
                                resourceAvailable = false;
                                break;
                            }
                        }
                    }
                }
            }
            break;
        }
        default: {
            break;
        }
    }
    return resourceAvailable;
};
export const serviceResourcesAvailability = ( dateStart, dateEnd, fullService, bookings ) => {
    let result = {
        available: true,
        resources: [],
        notAvailable: [],
        defaultResources: []
    };
    if ( !fullService ) {
        return result;
    }
    // first, only those ones without
    let mainResources = fullService.serviceResources.filter( tmp => !tmp.resourceServiceSubstituteFor && tmp.resourceServiceActive );
    result.defaultResources = mainResources;
    for ( let mainResourceI = 0; mainResourceI < mainResources.length; mainResourceI += 1 ) {
        const mainServiceResource = mainResources[ mainResourceI ];
        let mainResourceAvailable = false;
        let substituteAvailable = false;
        let substitutesNotAvailable = [];
        if ( isTimeSlotInResourceAvailability( dateStart, dateEnd, "resource", mainServiceResource.resourceServiceResource, bookings ) ) {
            result.resources.push( mainServiceResource );
            mainResourceAvailable = true;
        } else {
            let substitutes = fullService.serviceResources.filter( tmpSubstituteResource => tmpSubstituteResource.resourceServiceSubstituteFor === mainServiceResource.resourceServiceResourceId );
            for ( let index = 0; index < substitutes.length; index += 1 ) {
                const substitute = substitutes[ index ];
                if ( isTimeSlotInResourceAvailability( dateStart, dateEnd, "resource", substitute.resourceServiceResource, bookings ) ) {
                    result.resources.push( substitute );
                    substituteAvailable = true;
                    break;
                } else {
                    substitutesNotAvailable.push( substitute );
                }
            }
        }
        if ( !mainResourceAvailable && !substituteAvailable ) {
            result.notAvailable.push( mainServiceResource );
            substitutesNotAvailable.forEach( tmpSubstitute => result.notAvailable.push( tmpSubstitute ) );
            result.available = false;
            break;
        }
    }
    return result;
};

export const getBookingIcons = ( booking ) => {
    let icons = [];
    if ( booking && booking.bookingStatus === "canceled" ) {
        icons.push( { name: "cancel", emoji: "❌" } );
    } else if ( booking && booking.bookingStatus === "active" && booking.bookingRevised ) {
        icons.push( { name: "check", emoji: "✅" } );
    } else if ( booking && booking.bookingStatus === "queued" ) {
        icons.push( { name: "check", emoji: "⏸" } );
    }
    if ( booking && booking.bookingType === "bono" ) {
        icons.push( { name: "ruby" } );
    }
    return icons;
};
export const cancelBooking = ( booking, status, statusInfo ) => {
    if ( !booking ) {
        return booking;
    }
    // // we clear the bono booking if it's not a canceled_to_pay
    // if ( status === "canceled" && statusInfo !== "canceled_to_pay" && bono ) {
    //     let bookingMutations = {
    //         bookingPetId: null, bookingTeamMemberId: null, bookingDatetime: null, bookingDatetimeEnd: null, bookingStatus: "active", bookingCanceled: Object.assign( {}, booking )
    //     };
    //     if ( booking.bookingType === "bono" && bono ) {
    //         let bonoService = bono.bonoServices.find( tmpBonoService => tmpBonoService.bonoServiceId === booking.bookingBonoServiceId );
    //         if ( bonoService ) {
    //             bookingMutations.bookingServiceId = bonoService.bonoServiceServiceId;
    //         }
    //     }
    //     return Object.assign( {}, booking, bookingMutations );
    // }
    if ( status === "canceled" ) {
        return Object.assign( {}, booking, {
            bookingStatus: "canceled",
            bookingStatusInfo: statusInfo
        } );
    }
    return booking;
};
export const addBookingResource = ( booking, resource ) => {
    if ( !booking ) {
        return booking;
    }
    let newBooking = Object.assign( {}, booking );
    if ( !newBooking.bookingResources ) {
        newBooking.bookingResources = [];
    }
    let newBookingResource = {
        bookingResourceUniqueId: uuid(),
        bookingResourceBookingId: booking.bookingId,
        bookingResourceResourceId: resource.resourceId,
        bookingResourceDatetime: booking.bookingDatetime,
        bookingResourceDatetimeEnd: booking.bookingDatetimeEnd,
        bookingResourceActive: 1,
        bookingResourceResource: resource
    };
    newBooking.bookingResources.push( newBookingResource );
    return booking;
};
export const serviceResourcesToBookingResources = ( resourcesService, datetime, datetimeEnd, bookingId ) => {
    let result = [];
    if ( resourcesService ) {
        resourcesService.forEach( resourceService => {
            result.push( {
                bookingResourceBookingId: bookingId,
                bookingResourceResourceId: resourceService.resourceServiceResourceId,
                bookingResourceIsServiceResource: 1,
                bookingResourceDatetime: datetime,
                bookingResourceBookingEnd: datetimeEnd,
                bookingResourceActive: 1
            } );
        } );
    }
    return result;
};
export const cartBookingResourcesToBookingResources = ( cartBookingResources ) => {
    let result = [];
    if ( cartBookingResources ) {
        cartBookingResources.forEach( cartBookingResource => {
            result.push( {
                bookingResourceBookingId: cartBookingResource.cartBookingResourceBookingId,
                bookingResourceResourceId: cartBookingResource.cartBookingResourceResourceId,
                bookingResourceIsServiceResource: cartBookingResource.cartBookingResourceIsServiceResource,
                bookingResourceDatetime: cartBookingResource.cartBookingResourceDatetime,
                bookingResourceBookingEnd: cartBookingResource.cartBookingResourceBookingEnd,
                bookingResourceActive: cartBookingResource.cartBookingResourceActive
            } );
        } );
    }
    return result;
};
export const bookingResourcesToCartBookingResources = ( bookingResources ) => {
    let result = [];
    if ( bookingResources ) {
        bookingResources.forEach( bookingResource => {
            result.push( {
                cartBookingResourceBookingId: bookingResource.bookingResourceBookingId,
                cartBookingResourceResourceId: bookingResource.bookingResourceResourceId,
                cartBookingResourceIsServiceResource: bookingResource.bookingResourceIsServiceResource,
                cartBookingResourceDatetime: bookingResource.bookingResourceDatetime,
                cartBookingResourceBookingEnd: bookingResource.bookingResourceBookingEnd,
                cartBookingResourceActive: bookingResource.bookingResourceActive
            } );
        } );
    }
    return result;
};
export const getNewNotification = ( notificationIsReminder, notificationType, notificationRecipientType, notificationMethod, notificationDatetime, notificationSendNow, notificationRecipientId, notificationSubject, notificationBody, notificationSMSText, notificationEmail, notificationMobile, notificationLinkedType, notificationLinkedId ) => {
    // notificationType: reminder_custom, reminder_booking_48hs, reminder_booking_done, system_notification, reminder_customer_service_ago
    // notificationRecipientType: teamMember, customer
    // notificationLinkedType: paymentlink, sale, booking
    // notificationMethod: whatsapp_manual
    let notification = {
        notificationIsReminder: notificationIsReminder ? 1 : 0,
        notificationType,
        notificationRecipientType,
        notificationRecipientId: notificationRecipientId || null,
        notificationMethod,
        notificationActive: 1,
        notificationStatus: notificationType === "system_notification" ? "sent" : "active",
        notificationDatetime,
        notificationSendNow: notificationSendNow ? 1 : 0,
        notificationSubject,
        notificationBody,
        notificationSMSText,
        notificationEmail: notificationEmail || null,
        notificationMobile: notificationMobile || null,
        notificationLinkedType: notificationLinkedType || null,
        notificationLinkedId: notificationLinkedId || null
    };
    return notification;
};
// eslint-disable-next-line no-unused-vars
export const getBookingQuickActions = ( booking, sale, customer ) => {
    const quickActions = [];
    if ( booking.bookingStatus !== "canceled" && !booking.bookingConfirmationSent && !booking.bookingConfirmed ) {
        quickActions.push( { quickActionType: "booking_send_confirmation" } );
    } else if ( booking.bookingStatus !== "canceled" && booking.bookingConfirmationSent && !booking.bookingConfirmed ) {
        quickActions.push( { quickActionType: "booking_pending_confirmation" } );
    } else if ( booking.bookingStatus === "canceled" && booking.bookingStatusInfo === "canceled_by_payment_link" && sale && sale.saleStatus === "canceled" ) {
        quickActions.push( { quickActionType: "sale_reactivate_from_link" } );
    }
    return quickActions;
};

export const isDateAvailableForService = ( date, isDay, fullService, allTeamMembers, teamMemberId, argAllConflictingBookings ) => {
    const tomorrow = dateTimeAdd( now(), 1, "days" );
    if ( isPastDateTime( date, tomorrow ) ) {
        return false;
    }
    if ( !fullService || !fullService.serviceDuration ) {
        return false;
    }
    if ( !fullService.serviceTeamMembers || fullService.serviceTeamMembers.length === 0 ) {
        return false;
    }
    let allConflictingBookings = argAllConflictingBookings.filter( tmp => tmp.bookingDatetime && tmp.bookingDatetimeEnd );
    // checking team member availability
    let isTeamMemberAvailable = false;
    let isResourcesAvailable = true;
    // we use walk trough 20 slots if 30 minutes to check availability
    let tmpSlots = 1;
    if ( isDay ) {
        tmpSlots = 20;
    }
    let newDate = date;
    for ( let index = 1; index <= tmpSlots; index += 1 ) {
        if ( isDay ) {
            newDate = dateTimeAdd( date, 8, "hours" );
            newDate = dateTimeAdd( newDate, index * 30, "minutes" );
        }
        // checking team member
        let tmpTeamMember = null;
        if ( teamMemberId ) {
            tmpTeamMember = allTeamMembers.find( tmpMember => tmpMember && tmpMember.teamMemberId === teamMemberId );
            isTeamMemberAvailable = isTimeSlotInResourceAvailability( newDate, dateTimeAdd( newDate, fullService.serviceDuration, "minutes" ).toDate(), "teamMember", tmpTeamMember, allConflictingBookings );
        } else {
            let teamMembers = fullService.serviceTeamMembers.filter( serviceTeamMember => serviceTeamMember.serviceTeamMemberActive === 1 && serviceTeamMember.teamMember && serviceTeamMember.teamMember.teamMemberActive && serviceTeamMember.teamMember.teamMemberCalendarActive ).map( tmpServiceTeamMember => tmpServiceTeamMember.teamMember );
            teamMembers = teamMembers.map( tmpServiceTeamMember => allTeamMembers.find( tmp => tmp.teamMemberId === tmpServiceTeamMember.teamMemberId ) );
            for ( let indexTeamMember = 0; indexTeamMember < teamMembers.length; indexTeamMember += 1 ) {
                tmpTeamMember = teamMembers[ indexTeamMember ];
                if ( !isTeamMemberAvailable ) {
                    isTeamMemberAvailable = isTimeSlotInResourceAvailability( newDate, dateTimeAdd( newDate, fullService.serviceDuration, "minutes" ).toDate(), "teamMember", tmpTeamMember, allConflictingBookings );
                }
                if ( isTeamMemberAvailable ) {
                    break;
                }
            }
        }
        // checking service resources availability
        if ( fullService.serviceResources && fullService.serviceResources.length > 0 ) {
            const serviceAvailability = serviceResourcesAvailability( newDate, dateTimeAdd( newDate, fullService.serviceDuration, "minutes" ).toDate(), fullService, allConflictingBookings );
            isResourcesAvailable = serviceAvailability.available;
        }
        if ( isTeamMemberAvailable && isResourcesAvailable ) {
            return true;
        }
    }
    return false;
};

export const getAllConflictingBookings = ( excludeBooking, editingBookings, existingBookings ) => {
    let conflictingBookings = editingBookings.filter( tmp => tmp.bookingDatetime && tmp.bookingDatetimeEnd );
    if ( excludeBooking ) {
        conflictingBookings = conflictingBookings.filter( tmpBooking => tmpBooking !== excludeBooking );
    }
    conflictingBookings = conflictingBookings.concat( existingBookings );
    return conflictingBookings;
};

export const getServiceAvailableDays = ( fullService, allTeamMembers, teamMemberId, allConflictingBookings ) => {
    const startDateTime = dateTimeAdd( `${ today() } 09:00`, 24, "hours" );
    let startDate = formatDate( startDateTime );
    const endDate = nowAddDays( 90 );
    const availableDaysData = [];

    // startDate and endDate require to be in Date format
    while ( isBefore( startDate, endDate ) ) {
        startDate = dateTimeAdd( startDate, 30, "minutes" );
        if ( isDateAvailableForService( formatDate( startDate ), false, fullService, allTeamMembers, teamMemberId, allConflictingBookings ) ) {
            // found available date/time
            const currentDayString = formatDateString( startDate );
            let currentDay = availableDaysData.find( tmp => tmp.dayString === currentDayString );
            if ( !currentDay ) {
                currentDay = {
                    dayString: currentDayString,
                    availableDateTimes: [
                        formatDateTimeString( startDate )
                    ]
                };
                availableDaysData.push( currentDay );
            } else {
                currentDay.availableDateTimes.push( formatDateTimeString( startDate ) );
            }
        }
    }
    return availableDaysData;
};
