import { uuid } from "../utils/DataUtils";
/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isLoading: false,
    isError: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    editing: {},
    isEditingPayment: false,
    editingPayment: {},
    items: [],
    toastMessages: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_SALES_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_SALES_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_SALES_LIST_FETCH_SUCCESS": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: action.items
            } );
        }
        case "MANAGER_SALE_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isEditingId: uuid(),
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_SALE_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isEditingId: uuid(),
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: action.item
            } );
        }
        case "MANAGER_SALE_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_SALE_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_SALE_NEW_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_SALE_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_SALE_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_SALE_UPDATE_SUCCESS": {
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {}
            } );
        }
        case "MANAGER_SALE_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_SALE_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_SALE_UPDATE_STATUS_SUCCESS": {
            return update( state, {
                items: state.items.map( item => {
                    if ( item.customerId === action.customerId ) {
                        return update( item, { customerStatus: action.customerStatus } );
                    }
                    return item;
                } )
            } );
        }
        case "MANAGER_SALE_CHANGE_UPDATE_ITEM": {
            const itemIndex = state.editing.saleItems.findIndex( tmp => tmp.saleItemId === action.item.saleItemId );
            let items = state.editing.saleItems;
            if ( itemIndex > -1 ) {
                const mutation = {};
                mutation[ itemIndex ] = action.item;
                items = Object.assign( [], items, mutation );
            }
            return update( state, { editing: update( state.editing, { saleItems: items } ) } );
        }
        case "MANAGER_SALE_CHANGE_UPDATE_PAYMENT": {
            const itemIndex = state.editing.salePayments.findIndex( tmp => tmp.salePaymentId === action.item.salePaymentId );
            let items = state.editing.salePayments;
            if ( itemIndex > -1 ) {
                const mutation = {};
                mutation[ itemIndex ] = action.item;
                items = Object.assign( [], items, mutation );
            }
            return update( state, { editing: update( state.editing, { salePayments: items } ) } );
        }
        case "MANAGER_SALE_CHANGE_ADD_ITEM": {
            const items = state.editing.saleItems ? state.editing.saleItems.map( item => item ) : [];
            items.push( action.item );
            return update( state, { editing: update( state.editing, { saleItems: items } ) } );
        }
        case "MANAGER_SALE_CHANGE_ADD_PAYMENT": {
            const items = state.editing.salePayments ? state.editing.salePayments.map( item => item ) : [];
            items.push( action.item );
            return update( state, { editing: update( state.editing, { salePayments: items } ) } );
        }
        case "MANAGER_SALE_CHANGE_REMOVE_PAYMENT": {
            const items = state.editing.salePayments.filter( item => item.salePaymentId !== action.item.salePaymentId );
            return update( state, { editing: update( state.editing, { salePayments: items } ) } );
        }
        case "MANAGER_SALE_CHANGE_REMOVE_ITEM": {
            const items = state.editing.saleItems.filter( item => item.saleItemId !== action.item.saleItemId );
            return update( state, { editing: update( state.editing, { saleItems: items } ) } );
        }
        case "MANAGER_SALE_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessages: newToastMessages } );
        }
        case "MANAGER_SALE_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessages ? state.toastMessages.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessages: newToastMessages } );
        }
        default: return state;
    }
};
