/* eslint-disable complexity */
/* eslint-disable prefer-destructuring */
import { uuid, getVatIncludedInteger } from "./DataUtils";
import { getItemPriceAndDiscountValues, getDiscountTotals } from "./SalesUtils";
import * as Pages from "./Pages";

export const getAllSubCategories = ( categoryId, allCategories, onlyIds ) => {
    // max 4 levels
    const result = [];
    const mainCategory = allCategories.find( category => category.id === categoryId );
    // level 1
    if ( onlyIds ) {
        result.push( categoryId );
    } else {
        result.push( mainCategory );
    }
    if ( mainCategory && mainCategory.subCategories ) {
        mainCategory.subCategories.forEach( tmpSubCategory => {
            const subCategory = allCategories.find( tmp => tmp.id === tmpSubCategory.id );
            // level 2
            if ( onlyIds ) {
                result.push( subCategory.id );
            } else {
                result.push( subCategory );
            }
            subCategory.subCategories.forEach( tmpSubSubCategory => {
                const subSubCategory = allCategories.find( tmp => tmp.id === tmpSubSubCategory.id );
                // level 3
                if ( onlyIds ) {
                    result.push( subSubCategory.id );
                } else {
                    result.push( subSubCategory );
                }
                subSubCategory.subCategories.forEach( tmpSubSubSubCategory => {
                    const subSubSubCategory = allCategories.find( tmp => tmp.id === tmpSubSubSubCategory.id );
                    // level 4
                    if ( onlyIds ) {
                        result.push( subSubSubCategory.id );
                    } else {
                        result.push( subSubSubCategory );
                    }
                } );
            } );
        } );
    }
    return result;
};

export const getAllSubCategoriesIds = ( categoryId, allCategories ) => getAllSubCategories( categoryId, allCategories, true );

export const isPromoCodeValidForCustomer = ( promoCode, customer ) => {
    if ( !promoCode ) {
        return false;
    }
    let customerFound = null;
    let customersPromoCodeRules = promoCode.promoCodeRules.filter( tmp => tmp.promoCodeRuleType === "customer" && tmp.promoCodeRuleActive );
    // check limit for customer
    if ( promoCode.promoCodeLimitUseCustomer ) {
        if ( customer ) {
            let totalUsed = customer.customerSales.filter( tmp => tmp.salePromoCodeId === promoCode.promoCodeId );
            if ( totalUsed.length >= promoCode.promoCodeLimitUseCustomer ) {
                return false;
            }
        }
    }
    switch ( promoCode.promoCodeType ) {
        case "global": {
            if ( customer && customer.customerIsReseller ) {
                return false;
            }
            return true;
        }
        case "service_category": {
            if ( customer && customer.customerIsReseller ) {
                return false;
            }
            return true;
        }
        case "shopitem_category": {
            if ( customer && customer.customerIsReseller ) {
                return false;
            }
            return true;
        }
        case "customer":
        case "multiple_customers": {
            if ( !customer ) {
                return false;
            }
            customerFound = customersPromoCodeRules.find( tmp => tmp.promoCodeRuleLinkedId === customer.customerId );
            if ( customerFound ) {
                return true;
            }
            break;
        }
        case "custom": {
            if ( customersPromoCodeRules.length > 0 ) {
                if ( !customer ) {
                    return false;
                }
                customerFound = customersPromoCodeRules.find( tmp => tmp.promoCodeRuleLinkedId === customer.customerId );
                if ( !customerFound ) {
                    return false;
                }
            }
            if ( promoCode.promoCodeOnlyResellers && customer && !customer.customerIsReseller ) {
                return false;
            }
            return true;
        }
        default: {
            break;
        }
    }
    return false;
};

export const isPromoCodeValidForCartItem = ( cartItem, promoCode ) => {
    // € based discounts are only applied to the total
    if ( !promoCode || !promoCode.promoCodeDiscountAmount || promoCode.promoCodeDiscountType === "€" ) {
        return false;
    }
    switch ( promoCode.promoCodeType ) {
        case "global": {
            return true;
        }
        case "customer": {
            return true;
        }
        case "multiple_customers": {
            return true;
        }
        case "service_category": {
            let categoryFound = promoCode.promoCodeRules.find( tmp => tmp.promoCodeRuleType === "service_category" && tmp.promoCodeRuleActive && tmp.promoCodeRuleLinkedId === cartItem.categoryId );
            return categoryFound;
        }
        case "shopitem_category": {
            let categoryFound = promoCode.promoCodeRules.find( tmp => tmp.promoCodeRuleType === "shopitem_category" && tmp.promoCodeRuleActive && tmp.promoCodeRuleLinkedId === cartItem.categoryId );
            return categoryFound;
        }
        case "custom": {
            let shopItemCategoriesRules = promoCode.promoCodeRules.filter( tmp => tmp.promoCodeRuleType === "shopitem_category" && tmp.promoCodeRuleActive );
            let serviceCategoriesRules = promoCode.promoCodeRules.filter( tmp => tmp.promoCodeRuleType === "service_category" && tmp.promoCodeRuleActive );
            let filterCategory = false;
            let categoryFound = null;
            if ( shopItemCategoriesRules.length > 0 ) {
                filterCategory = true;
                categoryFound = shopItemCategoriesRules.find( tmp => tmp.promoCodeRuleType === "shopitem_category" && tmp.promoCodeRuleActive && tmp.promoCodeRuleLinkedId === cartItem.categoryId );
            }
            if ( serviceCategoriesRules.length > 0 ) {
                filterCategory = true;
                categoryFound = serviceCategoriesRules.find( tmp => tmp.promoCodeRuleType === "service_category" && tmp.promoCodeRuleActive && tmp.promoCodeRuleLinkedId === cartItem.categoryId );
            }
            if ( filterCategory && categoryFound ) {
                return true;
            }
            if ( !filterCategory ) {
                return true;
            }
            break;
        }
        default: {
            break;
        }
    }
    return false;
};

export const applyCartItemOverrides = ( cartItems, cartItem, promoCode ) => {
    const newCartItem = Object.assign( {}, cartItem );
    let cartItemPriceAmount = cartItem.priceAmount;

    // calculating the discountType and discountAmount to apply, considering a saleDiscountAmount
    let applyDiscountAmount = 0;
    let applyDiscountType = "%";
    let applyPromoCodeId = null;
    if ( promoCode && promoCode.promoCodeDiscountAmount && promoCode.promoCodeDiscountType === "%" && isPromoCodeValidForCartItem( cartItem, promoCode ) ) {
        applyDiscountAmount = promoCode.promoCodeDiscountAmount;
        applyDiscountType = promoCode.promoCodeDiscountType;
        applyPromoCodeId = promoCode.promoCodeId;
    }
    // cartItem discounts first
    let itemTotalValues = getItemPriceAndDiscountValues(
        cartItemPriceAmount,
        cartItem.priceVatIncluded,
        cartItem.priceVatPercentage,
        cartItem.quantity,
        applyDiscountType,
        applyDiscountAmount
    );
    newCartItem.cartItemTotalAmountBeforeDiscount = itemTotalValues.totalAmountBeforeDiscount;

    newCartItem.cartItemDiscountTypeApplied = applyDiscountType;
    newCartItem.cartItemDiscountAmountApplied = applyDiscountAmount;
    newCartItem.cartItemPromoCodeIdApplied = applyPromoCodeId;
    newCartItem.cartItemTotalAmount = itemTotalValues.totalAmount;
    newCartItem.cartItemVatTotalAmount = itemTotalValues.totalVatAmount;
    newCartItem.cartItemVatAmount = itemTotalValues.totalVatAmount;
    newCartItem.cartItemOverridesDone = true;
    newCartItem.cartItemPriceNoVatAmount = itemTotalValues.totalAmountNoVat;
    newCartItem.cartItemUnitPriceNoVatAmount = itemTotalValues.unitPriceNoVatAmount;
    newCartItem.cartItemUnitPriceAmount = itemTotalValues.unitPriceAmount;
    newCartItem.cartItemUnitVatAmount = itemTotalValues.unitVatAmount;
    newCartItem.cartItemUnitSellPriceAmount = itemTotalValues.unitSellPriceAmount;
    newCartItem.cartItemUnitDiscountAmount = itemTotalValues.unitDiscountAmount;
    newCartItem.cartItemTotalDiscountAmount = itemTotalValues.totalDiscountAmount;
    return newCartItem;
};

export const getCartTotals = ( cart, freeShippingAmount, freeShippingBeforeVat, prepaymentAmountForService, promoCode, inStorePickUp ) => {
    let subtotal = 0;
    let total = 0;
    let totalBeforeDiscount = 0;
    let totalItems = 0;
    const vatPercentages = [];
    let shippingPrice = 0;
    let amountForFreeShipping = 0;
    let prepaymentTotalAmount = 0;
    let totalDiscountAmount = 0;
    let vatTotalAmount = 0;
    cart.cartItems.forEach( tmpCartItem => {
        let cartItem = applyCartItemOverrides( cart.cartItems, tmpCartItem, promoCode );
        const vatPercentageIndex = vatPercentages.findIndex( vat => vat.percentage === cartItem.priceVatPercentage );
        vatTotalAmount = 0;
        let price = 0;
        totalItems += cartItem.quantity;
        if ( cartItem.shippingPrice && cartItem.shippingPrice > shippingPrice ) {
            shippingPrice = cartItem.shippingPrice;
        }

        vatTotalAmount = cartItem.cartItemVatTotalAmount;
        price = cartItem.cartItemTotalAmount;

        if ( vatPercentageIndex > -1 ) {
            vatPercentages[ vatPercentageIndex ].total += vatTotalAmount;
        } else {
            vatPercentages.push( { percentage: cartItem.priceVatPercentage, total: vatTotalAmount } );
        }
        subtotal += price - vatTotalAmount;
        total += price;
        totalBeforeDiscount += cartItem.cartItemTotalAmountBeforeDiscount;
        if ( !cartItem.isGift && cartItem.type === "service" ) {
            prepaymentTotalAmount += prepaymentAmountForService * cartItem.quantity;
        } else {
            prepaymentTotalAmount += price;
        }
        totalDiscountAmount += cartItem.cartItemTotalDiscountAmount;
    } );
    if ( promoCode && promoCode.promoCodeDiscountAmount && promoCode.promoCodeDiscountType === "€" ) {
        let discountTotals = getDiscountTotals( total, promoCode.promoCodeDiscountType, promoCode.promoCodeDiscountAmount );
        totalDiscountAmount += discountTotals.discountTotalAmount;
        totalBeforeDiscount = total;
        total = discountTotals.totalAfterDiscount;
        if ( vatPercentages.length === 1 ) {
            vatTotalAmount = getVatIncludedInteger( total, vatPercentages[ 0 ].percentage );
            vatPercentages[ 0 ].total = vatTotalAmount;
            vatPercentages[ 0 ].base = total - vatTotalAmount;
            subtotal = total - vatTotalAmount;
        }
    }
    if ( freeShippingAmount ) {
        let totalValue = subtotal;
        if ( !freeShippingBeforeVat ) {
            totalValue = total;
        }
        if ( inStorePickUp || totalValue >= freeShippingAmount ) {
            shippingPrice = 0;
            amountForFreeShipping = 0;
        } else {
            amountForFreeShipping = freeShippingAmount - totalValue;
        }
        // vat
        if ( shippingPrice > 0 ) {
            let vatPercentageIndex = vatPercentages.findIndex( vat => vat.percentage === 2100 );
            let shippingVatTotalAmount = getVatIncludedInteger( shippingPrice, 2100 );
            if ( vatPercentageIndex > -1 ) {
                vatPercentages[ vatPercentageIndex ].total += shippingVatTotalAmount;
            } else {
                vatPercentages.push( { percentage: 2100, total: shippingVatTotalAmount } );
            }
        }
    }
    total += shippingPrice;
    totalBeforeDiscount += shippingPrice;
    return {
        subtotal, total, vatPercentages, shippingPrice, amountForFreeShipping, prepaymentTotalAmount, totalItems, totalDiscountAmount, totalBeforeDiscount
    };
};
export const getShopItemFromObject = ( type, item ) => {
    let result = null;
    let shopPage = Pages.getPage( "shop" );
    switch ( type ) {
        case "service": {
            result = {
                id: item.serviceId,
                type,
                title: item.serviceTitle,
                subTitle: item.serviceSubtitle,
                serviceId: item.serviceId,
                categoryId: item.serviceCategoryId,
                service: item,
                topOfferText: item.serviceCardOfferText || null,
                topOfferTextColor: item.serviceCardOfferColor || null,
                topOfferTextBgColor: item.serviceCardOfferBgColor || null,
                brandId: null,
                relativeUrl: `${ shopPage.relativeUrl }/${ item.serviceCategoryId }/${ item.serviceId }`,
                imageTop: `/content/public/${ item.serviceImageWeb }`,
                shortText: item.serviceShortText,
                htmlDetails: item.serviceHtmlDescription,
                code: item.serviceCode || item.serviceId,
                internalProvider: "pw",
                priceCurrency: "EUR",
                priceAmount: item.servicePriceAmount,
                priceVatPercentage: item.serviceVatPercentage,
                priceVatIncluded: item.serviceVatIncluded,
                shopItemIsSpecialPrice: item.serviceIsSpecialPrice,
                shopItemPreviousPriceAmount: item.servicePreviousPriceAmount,
                shopItemPreviousPriceAmountDatetime: item.servicePreviousPriceAmountDatetime,
                maxQuantityPerPurchase: 10,
                bonos: item.serviceBonos || [],
                saleActive: 1,
                active: 1,
                price: {
                    currency: "EUR",
                    amount: item.servicePriceAmount,
                    vatPercentage: item.serviceVatPercentage
                }
            };
            break;
        }
        case "giftcardamount": {
            result = {
                uuid: uuid(),
                id: "giftcardamount",
                title: Pages.text( "es", "shop.item.giftcardamount.title" ),
                categoryId: "giftcards",
                brandId: null,
                relativeUrl: `${ shopPage.relativeUrl }/giftcards/giftcard`,
                imageTop: "/static/giftcard-item-amount.jpg",
                shortText: Pages.text( "es", "shop.item.giftcardamount.description" ),
                code: "giftcardamount",
                internalProvider: "pw",
                priceCurrency: "EUR",
                priceAmount: 5000,
                priceVatPercentage: 0,
                priceVatIncluded: 1,
                maxQuantityPerPurchase: 1,
                saleActive: 1,
                isGiftCardAmount: true,
                minPriceAmount: item.minPriceAmount || 1000,
                maxPriceAmount: item.maxPriceAmount || null,
                active: 1,
                price: {
                    currency: "EUR",
                    amount: 5000,
                    vatPercentage: 0
                }
            };
            break;
        }
        case "giftcardservices": {
            result = {
                uuid: uuid(),
                id: "giftcardservices",
                title: Pages.text( "es", "shop.item.giftcardservices.title" ),
                categoryId: "giftcards",
                brandId: null,
                relativeUrl: `${ shopPage.relativeUrl }/giftcards/giftcardservices`,
                imageTop: "/static/giftcard-item-services.jpg",
                shortText: Pages.text( "es", "shop.item.giftcardservices.description" ),
                code: "giftcardservices",
                internalProvider: "pw",
                priceCurrency: "EUR",
                priceAmount: 0,
                priceVatPercentage: 0,
                priceVatIncluded: 1,
                maxQuantityPerPurchase: 1,
                saleActive: 1,
                hideAddToCart: true,
                hidePrice: true,
                isGiftCardServices: true,
                active: 1,
                price: {
                    currency: "EUR",
                    amount: 0,
                    vatPercentage: 0
                }
            };
            break;
        }
        default:
            break;
    }
    return result;
};
export const applyShopItemOverrides = ( shopItem, clientIsReseller ) => {
    const newShopItem = Object.assign( {}, shopItem );
    if ( clientIsReseller ) {
        if ( shopItem.resellerPriceAmount ) {
            newShopItem.priceAmount = shopItem.resellerPriceAmount;
        }
    }
    return newShopItem;
};

export const getPromoCodeAvailable = ( contentState, sessionState ) => {
    let promoCodeAvailable = null;
    if ( contentState && contentState.shop && contentState.shop.featuredPromoCodes && contentState.shop.featuredPromoCodes.length > 0 ) {
        promoCodeAvailable = contentState.shop.featuredPromoCodes[ 0 ];
    }
    if ( sessionState.userIsReseller ) {
        promoCodeAvailable = null;
    }
    return promoCodeAvailable;
};

export const isOutsourcedCartItem = ( cartItem ) => {
    let result = false;
    if ( cartItem && cartItem.service ) {
        let fullService = cartItem.service;
        let bonoService = null;
        if ( cartItem.type === "bono" ) {
            bonoService = cartItem.service;
            fullService = bonoService.bonoServiceService;
        }
        if ( fullService && fullService.serviceOutsourced ) {
            result = true;
        }
    }
    return result;
};

export const isOutsourcedCart = ( cartItems ) => {
    let result = false;
    if ( cartItems ) {
        let outsourced = cartItems.find( cartItem => isOutsourcedCartItem( cartItem ) );
        if ( outsourced ) {
            result = true;
        }
    }
    return result;
};
export const isCartWithServices = ( cartItems ) => {
    let result = false;
    if ( cartItems ) {
        let cartItemWithServices = cartItems.find( cartItem => cartItem.service );
        if ( cartItemWithServices ) {
            result = true;
        }
    }
    return result;
};

export const getForcePaymentMethod = ( cartItems, forceServicesPaymentMethod ) => {
    if ( forceServicesPaymentMethod && isCartWithServices( cartItems ) ) {
        return forceServicesPaymentMethod;
    }
    let result = null;
    if ( isOutsourcedCart( cartItems ) ) {
        result = "paylater";
    }
    return result;
};

export const getShopItemPreviousPrice = ( shopItem ) => {
    if ( shopItem.shopItemPreviousPriceAmountDatetime && shopItem.shopItemPreviousPriceAmount > shopItem.priceAmount ) {
        return shopItem.shopItemPreviousPriceAmount;
    }
    return null;
};

export const getCardIconName = ( provider, cardId ) => {
    if ( provider === "redsys" ) {
        if ( [ "1", "2", "6", "8", "9" ].includes( `${ cardId }` ) ) {
            return `redsys-card-brand-${ cardId }`;
        }
    }
    return "card-brand-generic";
};
