import React from "react";

const CookiesPolicy = () => (
    <div style={ { textAlign: "left" } }>
        <h4><strong>Pol&iacute;tica de cookies:</strong></h4>
        <p><strong>Seg&uacute;n la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y Comercio Electr&oacute;nico le informamos de que este sitio web usa Cookies para mejorar y optimizar la experiencia del usuario. A continuaci&oacute;n encontrar&aacute; informaci&oacute;n detallada sobre qu&eacute; son las &ldquo;Cookies&rdquo;, qu&eacute; tipolog&iacute;a utiliza este sitio web, c&oacute;mo cambiar la configuraci&oacute;n de sus cookies, y qu&eacute; ocurre si las deshabilitas.</strong></p>
        <p><strong>&iquest;Qu&eacute; son las cookies?&nbsp;</strong></p>
        <p><strong>Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas p&aacute;ginas web. Las cookies permiten a una p&aacute;gina web, entre otras cosas, almacenar y recuperar informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n de un usuario o de su equipo y, dependiendo de la informaci&oacute;n que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</strong></p>
        <h5><strong>&iquest;Qu&eacute; tipos de cookies utiliza esta p&aacute;gina web?&nbsp;</strong></h5>
        <ul>
            <li><strong>Seg&uacute;n la entidad que las gestione, las cookies pueden ser:</strong></li>
            <li><strong>Cookies propias:</strong> Son aquellas que se env&iacute;an al equipo terminal del interesado desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</li>
            <li><strong>Cookies de terceros:</strong> Son aquellas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos trav&eacute;s de las cookies.&nbsp;</li>
        </ul>
        <h5>El Sitio Web utiliza tanto cookies propias como de terceros.</h5>
        <ul>
            <li><strong>Seg&uacute;n el plazo de tiempo que permanecen activadas:</strong></li>
            <li><strong>Cookies de sesi&oacute;n:</strong> Son un tipo de cookies dise&ntilde;adas para recabar y almacenar datos mientras el usuario accede a una p&aacute;gina web. Se suelen emplear para almacenar informaci&oacute;n que solo interesa conservar para la prestaci&oacute;n del servicio solicitado por el usuario en una sola ocasi&oacute;n (p.ej. una lista de productos adquiridos).</li>
            <li><strong>Cookies persistentes:</strong> Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios a&ntilde;os.</li>
        </ul>
        <h5>El Sitio Web utiliza tanto cookies persistentes como cookies de sesi&oacute;n.</h5>
        <ul>
            <li><strong>&iquest;Para qu&eacute; finalidades pueden ser utilizadas las cookies de la web?</strong></li>
        </ul>
        <ul>
            <li><strong>Cookies t&eacute;cnicas: </strong>Son aqu&eacute;llas que permiten al usuario la navegaci&oacute;n a trav&eacute;s de una p&aacute;gina web, plataforma o aplicaci&oacute;n y la utilizaci&oacute;n de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tr&aacute;fico y la comunicaci&oacute;n de datos, identificar la sesi&oacute;n, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripci&oacute;n o participaci&oacute;n en un evento, utilizar elementos de seguridad durante la navegaci&oacute;n, almacenar contenidos para la difusi&oacute;n de videos o sonido o compartir contenidos a trav&eacute;s de redes sociales.</li>
            <li><strong>Cookies de personalizaci&oacute;n:</strong> Son aqu&eacute;llas que permiten al usuario acceder al servicio con algunas caracter&iacute;sticas de car&aacute;cter general predefinidas en funci&oacute;n de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a trav&eacute;s del cual accede al servicio, la configuraci&oacute;n regional desde donde accede al servicio, etc.</li>
            <li><strong>Cookies de an&aacute;lisis:</strong> Son aqu&eacute;llas que permiten al responsable de las mismas, el seguimiento y an&aacute;lisis del comportamiento de los usuarios de los sitios web a los que est&aacute;n vinculadas. La informaci&oacute;n recogida mediante este tipo de cookies se utiliza en la medici&oacute;n de la actividad de los sitios web, aplicaci&oacute;n o plataforma y para la elaboraci&oacute;n de perfiles de navegaci&oacute;n de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en funci&oacute;n del an&aacute;lisis de los datos de uso que hacen los usuarios del servicio.</li>
            <li><strong>Cookies publicitarias:</strong> Son aqu&eacute;llas que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una p&aacute;gina web, aplicaci&oacute;n o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.</li>
            <li><strong>Cookies de publicidad comportamental:</strong> Son aqu&eacute;llas que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una p&aacute;gina web, aplicaci&oacute;n o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan informaci&oacute;n del comportamiento de los usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada de sus h&aacute;bitos de navegaci&oacute;n, lo que permite desarrollar un perfil espec&iacute;fico para mostrar publicidad en funci&oacute;n del mismo.</li>
        </ul>
        <h5><strong>Cookies de terceros:</strong></h5>
        <table border="0" width="100%" style={ { maxWidth: "1000px", border: "1px", margin: "auto" } } cellPadding="15">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Proveedor</th>
                    <th>Caducidad</th>
                    <th>Finalidad</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>stripe.csrf</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Sesi&oacute;n</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>site-auth</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Sesi&oacute;n</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>__stripe_orig_props</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Sesi&oacute;n</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>private_machine_identifier</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>1 a&ntilde;o</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>cookie-perms</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>6 meses</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>machine_identifier</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>5 a&ntilde;os</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>session</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Sesi&oacute;n</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>__stripe_mid</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>1 a&ntilde;o</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>__stripe_sid</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>30 minutos</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>recent-views</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Sesi&oacute;n</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>scfc</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Seguridad y pasarela de pago Stripe</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>1 a&ntilde;o</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>_ga</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Google Analytics</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Se usa para distinguir a los usuarios</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>2 a&ntilde;os</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>_gid</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Google Analytics</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Se usa para distinguir a los usuarios</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>24 horas</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1">
                        <p>_gat </p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Google Analytics</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>Se usa para limitar el porcentaje de solicitudes</p>
                    </td>
                    <td colSpan="1" rowSpan="1">
                        <p>1 minuto</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <h5><strong>Cookies propias:</strong></h5>
        <table border="0" width="100%" style={ { maxWidth: "1000px", border: "1px", margin: "auto" } } cellPadding="15">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Proveedor</th>
                    <th>Caducidad</th>
                    <th>Finalidad</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>language</td>
                    <td>Propia</td>
                    <td>1 Año</td>
                    <td>Idioma del usuario</td>
                </tr>
                <tr>
                    <td>acceptCookies</td>
                    <td>Propia</td>
                    <td>1 Año</td>
                    <td>Consentimiento del usuario del uso de las cookies</td>
                </tr>
            </tbody>
        </table>
        <h5><strong>&iquest;Qu&eacute; ocurre si se deshabilitan las Cookies?</strong></h5>
        <p><strong>Algunas funcionalidades de los Servicios y &aacute;reas de este sitio web podr&iacute;an no funcionar correctamente si se deshabilitan las cookies.</strong></p>
        <h5><strong>&iquest;Actualizamos nuestra Pol&iacute;tica de Cookies?</strong></h5>
        <p><strong>Es posible que actualicemos la Pol&iacute;tica de Cookies de nuestro p&aacute;gina web, por ello le recomendamos revisar esta pol&iacute;tica cada vez que acceda a nuestro sitio web con el objetivo de estar adecuadamente informado sobre c&oacute;mo y para qu&eacute; usamos las cookies.&nbsp;</strong></p>
        <h5 id="configcookies"><strong>&iquest;C&oacute;mo puede configurar y/o rechazar sus Cookies?</strong></h5>
        <p><strong>Al clickar en &ldquo;ENTENDIDO&rdquo; y continuar en nuestro sitio web, estar&aacute; consintiendo el uso de las Cookies en las condiciones contenidas en la presente Pol&iacute;tica de Cookies. Usted como usuario tiene la posibilidad de ejercer su derecho a bloquear, eliminar y rechazar el uso de Cookies, en todo momento, modificando las opciones de su navegador. Por ejemplo:</strong></p>
        <p>
            Para más información sobre la administración de las cookies en Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=es">https://support.google.com/chrome/answer/95647?hl=es</a> <br />
            Para más información sobre la administración de las cookies en Internet Explorer: <a href="http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions">http://windows.microsoft.com/es-es/windows-vista/cookies-frequently-asked-questions</a> <br />
            Para más información sobre la administración de las cookies en Mozilla Firefox: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a> <br />
            Para más información sobre la administración de las cookies en Safari: <a href="http://www.apple.com/es/privacy/use-of-cookies/">http://www.apple.com/es/privacy/use-of-cookies/</a> <br />
            Para más información sobre la administración de las cookies en Opera: <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a> <br />
            Si desea dejar de ser seguido por Google Analytics visite: <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a> <br />
        </p>
        <p>
            Si usa otro navegador, consulte su política de instalación, uso y bloqueo de cookies. Puede obtener más información sobre la publicidad online basada en el comportamiento y la privacidad online en el siguiente enlace: <a href="http://www.youronlinechoices.com/es/">http://www.youronlinechoices.com/es/</a><br />
            Protección de datos de Google Analytics: <a href="http://www.google.com/analytics/learn/privacy.html">http://www.google.com/analytics/learn/privacy.html</a><br />
            Cómo usa Google Analytics las cookies: <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es#analyticsjs</a><br />
        </p>
        <h5><strong>&iquest;S&oacute;lo existen cookies?</strong></h5>
        <p><strong>Puede ser que haya webs que usen otras formas de almacenamiento similar a las cookies pero con m&aacute;s capacidad, como otras formas de almacenamiento local de datos en el equipo del cliente, por ejemplo:</strong></p>
        <ul>
            <li><strong>LocalStorage y sessionStorage de HTML5: Espacio que la web puede usar en el equipo del usuario. Normalmente borrando el historial de Navegaci&oacute;n se pueden borrar.</strong></li>
            <li><strong>Los &quot;Local Shared Objects&quot; de Flash (el &quot;isolated storage&quot; de Silverlight): Se almacenan dentro de la carpeta de Microsoft de donde tiene el perfil de usuario. Hay que entrar en la carpeta y borrarlo. (Ejemplo: los videos de vimeo).<br /> Flash sirve para incorporar elementos multimedia en una web, y para ello almacena ficheros en el equipo del usuario.</strong></li>
            <li><strong>El web beacon sirve para rastrear tu actividad, insertando una mini-imagen de la que ni siquiera te percatas. Cuando navegas por la web, se te carga junto con la p&aacute;gina, y el servidor de d&oacute;nde se descarga la foto, registra la hora del acceso, o las veces que has accedido.</strong></li>
        </ul>
        <h5><strong>&iquest;A continuación detallamos el uso de otro tipo de almacenamiento local en nuestra web</strong></h5>
        <table border="0" width="100%" style={ { maxWidth: "1000px", border: "1px", margin: "auto" } } cellPadding="15">
            <tbody>
                <tr>
                    <td>Nombre</td>
                    <td>Tipo de almacenamiento</td>
                    <td>¿Cómo lo usamos?</td>
                </tr>
                <tr>
                    <td>_grecaptcha</td>
                    <td>LocalStorage</td>
                    <td>Utilizado por Google Recaptcha como sistema anti-bot en formularios.</td>
                </tr>
                <tr>
                    <td>pwShopData</td>
                    <td>LocalStorage</td>
                    <td>Utilizado para guardar los items seleccionados del carrito de compra mientras el usuario continúa la navegación del sitio web. También es utilizado para guardar un código identificador de sesión, en caso de que el usuario haya accedido a su cuenta en el sitio web.</td>
                </tr>
            </tbody>
        </table>
        <h5><strong>&iquest;C&oacute;mo puede borrar LocalStorage?</strong></h5>
        <p> Usted como usuario tiene la posibilidad de borrar los datos de navegación almacenados en LocalStorage en todo momento, utilizando las opciones de su navegador. Por ejemplo:</p>
        <p>
            Si utilizas Google Chrome: <a href="https://support.google.com/chrome/answer/2392709?hl=es">https://support.google.com/chrome/answer/2392709?hl=es</a> <br />
            Si utilizas Microsoft Edge: <a href="https://support.microsoft.com/es-es/microsoft-edge/ver-o-eliminar-el-historial-del-explorador-en-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4">https://support.microsoft.com/es-es/microsoft-edge/ver-o-eliminar-el-historial-del-explorador-en-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4</a> <br />
            Si utilizas Mozilla Firefox: <a href="https://support.mozilla.org/es/kb/limpiar-el-historial-de-navegacion-busquedas-y-des">https://support.mozilla.org/es/kb/limpiar-el-historial-de-navegacion-busquedas-y-des</a> <br />
            Si utilizas Safari: <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">https://support.apple.com/es-es/guide/safari/sfri11471/mac</a> <br />
        </p>
        <p> Si utilizas otro navegador consulta cómo puedes borrar los datos de navegación de sitios web en su centro de ayuda.</p>
    </div>
);
export default CookiesPolicy;
