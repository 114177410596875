/* eslint-disable complexity */
import React from "react";
import { Helmet } from "react-helmet";
import { IoIosCall, IoMdPin } from "react-icons/io";
import Fade from "react-reveal/Fade";
import * as Pages from "../../../utils/Pages";
import WebContext from "../../../utils/WebContext";
import GenericDynamicContent from "../../../components/GenericDynamicContent";
import { apiFetchDynamicContent } from "../../../api";

class Home extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { slide: null };
        this.state.animateCarousel = true;
    }

    componentDidMount() {
        this.props.loadUIContext();
        apiFetchDynamicContent( "Page", "home" )
            .then( result => {
                this.setState( { content: result.dynamicContent } );
            } );
    }
    render() {
        const page = Pages.getPage( "home" );
        const lang = this.context.language;
        const homeSlides = [];
        if ( this.state.slide ) {
            homeSlides.push( this.state.slide );
        }
        return (
            <div className={ Pages.getPageClassNames( page ) }>
                <Helmet>
                    <title>{ page.seoTitle }</title>
                    <meta name="description" content={ page.seoDescription } />
                    <meta name="keywords" content={ page.seoKeywords } />
                    <meta name="author" content={ page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ page.seoTitle } />
                    <meta name="DC.description" content={ page.seoDescription } />
                    <meta property="og:title" content={ page.seoTitle } />
                    <meta property="og:description" content={ page.seoDescription } />
                    <meta property="og:url" content={ page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ page.seoDescription } />
                    <meta name="twitter:title" content={ page.seoTitle } />
                </Helmet>
                <GenericDynamicContent items={ this.state.content } />
                <div id="products" className="pw_section_container">
                    <Fade bottom>
                        <h1 className="pw_section_title">Productos de la más alta calidad</h1>
                        <h2 className="pw_section_subtitle">Contamos con marcas de alta gama para los tratamientos más elegantes, productos naturales para quién se preocupa por la sostenibilidad y productos específicos para tratar la problemática facial y corporal de cada caso.</h2>
                        <div style={ { margin: "40px 0" } }>
                            <img src="/static/ayuna-products.png" alt={ page.seoDefaultAlt } />
                        </div>
                    </Fade>
                </div>
                <div id="contacto" style={ { height: "20px", clear: "both" } } />
                <div className="pw_section_parallax" style={ { background: "linear-gradient(0deg, rgba(55,52,55,1) 0%, rgba(156,76,146,1) 35%, rgba(225,61,125,1) 100%)" } }>
                    <div className="pw_section_parallax_body">
                        <h2>Contáctanos</h2>
                        <div className="pw_section_parallax_phone">
                            <a href="tel:+34664490138"><IoIosCall /> 664 490 138</a>
                        </div>
                        <hr className="pw_hr_text" data-content="Social" />
                        <div className="pw_section_parallax_social">
                            <a href={ Pages.company.facebookPageURL }><img src="/static/social-icons-fb-black.png" alt="social icon facebook" /></a>
                            <a href={ Pages.company.instagramPageURL }><img src="/static/social-icons-instagram-black.png" alt="social icon facebook" /></a>
                            <a href={ Pages.company.whatsappContactURL }><img src="/static/social-icons-whatsapp-black.png" alt="social icon facebook" /></a>
                        </div>
                        <hr className="pw_hr_text" data-content="Nuestro centro" />
                        <div className="pw_section_parallax_location">
                            <IoMdPin /> Carrer de Lluís Martí, 30, 07006 Palma de Mallorca, Illes Balears
                        </div>
                        <hr className="pw_hr_text" data-content="Horario" />
                        <div className="pw_section_parallax_location">
                            De lunes a viernes de 09:00h a 19:00h
                        </div>
                    </div>
                </div>
                <div style={ { width: "100%", height: 500, position: "relative" } }>
                    { this.props.UIContext.loaded ?
                        <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12301.760453749466!2d2.6601632!3d39.5722303!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x82509a1026e122f1!2sAyuna%20Beauty%20Mallorca!5e0!3m2!1sen!2ses!4v1621839121941!5m2!1sen!2ses" width="100%" height="100%" frameBorder="0" style={ { border: 0 } } allowFullScreen="" aria-hidden="false" />
                        : null
                    }
                </div>
            </div>
        );
    }
}

Home.contextType = WebContext;
// Home.serverFetch = fetchData; // static declaration of data requirements

export default Home;
