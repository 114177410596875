export const contentStateData = ( argData ) => ( dispatch ) => {
    dispatch( { type: "CONTENT_DATA_FETCH_SUCCESS", data: argData } );
};
export const contentStatePageData = ( argData ) => ( dispatch ) => {
    dispatch( { type: "CONTENT_DATA_FETCH_PAGE_SUCCESS", data: argData } );
};
export const changeContentSalesAttr = ( key, value ) => ( {
    type: "CHANGE_CONTENT_SALES_ATTR",
    key,
    value
} );
