import { uuid } from "../utils/DataUtils";
/* eslint-disable complexity */
const update = ( ...objects ) => Object.assign( {}, ...objects );

export default ( state = {
    isEditing: false,
    isEditingChild: false,
    isLoading: false,
    isLoadingChild: false,
    isLoadingDebt: false,
    isError: false,
    listIsLoading: false,
    listErrorMessage: "",
    errorMessage: "",
    tmpSelectedCustomerIsLoading: false,
    tmpSelectedCustomerId: null,
    tmpSelectedCustomerPendingBookings: [],
    tmpSelectedCustomerSales: [],
    tmpSelectedCustomerConsents: [],
    tmpSelectedCustomerPets: [],
    tmpSelectedCustomerPaymentLinks: [],
    editing: {},
    editingChild: {},
    items: [],
    petsItems: [],
    isEditingSubscription: false,
    isLoadingSubscription: false,
    editingSubscription: {},
    isEditingPromoCode: false,
    isLoadingPromoCodes: false,
    editingPromoCode: {},
    subscriptionsItems: [],
    promoCodesItems: [],
    paymentLinksItems: [],
    toastMessagesModal: []
}, action ) => {
    switch ( action.type ) {
        case "MANAGER_CUSTOMERS_LIST_FETCH_ATTEMPT": {
            return update( state, {
                listIsLoading: true,
                listErrorMessage: ""
            } );
        }
        case "MANAGER_CUSTOMERS_LIST_FETCH_ERROR": {
            return update( state, {
                listIsLoading: false,
                listErrorMessage: action.errorMessage
            } );
        }
        case "MANAGER_CUSTOMERS_LIST_FETCH_SUCCESS": {
            let newItems = action.items;
            if ( action.customerId && action.items && action.items.length === 1 ) {
                newItems = state.items.map( tmp => {
                    if ( tmp.customerId === action.items[ 0 ].customerId ) {
                        return action.items[ 0 ];
                    }
                    return tmp;
                } );
            }
            return update( state, {
                listIsLoading: false,
                listErrorMessage: "",
                items: newItems
            } );
        }
        case "MANAGER_CUSTOMER_EDIT_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                isEditingId: uuid(),
                editing: action.customer,
                editingChild: {}
            } );
        }
        case "MANAGER_CUSTOMER_NEW_EDIT": {
            return update( state, {
                isEditing: true,
                isError: false,
                isLoading: false,
                errorMessage: "",
                editing: {
                    from: action.from ? action.from : null, customerActive: 1, customerIsReseller: 0, customerUseVATEquivalence: 0
                },
                editingChild: {},
                tmpSelectedCustomerSales: [],
                tmpSelectedCustomerConsents: [],
                tmpSelectedCustomerPets: []
            } );
        }
        case "MANAGER_CUSTOMER_NEW_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_CUSTOMER_NEW_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_CUSTOMER_NEW_SUCCESS": {
            if ( action.saveAndContinue ) {
                return update( state, {
                    isLoading: false,
                    isError: false,
                    errorMessage: ""
                } );
            }
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {},
                editingChild: {}
            } );
        }
        case "MANAGER_CUSTOMER_CHILD_UPDATE_ATTEMPT":
        case "MANAGER_CUSTOMER_CHILD_NEW_ATTEMPT": {
            return update( state, {
                isLoadingChild: true
            } );
        }
        case "MANAGER_CUSTOMER_CHILD_UPDATE_SUCCESS":
        case "MANAGER_CUSTOMER_CHILD_NEW_SUCCESS": {
            return update( state, {
                isLoadingChild: false,
                isEditingChild: false,
                editingChild: {}
            } );
        }
        case "MANAGER_CUSTOMER_CHILD_UPDATE_ERROR":
        case "MANAGER_CUSTOMER_CHILD_NEW_ERROR": {
            return update( state, {
                isLoadingChild: false
            } );
        }
        case "MANAGER_CUSTOMER_UPDATE_ATTEMPT": {
            return update( state, {
                isLoading: true,
                isError: false,
                errorMessage: ""
            } );
        }
        case "MANAGER_CUSTOMER_UPDATE_ERROR": {
            return update( state, {
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage
            } );
        }
        case "MANAGER_CUSTOMER_UPDATE_SUCCESS": {
            if ( action.saveAndContinue ) {
                return update( state, {
                    isLoading: false,
                    isError: false,
                    errorMessage: ""
                } );
            }
            return update( state, {
                isLoading: false,
                isError: false,
                isEditing: false,
                errorMessage: "",
                editing: {},
                editingChild: {}
            } );
        }
        case "MANAGER_CUSTOMER_CHANGE_ATTR": {
            return update( state, { [ action.key ]: action.value } );
        }
        case "MANAGER_CUSTOMER_CHANGE_EDIT_ATTR": {
            return update( state, { editing: update( state.editing, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_CUSTOMER_CHANGE_EDIT_CHILD_ATTR": {
            return update( state, { editingChild: update( state.editingChild, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_CUSTOMER_CHANGE_EDIT_SUBSCRIPTION_ATTR": {
            return update( state, { editingSubscription: update( state.editingSubscription, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_CUSTOMER_CHANGE_EDIT_PROMOCODE_ATTR": {
            return update( state, { editingPromoCode: update( state.editingPromoCode, { [ action.key ]: action.value } ) } );
        }
        case "MANAGER_CUSTOMER_UPDATE_STATUS_SUCCESS": {
            return update( state, {
                items: state.items.map( item => {
                    if ( item.customerId === action.customerId ) {
                        return update( item, { customerStatus: action.customerStatus, customerActive: action.customerActive } );
                    }
                    return item;
                } )
            } );
        }
        case "MANAGER_CUSTOMER_MODAL_SHOW_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessagesModal ? state.toastMessagesModal.map( tmp => tmp ) : [];
            newToastMessages.push( action.message );
            return update( state, { toastMessagesModal: newToastMessages } );
        }
        case "MANAGER_CUSTOMER_MODAL_HIDE_TOAST_MESSAGE": {
            const newToastMessages = state.toastMessagesModal ? state.toastMessagesModal.filter( tmp => tmp.id !== action.message.id ) : [ action.message ];
            return update( state, { toastMessagesModal: newToastMessages } );
        }
        default: return state;
    }
};
