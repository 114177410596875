/* eslint-disable no-constant-condition */
/* eslint-disable complexity */
/* eslint-disable global-require */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Form, Button, ButtonToolbar, Modal, Alert, Spinner } from "react-bootstrap";
import { loadUIContext } from "../../actions/UIContextActions";
import * as ManagerCustomersActions from "../../actions/ManagerCustomersActions";
import * as ManagerSalesActions from "../../actions/ManagerSalesActions";
import { formatDate, formatDateTimeString, isEmptyHtml, formatIntegerPriceTwoDecimals, filterInteger, formatIntegerPriceAmount, filterNumber } from "../../utils/DataUtils";
import { getSalePaymentMethodDesc } from "../../utils/SalesUtils";

registerLocale( "es", es );
setDefaultLocale( "es" );

const mapStateToProps = ( state ) => ( {
    UIContext: state.UIContext,
    ManagerCustomers: state.ManagerCustomers,
    ManagerSales: state.ManagerSales,
    pwSession: state.pwSession
} );

const mapDispatchToProps = Object.assign( {}, { loadUIContext }, ManagerCustomersActions, ManagerSalesActions );

class PaymentLinkModal extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            paymentLinkTotal: 0
        };
        this.resetModal = this.resetModal.bind( this );
        this.saveCustomerPaymentLink = this.saveCustomerPaymentLink.bind( this );
        this.isEditingSale = this.isEditingSale.bind( this );
        this.renderBottomButtons = this.renderBottomButtons.bind( this );
        this.quill = null;
    }
    componentDidMount() {
        this.resetModal();
        if ( document ) {
            this.quill = require( "react-quill" );
        }
    }
    componentDidUpdate( prevProps ) {
        if ( prevProps.ManagerCustomers.editingChild && ( !this.props.ManagerCustomers.editingChild || this.props.ManagerCustomers.editingChild.paymentLinkId !== prevProps.ManagerCustomers.editingChild.paymentLinkId ) ) {
            this.resetModal();
        }
    }
    resetModal() {
        const mutations = {
            paymentLinkTotal: formatIntegerPriceTwoDecimals( this.props.ManagerCustomers.editingChild.paymentLinkTotalAmount || 0 )
        };
        this.setState( mutations );
    }
    saveCustomerPaymentLink() {
        let editingPaymentLink = Object.assign( {}, this.props.ManagerCustomers.editingChild );
        if ( editingPaymentLink.paymentLinkId ) {
            this.props.managerUpdatePaymentLinkRequest()
                .then( () => {
                    if ( this.props.ManagerCustomers.items.length > 0 ) {
                        this.props.requestManagerCustomers();
                    }
                    if ( this.props.ManagerSales.items.length > 0 ) {
                        if ( editingPaymentLink.paymentLinkLinkedType === "sale" && editingPaymentLink.paymentLinkLinkedId ) {
                            let updatedSaleId = editingPaymentLink.paymentLinkLinkedId;
                            this.props.requestManagerSales( { saleId: updatedSaleId }, true );
                        }
                    }
                } );
        } else {
            this.props.changeManagerCustomerChildAttr( "paymentLinkCustomerId", this.props.ManagerCustomers.editing.customerId );
            this.props.managerNewPaymentLinkRequest();
        }
    }
    isEditingSale() {
        return this.props.ManagerSales.editing.saleActive;
    }
    renderBottomButtons () {
        return (
            <ButtonToolbar>
                <Button onClick={ this.saveCustomerPaymentLink } disabled={ this.props.ManagerCustomers.isLoadingChild }>
                    { this.props.ManagerCustomers.isLoadingChild ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null } Guardar Cambios
                </Button>
            </ButtonToolbar>
        );
    }
    render() {
        if ( !this.props.ManagerCustomers.editingChild || !this.props.ManagerCustomers.editingChild.paymentLinkStatus ) {
            return null;
        }
        const ReactQuill = this.quill;
        return (
            <Modal
                show={ this.props.ManagerCustomers.isEditingChild && this.props.ManagerCustomers.editingChild.paymentLinkStatus }
                onHide={ () => this.props.changeManagerCustomerAttr( "isEditingChild", false ) }
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pw_child_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { this.props.ManagerCustomers.editingChild.paymentLinkId ? "Editar link de pago" : "Nuevo link de pago" }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        { this.isEditingSale() ?
                            <Alert variant="success">
                                <b>Se está creando un link de pago relacionado a la venta</b>
                                <p>Al crear un link de pago desde una venta, una vez que el cliente realiza el pago, se registrará también en la venta.</p>
                                <p>En caso de que <b>no</b> quieras vincularlo a la venta, puedes crearlo desde <b>Clientes</b> o <b>Facturas</b></p>
                            </Alert> : null }
                        <Form.Group>
                            <Form.Label>Título:</Form.Label>
                            <Form.Control
                                value={ this.props.ManagerCustomers.editingChild.paymentLinkTitle }
                                onChange={ ( e ) => {
                                    this.props.changeManagerCustomerChildAttr( "paymentLinkTitle", e.target.value );
                                } }
                            />
                        </Form.Group>
                        { ReactQuill ?
                            <Form.Group>
                                <Form.Label>Descripción:</Form.Label>
                                <ReactQuill
                                    value={ this.props.ManagerCustomers.editingChild.paymentLinkHtmlDescription ? this.props.ManagerCustomers.editingChild.paymentLinkHtmlDescription : "" }
                                    modules={ {
                                        toolbar: [
                                            [ { header: [ 2, 3, 4, false ] } ],
                                            [ "bold", "italic", "underline", "strike" ],
                                            [ { list: "ordered" }, { list: "bullet" } ],
                                            [ "clean" ]
                                        ]
                                    } }
                                    onChange={ ( htmlValue ) => this.props.changeManagerCustomerChildAttr( "paymentLinkHtmlDescription", isEmptyHtml( htmlValue ) ? null : htmlValue ) }
                                />
                            </Form.Group>
                            : null }
                        <Form.Group>
                            <Form.Label>Total:</Form.Label>
                            <Form.Control
                                value={ this.state.paymentLinkTotal }
                                onClick={ ( e ) => e.target.select() }
                                onChange={ ( e ) => {
                                    const paymentLinkTotal = filterNumber( e.target.value, 0 );
                                    this.setState( { paymentLinkTotal } );
                                    this.props.changeManagerCustomerChildAttr( "paymentLinkTotalAmount", formatIntegerPriceAmount( paymentLinkTotal ) );
                                } }
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fecha/Hora límite:</Form.Label>
                            <DatePicker
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeIntervals={ 60 }
                                showTimeSelect
                                selected={ formatDate( this.props.ManagerCustomers.editingChild.paymentLinkExpirationDatetime ? this.props.ManagerCustomers.editingChild.paymentLinkExpirationDatetime : null ) }
                                onChange={ ( value ) => {
                                    this.props.changeManagerCustomerChildAttr( "paymentLinkExpirationDatetime", formatDateTimeString( value ) );
                                    if ( value && this.props.ManagerCustomers.editingChild.paymentLinkStatus === "expired" ) {
                                        this.props.changeManagerCustomerChildAttr( "paymentLinkStatus", "active" );
                                        this.props.changeManagerCustomerChildAttr( "paymentLinkActive", 1 );
                                    }
                                } }
                            />
                        </Form.Group>
                        { this.isEditingSale() ?
                            <Form.Group>
                                <Form.Check type="switch" id="link-cancel-sale-check" checked={ this.props.ManagerCustomers.editingChild.paymentLinkCancelSale } onChange={ () => this.props.changeManagerCustomerChildAttr( "paymentLinkCancelSale", this.props.ManagerCustomers.editingChild.paymentLinkCancelSale ? 0 : 1 ) } label="Anular venta si no paga en fecha" />
                            </Form.Group> : null }
                        <Form.Group>
                            <Form.Label>Forma de pago:</Form.Label>
                            <Form.Control value={ getSalePaymentMethodDesc( this.props.pwSession.manager.capabilities.paymentLinksPaymentMethod ) } readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Estado:</Form.Label>
                            <Form.Control
                                as="select"
                                value={ this.props.ManagerCustomers.editingChild.paymentLinkActive }
                                onChange={ ( e ) => {
                                    const value = filterInteger( e.target.value );
                                    this.props.changeManagerCustomerChildAttr( "paymentLinkActive", value );
                                    if ( !this.props.ManagerCustomers.editingChild.paymentLinkStatus ||
                                        [ "active", "inactive" ].includes( this.props.ManagerCustomers.editingChild.paymentLinkStatus ) ) {
                                        this.props.changeManagerCustomerChildAttr( "paymentLinkStatus", value ? "active" : "inactive" );
                                    }
                                } }
                            >
                                <option value="1">Activo</option>
                                <option value="0">Desactivado</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    { this.renderBottomButtons() }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( PaymentLinkModal ) );
